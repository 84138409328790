import { t } from 'i18next';
import { toast } from 'react-toastify';

export const COMMON_ELEMENT_WIDTH = '400px';

export const LogoVariants = {
  white: 'logo-white.svg',
  black: 'logo-black.svg'
};

export const ButtonVariantMap = {
  red: {
    backgroundColor: 'var(--error)',
    color: 'var(--blackI)',
    '&:hover, &:disabled': {
      backgroundColor: 'var(--error)',
      opacity: 0.6,
      color: 'var(--blackI)'
    }
  },
  purple: {
    backgroundColor: 'var(--purple)',
    color: 'var(--blackI)',
    '&:hover, &:disabled': {
      backgroundColor: 'var(--purple)',
      color: 'var(--blackI)',
      opacity: 0.6
    }
  },
  'outline-purple': {
    backgroundColor: 'transparent',
    color: 'var(--purple)',
    border: '1px solid var(--purple)',
    '&:hover, &:disabled': {
      backgroundColor: 'transparent',
      color: 'var(--purple)',
      border: '1px solid var(--purple)',
      opacity: 0.6
    }
  },
  'text-only-purple': {
    backgroundColor: 'transparent',
    color: 'var(--purple)',
    border: 0,
    '&:hover, &:disabled': {
      backgroundColor: 'transparent',
      color: 'var(--purple)',
      border: 0,
      opacity: 0.6
    }
  },
  green: {
    backgroundColor: 'var(--green)',
    color: 'var(--black)',
    '&:hover, &:disabled': {
      backgroundColor: 'var(--green)',
      color: 'var(--black)',
      opacity: 0.6
    }
  },
  blue: {
    backgroundColor: 'var(--lightBlue)',
    color: 'var(--white)',
    '&:hover, &:disabled': {
      backgroundColor: 'var(--lightBlue)',
      color: 'var(--white)',
      opacity: 0.6
    }
  },
  'outline-green': {
    backgroundColor: 'transparent',
    color: 'var(--green)',
    border: '1px solid var(--green)',
    '&:hover, &:disabled': {
      backgroundColor: 'transparent',
      color: 'var(--green)',
      border: '1px solid var(--green)',
      opacity: 0.6
    }
  },
  'outline-blue': {
    backgroundColor: 'transparent',
    color: 'var(--lightBlue)',
    border: '1px solid var(--lightBlue)',
    '&:hover, &:disabled': {
      backgroundColor: 'transparent',
      color: 'var(--lightBlue)',
      border: '1px solid var(--lightBlue)',
      opacity: 0.6
    }
  },
  'outline-dark-blue': {
    backgroundColor: 'transparent',
    color: '#143B59',
    border: '2px solid #143B59',
    '&:hover, &:disabled': {
      backgroundColor: 'transparent',
      color: '#143B59',
      border: '2px solid #143B59',
      opacity: 0.6
    }
  }
};

export const InputVariantMap = {
  black: {
    backgroundColor: 'var(--black)',
    color: 'var(--white)',
    borderColor: 'transparent',
    '&:read-only': {
      fontWeight: 'bold',
      input: {
        cursor: 'pointer'
      },
      cursor: 'pointer'
    }
  },
  navy: {
    backgroundColor: 'var(--blackIII)',
    color: 'var(--white)',
    borderColor: 'transparent',
    '&:read-only': {
      input: {
        cursor: 'pointer'
      },
      cursor: 'pointer'
    },
    '&.Mui-disabled': {
      input: {
        color: 'var(--grey)',
        '-webkit-text-fill-color': 'var(--grey)'
      }
    }
  },
  'outline-darkGrey': {
    backgroundColor: 'var(--navy)',
    color: 'var(--grey)',
    borderColor: 'var(--darkGrey)',
    '&:read-only': {
      fontWeight: 'bold',
      input: {
        cursor: 'pointer'
      },
      cursor: 'pointer'
    }
  },
  light: {
    backgroundColor: 'var(--white)',
    color: 'var(--grey)',
    borderColor: 'transparent',
    '&:read-only': {
      fontWeight: 'bold',
      input: {
        cursor: 'pointer'
      },
      cursor: 'pointer'
    }
  },
  'light-blue': {
    backgroundColor: 'var(--lightBlueTransparent)',
    color: 'var(--newGrey)',
    borderColor: 'transparent',
    '&:read-only': {
      fontWeight: 'bold',
      input: {
        cursor: 'pointer'
      },
      cursor: 'pointer'
    }
  }
};

export const showGeneralErrorMessage = (msg?: string) => {
  toast.error(msg ? msg : t('Something went wrong, try again in a few minutes'), {
    autoClose: 3000,
    toastId: 'general-error'
  });
};
