import { MouseEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import Icon from '../../../Common/Icon';
import { MenuItem, MenuOptions } from '../../../Sidebar/PortfolioSidebar/style';
import { PortfolioSortTypes } from '../../types';
import { FolderAction, FolderActionsWrapper } from './style';

interface FolderActionsProps {
  setSelectMode: (value: boolean) => void;
  selectMode: boolean;
  handleToggleSelectMode: () => void;
  selectedProjects: string[];
  handleSortBy?: (value: PortfolioSortTypes) => void;
}

const FolderActions = ({
  setSelectMode,
  selectMode,
  selectedProjects,
  handleToggleSelectMode,
  handleSortBy
}: FolderActionsProps) => {
  const { t } = useTranslation();
  const menuRef = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [sortBy, setSortBy] = useState(PortfolioSortTypes.RECENT);
  useOnClickOutside(menuRef, () => setOpenMenu(false), 'mouseup');

  const handleItemClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>, value: PortfolioSortTypes) => {
      e.stopPropagation();
      setSortBy(value);
      handleSortBy?.(value);
      setOpenMenu(false);
    },
    [setSortBy, setOpenMenu, handleSortBy]
  );

  return (
    <FolderActionsWrapper>
      <FolderAction
        onClick={() => {
          setSelectMode(false);
          setOpenMenu(true);
        }}
      >
        <Icon variant="SORT" size={24} color="silver" />
        {t(`Sort by ${sortBy}`)}
        <MenuOptions isOpen={openMenu} ref={menuRef}>
          <MenuItem
            active={sortBy === PortfolioSortTypes.ALPHABETICAL}
            onClick={(e) => handleItemClick(e, PortfolioSortTypes.ALPHABETICAL)}
          >
            {t('Alphabetical')}
          </MenuItem>
          <MenuItem
            active={sortBy === PortfolioSortTypes.RECENT}
            onClick={(e) => handleItemClick(e, PortfolioSortTypes.RECENT)}
          >
            {t('Recent')}
          </MenuItem>
          <MenuItem
            active={sortBy === PortfolioSortTypes.OLDEST}
            onClick={(e) => handleItemClick(e, PortfolioSortTypes.OLDEST)}
          >
            {t('Oldest')}
          </MenuItem>
        </MenuOptions>
      </FolderAction>
      <FolderAction active={selectMode} onClick={handleToggleSelectMode}>
        <Icon
          variant={selectedProjects.length > 0 ? 'SELECT_CHECKED' : 'SELECT'}
          size={selectedProjects.length > 0 ? 24 : 18}
          color={selectMode ? 'green' : 'silver'}
        />
        {t('Select ')}
        {selectedProjects.length > 0 ? ` (${selectedProjects.length})` : ''}
      </FolderAction>
    </FolderActionsWrapper>
  );
};

export default FolderActions;
