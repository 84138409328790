import { Page, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import Footer from '../../Components/Footer';
import { globalStyles } from '../../style';
import { styles } from './style';

interface Props {
  regionName: string;
}

const DisclaimerPage = ({ regionName }: Props) => {
  const { t } = useTranslation();

  return (
    <Page style={globalStyles.pageWrapper}>
      <Text style={styles.disclaimerTitle}>{t('Disclaimer')}</Text>
      <Text style={styles.disclaimerText}>
        {t(
          'dClimate Inc., CYCLOPS MRV Inc., and/or their affiliated entities (the "Companies") make no warranty, express or implied, concerning the contents of this Report. All information provided in this Report is presented "as is." Additionally, this Report was developed in part through the use of information compiled and provided by third parties, which is not guaranteed as to accuracy, completeness, reliability, or otherwise. The Companies expressly disclaim any implied warranty of merchantability or fitness for a particular purpose, including any warranty for the use or the results of the use of the contents of this report with respect to its correctness, quality, accuracy, completeness, reliability, performance, or timeliness. The content of this Report is believed to be accurate and is obtained from sources which the Companies believe to be reliable. However, the Companies have not verified this information and do not guarantee its accuracy, completeness, fairness or timeliness. As such, the recipient(s) of this Report acknowledge(s) and agree(s) that the Companies, and their advisers, consultants, shareholders, and other affiliated persons are not responsible for and are not making any representations, guarantees or warranties regarding, the accuracy, completeness or fairness of the information contained in this presentation.'
        )}
      </Text>
      <Footer regionName={regionName} />
    </Page>
  );
};

export default DisclaimerPage;
