import styled from 'styled-components';

export type BannerType = 'default' | 'danger';

export const Wrapper = styled.div`
  border-radius: 10px;
  width: 100%;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: ${({ theme }) => theme.transition};

  button {
    margin-left: 32px;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  div {
    margin-right: 10px;
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.white};
  max-width: 230px;
  font-weight: 500;
`;
