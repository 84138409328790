import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch } from '../../../redux/hooks';
import { ContentWrapper } from '../../../styles/Global';
import Icon from '../../Common/Icon';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import Tabs from '../../Common/Tabs';
import Tab from '../../Common/Tabs/Tab';
import AccountAPIKey from './APIKey';
import AccountDetails from './Details';
import AccountPlan from './Plan';
import AccountSettings from './Settings';
import {
  AccountModalSx,
  ModalContent,
  ModalDetails,
  ModalDetailsBackToMap,
  ModalTitle,
  TabContentWrapper,
  TabsWrapperSx
} from './style';
import AccountSupport from './Support';

const AccountModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [tab, changeTab] = useState('0');

  return (
    <Modal
      modalType="account"
      additionalSx={AccountModalSx}
      dataTestId="account-modal"
      disableBackdropClick
      disableEscapeKeyDown
      hideBackdrop
      withCloseIcon={false}
      zIndex={10002}
    >
      <ContentWrapper
        style={{
          flexDirection: 'column'
        }}
      >
        <ModalDetails>
          <ModalDetailsBackToMap onClick={() => dispatch(closeModal())}>
            <Icon color="white" variant="CARET" size={24} />
            {t('Back to Map')}
          </ModalDetailsBackToMap>
          <ModalTitle>{t('My Account')}</ModalTitle>
        </ModalDetails>
        <Spacer size="20px 0 0" />
        <ModalContent>
          <Tabs
            color="green"
            value={tab}
            setValue={changeTab}
            additionalTabsSx={TabsWrapperSx}
            dataTestId="account-modal-tabs"
            orientation={'vertical'}
            additionalTabSx={{
              padding: '0',
              alignItems: 'flex-start',
              marginLeft: '20px',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '27px',
              marginBottom: '30px'
            }}
          >
            <Tab title={t('Details')}>
              <TabContentWrapper withFooter>
                <AccountDetails />
              </TabContentWrapper>
            </Tab>
            <Tab title={t('Settings')}>
              <TabContentWrapper withFooter>
                <AccountSettings />
              </TabContentWrapper>
            </Tab>
            <Tab title={t('API Key')}>
              <TabContentWrapper>
                <AccountAPIKey />
              </TabContentWrapper>
            </Tab>
            <Tab title={t('Plan')}>
              <TabContentWrapper>
                <AccountPlan />
              </TabContentWrapper>
            </Tab>
            <Tab title={t('Support')}>
              <TabContentWrapper>
                <AccountSupport />
              </TabContentWrapper>
            </Tab>
          </Tabs>
        </ModalContent>
      </ContentWrapper>
    </Modal>
  );
};

export default AccountModal;
