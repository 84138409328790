import { IFolderRequest, IFolderResponse, IFolderUpdateRequest } from '../../types/API/Folder';
import { setCurrentFolder, setFolders } from '../features/folder/folder-slice';
import { baseApi } from './index';

export const folderApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Folders', 'Folder']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      addFolder: builder.mutation<IFolderResponse, IFolderRequest>({
        query(body) {
          return {
            url: '/folders/add',
            method: 'POST',
            body
          };
        },
        invalidatesTags: ['Folders']
      }),
      updateFolder: builder.mutation<
        IFolderResponse,
        {
          id: string;
          body: IFolderUpdateRequest;
        }
      >({
        query({ body, id }) {
          return {
            url: `/folders/update/${id}`,
            method: 'PATCH',
            body
          };
        },
        invalidatesTags: ['Folder']
      }),
      removeFolder: builder.mutation<{ success: boolean }, { id: string }>({
        query({ id }) {
          return {
            url: `/folders/remove/${id}`,
            method: 'DELETE'
          };
        },
        invalidatesTags: ['Folders']
      }),
      getUserFolders: builder.query<IFolderResponse[], void>({
        query() {
          return {
            url: '/folders/user',
            method: 'GET'
          };
        },
        providesTags: ['Folders'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const response = await queryFulfilled;
          dispatch(setFolders(response.data));
        }
      }),
      getFolder: builder.query<IFolderResponse, { id: string; sorting?: string; sortingDirection?: 'asc' | 'desc' }>({
        query({ id, sorting, sortingDirection }) {
          return {
            url: `/folders/single/${id}`,
            method: 'GET',
            params: {
              sorting,
              sortingDirection
            }
          };
        },
        // This is the tag that will be invalidated when the query is successful, and should be asigned to id
        providesTags: ['Folder'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const response = await queryFulfilled;
          dispatch(setCurrentFolder(response.data));
        }
      }),
      getAllFolders: builder.query<IFolderResponse[], void>({
        query() {
          return {
            url: '/folders',
            method: 'GET'
          };
        },
        providesTags: ['Folders']
      })
    })
  });

export const {
  useAddFolderMutation,
  useUpdateFolderMutation,
  useRemoveFolderMutation,
  useGetUserFoldersQuery,
  useGetFolderQuery,
  useGetAllFoldersQuery
} = folderApi;
