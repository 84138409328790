import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateRegionMutation } from '../../../redux/api/regionApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { setSelectedPolygon } from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../ModalCommon.style';
import { ModalSx } from './style';

const UnsubscribeRegionModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { selectedPolygon } = useAppSelector((state) => state.regionState);

  const [updateRegion, { isLoading, reset, error }] = useUpdateRegionMutation();

  const handleRegionUnsubscribe = useCallback(async () => {
    if (selectedPolygon && !isLoading) {
      const data = await updateRegion({
        id: String(selectedPolygon?.id),
        body: {
          isSubscribed: false
        }
      });
      if (!('error' in data)) {
        dispatch(setSelectedPolygon(data.data));
        reset();
        dispatch(closeModal());
      } else {
        toast.error(t('Something went wrong'), {
          toastId: 'unsubscribe-region-modal-error',
          autoClose: 3000
        });
        reset();
      }
    }
  }, [dispatch, isLoading, reset, selectedPolygon, t, updateRegion]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'unsubscribe-region-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <Modal
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="unsubscribe-region-modal"
      modalType="unsubscribeRegion"
    >
      <Title data-test-id="unsubscribe-region-modal-title">{t('Unsubscribe region')}</Title>
      <Subtitle data-test-id="unsubscribe-region-modal-subtitle">
        {t('Are you sure you want to stop monitoring this region?')}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
          dataTestId="unsubscribe-region-modal-cancel-button"
          additionalSx={{
            fontSize: '14px'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="red"
          fullWidth
          onClick={handleRegionUnsubscribe}
          disabled={isLoading}
          dataTestId="unsubscribe-region-modal-delete-button"
        >
          {t('Unsubscribe')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default UnsubscribeRegionModal;
