import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { COMMON_ELEMENT_WIDTH } from '../../../constants/UI';
import { InputVariant } from '../../../types/Theme';
import Icon from '../Icon';
import { InputRelativeContainer, InputSX, InputWrapper, LabelSx, ValueResetter } from '../Input/style';

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder | toggle'> {
  variant?: InputVariant;
  value: string;
  id?: string;
  name?: string;
  label?: string;
  color?: never;
  size?: never;
  width?: string;
  fullWidth?: boolean;
  noMargin?: boolean;
  placeholder?: any;
  visible?: boolean;
  dataTestId?: string;
}

const CopyPasteInputComponent = ({
  variant = 'navy',
  value,
  onChange,
  id,
  name,
  label,
  width = COMMON_ELEMENT_WIDTH,
  fullWidth = false,
  noMargin = false,
  visible = true,
  dataTestId,
  ...rest
}: Props) => {
  const [copied, setCopied] = useState(false);

  const SX = useMemo(() => InputSX(variant, false, value.length > 0, width), [variant, value.length, width]);

  const handleOnCopy = useCallback(async () => {
    if (!navigator?.clipboard) {
      return false;
    }

    setCopied(true);
    await navigator.clipboard.writeText(value);
  }, [value]);

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 3000);
  }, [copied]);

  return (
    <InputWrapper noMargin={noMargin} onClick={handleOnCopy}>
      {label ? (
        <FormLabel htmlFor={id} sx={LabelSx} data-test-id={dataTestId && `${dataTestId}-label`}>
          {label}
        </FormLabel>
      ) : null}
      <InputRelativeContainer width={width}>
        <Input
          value={value}
          id={id}
          name={name}
          type={visible ? 'text' : 'password'}
          readOnly
          inputProps={{ 'data-test-id': dataTestId }}
          fullWidth={fullWidth}
          sx={{
            ...SX,
            input: {
              maxWidth: '80%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }
          }}
          {...rest}
        />
        <ValueResetter visible type="button" tabIndex={-1} data-test-id={dataTestId && `${dataTestId}-reset`}>
          <Icon size={20} color="gray" variant={copied ? 'TICK_CIRCLE' : 'ENVELOPE'} />
        </ValueResetter>
      </InputRelativeContainer>
    </InputWrapper>
  );
};

export default CopyPasteInputComponent;
