import styled, { css } from 'styled-components';

export const ModalSx = {
  minHeight: '300px'
};

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`;

export const InputInfo = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: -8px;
`;

export const UsersListWrapper = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const UserListItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.blue};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 16px;
  transition: ${({ theme }) => theme.transition};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.navy};
    border: 1px solid ${({ theme }) => theme.colors.purple};

    * {
      color: ${({ theme }) => theme.colors.purple};
    }

    button {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextStyle = css`
  color: ${({ theme }) => theme.colors.grey};
  font-size: 15px;
  line-height: 18px;
  transition: ${({ theme }) => theme.transition};
`;

export const UserName = styled.p`
  ${TextStyle};
  font-weight: bold;
  margin-bottom: 4px;
`;

export const UserEmail = styled.p`
  ${TextStyle};
`;

export const EditButton = styled.button`
  width: 56px;
  height: 26px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.purple};
  color: ${({ theme }) => theme.colors.purple};
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: ${({ theme }) => theme.transition};
  align-self: center;
  background-color: transparent;
`;
