import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  nav {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.blue};
    padding: 0;

    li {
      padding: 0;
      border-radius: 0;

      button,
      .MuiPaginationItem-ellipsis {
        padding: 10px;
        margin: 0;
        color: ${({ theme }) => theme.colors.white};
        border-right: 1px solid ${({ theme }) => theme.colors.blue};
        border-radius: 0;

        &.Mui-selected {
          color: ${({ theme }) => theme.colors.purple};
          font-weight: bold;
        }
      }

      &:last-of-type {
        button {
          border-right: none;
        }
      }
    }
  }
`;
