import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateFolderMutation } from '../../../../redux/api/folderApi';
import { closeModal, openModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import Input from '../../../Common/Input';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import Textarea from '../../../Common/Textarea';
import { ButtonWrapper, Title } from '../../ModalCommon.style';

const EditFolderModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentFolder } = useAppSelector((state) => state.folderState);
  const [name, setName] = useState(currentFolder?.name || '');
  const [summary, setSummary] = useState(currentFolder?.summary || '');
  const [updateFolder, { isLoading, isError, reset }] = useUpdateFolderMutation();

  const handleNameChange = useCallback((newName: string) => {
    setName(newName);
  }, []);

  const handleSummaryChange = useCallback((newSummary: string) => {
    setSummary(newSummary);
  }, []);

  const handleDeleteFolder = useCallback(() => {
    dispatch(openModal('deleteFolder'));
  }, [dispatch]);

  const handleOnSave = useCallback(async () => {
    if (!currentFolder) return;
    const data = await updateFolder({
      id: currentFolder.id,
      body: {
        name,
        summary
      }
    });

    if (!('error' in data)) {
      toast.success(t('Folder updated successfully'), {
        autoClose: 3000,
        toastId: 'update-folder-toast'
      });

      dispatch(closeModal());
    }
  }, [currentFolder, updateFolder, name, summary, t, dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(t('We were unable to update the folder. Please try again later'), {
        autoClose: 3000,
        toastId: 'update-folder-error-toast'
      });
      reset();
    }
  }, [isError, reset, t]);

  return (
    <Modal withCloseIcon={false} dataTestId={'edit-folder-modal'} modalType="editFolder">
      <Title data-test-id="edit-folder-modal-title">{t('Edit folder')}</Title>
      <Spacer size="30px 0 0" />
      <Input
        dataTestId="edit-folder-modal-name-input"
        value={name}
        required
        label={String(t('Folder name'))}
        name="folder-name"
        id="folder-name"
        onChange={handleNameChange}
        noMargin
        fullWidth
      />
      <Spacer size="20px 0 0" />
      <Textarea
        dataTestId="edit-folder-modal-summary-input"
        value={summary}
        required
        label={String(t('Summary'))}
        name="folder-summary"
        id="folder-summary"
        onChange={handleSummaryChange}
        noMargin
        fullWidth
        maxLength={50}
        minRows={5}
      />
      <Spacer size="30px 0 0" />
      <ButtonWrapper>
        <Button
          variant="outline-purple"
          fullWidth
          onClick={handleDeleteFolder}
          disabled={isLoading}
          dataTestId="edit-folder-modal-delete-button"
          additionalSx={{
            width: '196px'
          }}
        >
          {t('Delete Folder')}
        </Button>
        <Button
          dataTestId="edit-folder-modal-save-button"
          variant="purple"
          fullWidth
          disabled={name.length === 0 || isLoading}
          onClick={handleOnSave}
          additionalSx={{
            width: '196px'
          }}
        >
          {t('Update')}
        </Button>
      </ButtonWrapper>
      <Spacer size={'20px 0 0'} />
      <Button
        variant="text-only-purple"
        fullWidth
        onClick={() => dispatch(closeModal())}
        disabled={isLoading}
        dataTestId="edit-folder-modal-cancel-button"
        additionalSx={{
          padding: 0
        }}
      >
        {t('Cancel')}
      </Button>
    </Modal>
  );
};

export default EditFolderModal;
