import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AdminLogo from '../../components/Admin/AdminLogo';
import Cache from '../../components/Admin/Cache';
import PublicProjects from '../../components/Admin/PublicProjects';
import { AdminHeader, Container, ContentWrapper, Wrapper } from '../../components/Admin/style';
import UsersList from '../../components/Admin/UsersList';
import Tabs from '../../components/Common/Tabs';
import Tab from '../../components/Common/Tabs/Tab';
import Menu from '../../components/Layout/Header/Menu';
import { useAppSelector } from '../../redux/hooks';

const ADMIN_TAB_TO_URL_PARAM = ['users', 'cache', 'public-projects'];

const AdminPage = () => {
  const { isAuthenticated, user } = useAppSelector((state) => state.userState);
  const { tab } = useParams();
  const [adminTab, changeAdminTab] = useState(String(ADMIN_TAB_TO_URL_PARAM.indexOf(tab || 'users')));
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = 'rgb(9, 20, 29)';
    if (isAuthenticated && user && user.role !== 'admin') {
      navigate('/login/admin');
    }
  }, [isAuthenticated, navigate, user]);

  useEffect(() => {
    const newParam = ADMIN_TAB_TO_URL_PARAM[parseInt(adminTab, 10)] || 'users';
    // set url param based on the currently selected tab
    window.history.pushState('', '', `/admin/${newParam}`);
    changeAdminTab(String(ADMIN_TAB_TO_URL_PARAM.indexOf(newParam)));
  }, [adminTab]);

  return (
    <Wrapper>
      <Container>
        <AdminHeader>
          <AdminLogo align="left" />
          <Menu version="admin" />
        </AdminHeader>
        <ContentWrapper>
          <Tabs color="green" value={adminTab} setValue={changeAdminTab}>
            <Tab title={t('Users')}>
              <UsersList />
            </Tab>
            <Tab title={t('Cache')}>
              <Cache />
            </Tab>
            <Tab title={t('Public Projects')}>
              <PublicProjects />
            </Tab>
          </Tabs>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default AdminPage;
