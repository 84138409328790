import CircularProgress from '@mui/material/CircularProgress';
import { ContentWrapper, Description, LoaderWrapper, Title } from './style';

interface Props {
  title: string;
  description: string;
}

const Loader = ({ title, description }: Props) => {
  return (
    <LoaderWrapper>
      <ContentWrapper>
        <CircularProgress
          data-test-id="data-board-loader"
          color="success"
          size={50}
          sx={{
            color: 'var(--green)'
          }}
        />
        <Title data-test-id="data-board-loader-title">{title}</Title>
        <Description data-test-id="data-board-loader-description">{description}</Description>
      </ContentWrapper>
    </LoaderWrapper>
  );
};
export default Loader;
