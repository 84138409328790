import { ColorVariant } from '../../../types/Theme';
import { IconWrapper } from './style';

export type IconVariant =
  | 'ARROW_RIGHT_SKIP'
  | 'ARROW_RIGHT'
  | 'ARROW_LEFT'
  | 'BELL'
  | 'BELL_OUTLINE'
  | 'BELL_FILLED'
  | 'CALENDAR'
  | 'CARET'
  | 'CHECK'
  | 'CHEVRON_DOWN'
  | 'CHEVRON_RIGHT'
  | 'CSV'
  | 'DOWNLOAD'
  | 'DRAW'
  | 'DRAW_BOLD'
  | 'ENVELOPE'
  | 'EXCLAMATION_CIRCLE'
  | 'EYE_HIDE'
  | 'EYE_SHOW'
  | 'FOLDER'
  | 'GEOJSON'
  | 'INFO_CIRCLE'
  | 'KEY'
  | 'LIGHTBULB'
  | 'LIST'
  | 'MINUS'
  | 'MORE'
  | 'PAUSE'
  | 'PDF'
  | 'PENCIL'
  | 'PIN'
  | 'PLAY'
  | 'PLAY_CIRCLE'
  | 'PLUS'
  | 'RIGHT_ARROW'
  | 'SEARCH'
  | 'SELECT'
  | 'SELECT_CHECKED'
  | 'SORT'
  | 'THREE_DOTS_HORIZONTAL'
  | 'TICK_CIRCLE'
  | 'TRASH'
  | 'X_CIRCLE_OUTLINE'
  | 'X_CIRCLE'
  | 'X'
  | 'UNASSIGNED'
  | 'USER'
  | 'UPLOAD'
  | 'UPLOAD_BOLD'
  | 'WARNING';

export interface Props {
  color: ColorVariant;
  variant: IconVariant;
  size: number;
  dataTestId?: string;
}

const Icon = (props: Props) => {
  return <IconWrapper data-test-id={props.dataTestId} {...props} />;
};

export default Icon;
