import styled from 'styled-components';
import { IconWrapper } from '../Common/Icon/style';

export const Title = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.lightBlueTransparent};
  margin-bottom: 24px;
`;

export const RegionPointsList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RegionPoint = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
  position: relative;
  height: 72px;
  padding: 8px 32px 8px 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.newGrey};
  margin-bottom: 32px;
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.green};
  }

  ${IconWrapper} {
    transition: ${({ theme }) => theme.transition};
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.lightBlueTransparent};
    margin-right: 16px;
    min-width: 60px;
  }

  &:before {
    content: '';
    background: #eaf2f0;
    width: 8px;
    height: 8px;
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 24px;
    position: absolute;
    top: calc(50% - 4px);
    left: -24px;
  }

  &:after {
    content: '';
    width: 1px;
    height: 102px;
    background: ${({ theme }) => theme.colors.white};
    left: -20.5px;
    top: 50%;
    position: absolute;
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }
`;

export const RegionPointInputs = styled.div`
  display: flex;
  gap: 16px;
`;

export const RegionPointInput = styled.input.attrs({
  type: 'number',
  step: 0.000001
})`
  padding: 8px 16px;
  width: 100%;
  height: 35px;
  color: ${({ theme }) => theme.colors.white};
  background: #000000;
  border-radius: 8px;
  border: 0;
  margin-right: auto;
  transition: ${({ theme }) => theme.transition};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  &:focus {
    outline: none;
  }
`;
