import { useCallback } from 'react';
import { Switch, Wrapper } from './style';

export interface Props {
  checked: boolean;
  onToggle: () => void;
  dataTestId?: string;
}

const SwitchComponent = ({ checked, onToggle, dataTestId }: Props) => {
  const handleOnChange = useCallback(() => {
    onToggle();
  }, [onToggle]);

  return (
    <Wrapper active={checked} onClick={handleOnChange}>
      <Switch active={checked} data-test-id={dataTestId} />
    </Wrapper>
  );
};
export default SwitchComponent;
