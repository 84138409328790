import area from '@turf/area';
import center from '@turf/center';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import { COMMON_FEATURE_BODY_TYPE } from '../../constants/data-board';
import { PUBLIC_SOURCE_NAME, USER_SOURCE_NAME } from '../../constants/map';
import {
  useGetCarbonAccountingByAreaMutation,
  useGetCarbonAccountingDataMutation,
  useGetForestCoverDataMutation
} from '../../redux/api/biomassApi';
import { setIsDrawing } from '../../redux/features/draw/draw-slice';
import { resetRegionState, setCalculatedArea, setSelectedPolygon } from '../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IBiomassRequest, IDeforestationResponse, SQ_METER_TO_KILOMETER_RATIO } from '../../types/Geo';
import { formatCarbonData, formatForestData } from '../../utils/biomass';
import { convertSqKmTo } from '../../utils/units';
import { useMapContext } from '../Map';
import { Props } from '../types';
import {
  CarbonDataType,
  CarbonDisplayPerType,
  defaultState,
  ForestDataType,
  ForestTemporalType,
  IPolygonContext
} from './types';

const PolygonContext = createContext<IPolygonContext>(defaultState);

export const PolygonProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [getCarbonAccountingData] = useGetCarbonAccountingDataMutation();
  const [getCarbonAccountingDataByArea] = useGetCarbonAccountingByAreaMutation();
  const [getForestCoverData] = useGetForestCoverDataMutation();

  const {
    selectedPolygon,
    forestCoverChartData,
    calculatedArea,
    carbonAccountingChartData,
    carbonLoading,
    forestCoverLoading
  } = useAppSelector((state) => state.regionState);
  const { isDrawing } = useAppSelector((state) => state.drawState);

  const { mapRoot } = useMap();
  const { removeMapSelection } = useMapContext();
  const user = useAppSelector((state) => state.userState.user);

  const loadingRef = useRef(false);

  const [deforestationData] = useState<IDeforestationResponse | null>(null);

  // update of the global loader for polygon context
  useEffect(() => {
    loadingRef.current = carbonLoading || forestCoverLoading;
  }, [carbonLoading, forestCoverLoading]);

  /**
   * Reset of the state for the context
   */
  const resetPolygonData = useCallback(
    (omitResetDraw = false, withDelay = false) => {
      if (!omitResetDraw) {
        dispatch(setIsDrawing(false));
      }

      // sorts all the biomass data reset
      dispatch(resetRegionState());

      document.body.classList.remove('deforestation');

      if (mapRoot?.getStyle().sources[USER_SOURCE_NAME]) {
        mapRoot.removeFeatureState({
          source: USER_SOURCE_NAME
        });
      }

      if (mapRoot?.getStyle().sources[PUBLIC_SOURCE_NAME]) {
        mapRoot.removeFeatureState({
          source: PUBLIC_SOURCE_NAME
        });
      }

      // added setTimeout to avoid having jumping elements changed due to the element change
      // on reset which is happening while closing the data board
      // 300ms because of the time that visibility goes to 0
      if (withDelay) {
        setTimeout(() => dispatch(setSelectedPolygon(null)), 300);
      } else {
        dispatch(setSelectedPolygon(null));
      }
    },
    [mapRoot, dispatch]
  );

  /**
   * Carbon accounting data fetching call, with possibility of sorting
   */
  const getPolygonCarbonAccountingData = useCallback(
    async (
      body: Omit<IBiomassRequest, 'type'>,
      dataType: CarbonDataType = 'sequestered-carbon',
      displayPer: CarbonDisplayPerType = 'data',
      shouldReturn = false
    ) => {
      if (displayPer === 'data') {
        const response = await getCarbonAccountingData({
          ...body,
          type: dataType ? dataType : 'sequestered-carbon',
          returnOnly: shouldReturn
        });
        if (shouldReturn && !('error' in response) && 'data' in response.data) {
          return formatCarbonData(response.data, 'data', user);
        }
      } else {
        const response = await getCarbonAccountingDataByArea({
          ...body,
          type: dataType ? dataType : 'sequestered-carbon',
          returnOnly: shouldReturn
        });
        if (shouldReturn && !('error' in response) && 'data' in response.data) {
          return formatCarbonData(response.data, 'data/area', user);
        }
      }
      return null;
    },
    [getCarbonAccountingData, getCarbonAccountingDataByArea, user]
  );

  /**
   * Forest Cover data fetching, with the possibility of sorting
   */
  const getPolygonForestCoverData = useCallback(
    async (
      body: Omit<IBiomassRequest, 'type'>,
      temporalType: ForestTemporalType = ForestTemporalType.LINEAR,
      dataType: ForestDataType = ForestDataType.PERCENTAGE,
      shouldReturn = false
    ) => {
      const response = await getForestCoverData({
        ...body,
        type: temporalType === ForestTemporalType.LINEAR ? `${dataType}` : `${temporalType}-${dataType}`,
        returnOnly: shouldReturn
      });
      if (shouldReturn && !('error' in response) && 'data' in response.data) {
        return formatForestData(response.data, temporalType, dataType, calculatedArea);
      }
      return null;
    },
    [calculatedArea, getForestCoverData]
  );

  /**
   * Calculation of the area based on the user's selected unit
   */
  const calculatePolygonArea = useCallback(() => {
    const convertedAreaValue =
      selectedPolygon && selectedPolygon.geometry
        ? convertSqKmTo(area(selectedPolygon.geometry) / SQ_METER_TO_KILOMETER_RATIO, user?.settings.unit)
        : 0;

    dispatch(setCalculatedArea(convertedAreaValue));
  }, [dispatch, selectedPolygon, user?.settings.unit]);

  /**
   * Multiple things are happening here:
   * 1. We are calculating the area of the selected polygon
   * 2. We are preparing the body for the Carbon Accounting data fetch
   * 3. We are preparing the body for the Forest Cover data fetch
   * Note: This is just for the initial load of the data
   */
  const getPolygonData = useCallback(async () => {
    calculatePolygonArea();
    // this checks if the data was not already fetched
    if (selectedPolygon && forestCoverChartData.length === 0 && carbonAccountingChartData.length === 0) {
      const body = COMMON_FEATURE_BODY_TYPE(selectedPolygon);

      await Promise.all([getPolygonCarbonAccountingData(body), getPolygonForestCoverData(body)]);
    }
  }, [
    calculatePolygonArea,
    selectedPolygon,
    forestCoverChartData.length,
    carbonAccountingChartData.length,
    getPolygonCarbonAccountingData,
    getPolygonForestCoverData
  ]);

  /**
   * Function responsible for fetching the polygon data and moving the camera to the center of the polygon
   * Additional removal of map selected is used to clean up unnecessary map selections in draw mode
   * There is a possibility to not fetch data when certain flags are provided, like omitRefetch
   */
  useEffect(() => {
    if (
      selectedPolygon?.properties?.pdfUrl ||
      (selectedPolygon?.id &&
        !selectedPolygon.omitRefetch &&
        (!isDrawing || (isDrawing && selectedPolygon.getInDrawMode)))
    ) {
      const centerPoint = center(selectedPolygon.geometry);
      setTimeout(() => {
        mapRoot?.flyTo({
          center: [centerPoint.geometry.coordinates[0], centerPoint.geometry.coordinates[1]]
        });
      }, 300);

      if (!loadingRef.current) {
        void getPolygonData();
      }
    }

    if (isDrawing && !selectedPolygon?.getInDrawMode) {
      removeMapSelection();
    }
  }, [getPolygonData, selectedPolygon, mapRoot, removeMapSelection, isDrawing]);

  return (
    <PolygonContext.Provider
      value={{
        calculatedArea,
        getPolygonCarbonAccountingData,
        getPolygonData,
        getPolygonForestCoverData,
        resetPolygonData,
        deforestationData
      }}
    >
      {children}
    </PolygonContext.Provider>
  );
};

export const usePolygonContext = () => useContext(PolygonContext);
