import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Common/Button';
import Icon from '../components/Common/Icon';
import ForgotPasswordForm from '../components/Forms/ForgotPasswordForm';
import AuthLayout from '../components/Layout/AuthLayout';
import { IconCircleWrapper, Subtitle, Title } from '../components/Layout/AuthLayout/style';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleOnSubmitSuccess = useCallback(() => {
    setIsSubmitted(true);
  }, []);

  const handleOnNavigateClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <AuthLayout>
      {isSubmitted ? (
        <IconCircleWrapper data-test-id="forgot-password-success-icon">
          <Icon color="gray" variant="ENVELOPE" size={20} />
        </IconCircleWrapper>
      ) : null}
      <Title data-test-id="forgot-password-title">{isSubmitted ? t('Email sent') : t('Forgot Password')}</Title>
      <Subtitle data-test-id="forgot-password-subtitle">
        {t("If you have an account, we'll email you instructions on how to reset your password.")}
      </Subtitle>
      {isSubmitted ? (
        <Button
          onClick={handleOnNavigateClick}
          variant="purple"
          additionalSx={{
            width: '190px',
            margin: '30px auto 0'
          }}
          dataTestId={'forgot-password-back-to-login'}
        >
          {t('Back to Log In')}
        </Button>
      ) : null}
      {!isSubmitted ? <ForgotPasswordForm onSuccess={handleOnSubmitSuccess} /> : null}
    </AuthLayout>
  );
};

export default ForgotPassword;
