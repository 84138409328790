import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const Container = styled.div`
  display: flex;
  width: 1440px;
  margin: 72px auto 0;
  padding: 50px 0;
`;

export const ContentWrapper = styled.div`
  margin-left: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.silver};
  font-size: 20px;
  line-height: normal;
  text-align: left;
  font-weight: 700;
  margin-bottom: 24px;
`;
