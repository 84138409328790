import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { Button, Link, Text, Title, Wrapper } from './style';

const CookiesBar = () => {
  const { t } = useTranslation();
  const [isCookiesAccepted, setIsCookiesAccepted] = useLocalStorage('cookies-accepted', false);

  const handleDoNotShowAgain = useCallback(() => {
    setIsCookiesAccepted(true);
  }, [setIsCookiesAccepted]);

  return (
    <Wrapper data-test-id="cookies-bar" active={!isCookiesAccepted}>
      <Title data-test-id="cookies-bar-title">{t('We use cookies')}</Title>
      <Text data-test-id="cookies-bar-text">
        {t('to ensure that we give you best')}
        <br />
        {t('experience on our website. If you continue ')}
        <br /> {t('without changing your settings, we assume')}
        <br />
        {t('that you are happy to receive all cookies on our website.')}
      </Text>
      <Link target="_blank" href="/privacy-policy" data-test-id="cookies-bar-link">
        {t('See more about cookies')}
      </Link>
      <Button data-test-id="cookies-bar-button-accept" onClick={handleDoNotShowAgain}>
        {t('Do not show again')}
      </Button>
    </Wrapper>
  );
};

export default CookiesBar;
