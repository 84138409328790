import { IJob, IJobStatusResponse } from '../../types/API/Jobs';
import { baseApi } from './index';

export const jobsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Jobs']
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // allow passing skip: true to skip the automatic query
      runTilesJob: builder.query<IJob, any>({
        query: () => '/jobs/run/cache-tiles'
      }),
      getJobStatus: builder.query<IJobStatusResponse, { jobId: string }>({
        query: ({ jobId }) => `/jobs/status/${jobId}`,
        providesTags: ['Jobs']
      })
    })
  });

export const { useRunTilesJobQuery, useGetJobStatusQuery } = jobsApi;
