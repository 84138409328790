import { useDroppable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';
import { DroppableWrapper } from './style';

interface Props extends PropsWithChildren {
  id: string;
  folderId?: string;
  value?: string;
}

const Droppable = ({ children, id, folderId }: Props) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
    disabled: !folderId,
    data: { folderId }
  });

  return (
    <DroppableWrapper ref={setNodeRef} isOver={isOver}>
      {children}
    </DroppableWrapper>
  );
};
export default Droppable;
