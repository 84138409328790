import PolicyLayout from '../components/Layout/PolicyLayout';
import Policy from '../components/Policy';
import TermsOfUse from '../constants/Policies/TOU';

const TermsOfUsePage = () => {
  return (
    <PolicyLayout title="Terms of Use" lastUpdated={new Date()}>
      <Policy content={TermsOfUse} />
    </PolicyLayout>
  );
};

export default TermsOfUsePage;
