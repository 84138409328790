import styled from 'styled-components';

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const NameInputError = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
  font-weight: 600;
  line-height: 120%;
`;

export const NameInputLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.lightBlueTransparent};
`;

export const NameInputLabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

export const NameInput = styled.input`
  width: 100%;
  margin: 0 0 -8px;
  font-size: 15px;
  line-height: 18px;
  color: #b6b8ba;
  padding: 14px 16px;
  background-color: #000;
  border-radius: 8px;
  border: 0;

  &:focus {
    outline: none;
  }
`;
