import { MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { openModal } from '../../../../redux/features/modal/modal-slice';
import { setRegionToEditOrDelete } from '../../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Icon from '../../../Common/Icon';
import Spacer from '../../../Common/Spacer';
import {
  ActionButton,
  FolderSummary,
  HeaderTopBar,
  HeaderWrapper,
  NameAndDetails
} from '../../Folder/FolderHeader/style';
import { ButtonWrapper } from './style';

const ProjectHeader = () => {
  const { selectedPolygon } = useAppSelector((state) => state.regionState);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleEditRegion = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!selectedPolygon) {
        return;
      }
      // Prevents the click event from bubbling up to the parent element
      e.stopPropagation();
      dispatch(setRegionToEditOrDelete(selectedPolygon));
      dispatch(openModal('editRegion'));
    },
    [selectedPolygon, dispatch]
  );

  if (!selectedPolygon) {
    return null;
  }

  return (
    <HeaderWrapper>
      <HeaderTopBar>
        <NameAndDetails>
          <h2>{selectedPolygon?.name}</h2>
        </NameAndDetails>
        <ButtonWrapper>
          <ActionButton onClick={() => dispatch(openModal('pdfReport'))}>
            <Icon color="silver" variant="DOWNLOAD" size={24} />
            {t('Export PDF')}
          </ActionButton>
          <ActionButton onClick={(e) => handleEditRegion(e)}>
            <Icon color="silver" variant="PENCIL" size={24} />
            {t('Edit')}
          </ActionButton>
        </ButtonWrapper>
      </HeaderTopBar>
      {selectedPolygon?.summary ? <FolderSummary>{selectedPolygon?.summary}</FolderSummary> : null}
      <Spacer size={'48px 0 0'} />
    </HeaderWrapper>
  );
};

export default ProjectHeader;
