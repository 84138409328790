import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 632px;
  height: calc(100vh - 72px);
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ${({ theme }) => theme.transition};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: 22px;
  line-height: 27px;
  font-weight: bold;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  line-height: 19px;
`;
