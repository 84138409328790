import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Common/Icon';
import Message from '../../../Common/Message';
import Spacer from '../../../Common/Spacer';
import ContactForm from '../../../Forms/ContactForm';
import { AccountSectionTitle, ContactFormTitle, ContactFormWrapper } from '../style';

const AccountContactForm = () => {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);

  return submitted ? (
    <ContactFormWrapper>
      <AccountSectionTitle>{t('Contact us')}</AccountSectionTitle>
      <Spacer size="207px 0 0" />
      <Message text={String(t('Thanks for Contacting Us'))} dataTestId="contact-form-success-message">
        <Icon variant="TICK_CIRCLE" color="green" size={28} />
      </Message>
    </ContactFormWrapper>
  ) : (
    <ContactFormWrapper>
      <ContactFormTitle>{t('Contact Us')}</ContactFormTitle>
      <ContactForm setSubmitted={setSubmitted} />
    </ContactFormWrapper>
  );
};

export default AccountContactForm;
