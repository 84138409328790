import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { regionApi, useGetUserRegionsQuery } from '../../../redux/api/regionApi';
import { resetRegionState, setSelectedPolygon } from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ProjectDetails from './ProjectDetails';
import ProjectHeader from './ProjectHeader';
import ProjectTabs from './ProjectTabs';
import { Wrapper } from './style';

const Project = () => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  useGetUserRegionsQuery(
    {},
    {
      refetchOnMountOrArgChange: true
    }
  );
  const { userTiles, selectedPolygon } = useAppSelector((state) => state.regionState);

  const project = useMemo(() => userTiles?.find((tile) => tile.id === projectId), [projectId, userTiles]);

  useEffect(() => {
    if (project) {
      dispatch(resetRegionState());
      dispatch(setSelectedPolygon(project));
    }
  }, [dispatch, project]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'documentDeletedUpdated') {
        dispatch(regionApi.util.invalidateTags(['SavedRegions']));
      }
    });
  }, [dispatch]);

  if (!selectedPolygon) {
    return <div>No project found.</div>;
  }

  return (
    <Wrapper>
      <ProjectHeader />
      <ProjectDetails />
      <ProjectTabs />
    </Wrapper>
  );
};

export default Project;
