export interface Folder {
  id: number;
  name: string;
  summary: string;
  userId: number;
  regions: { id: number; name: string; userId: number }[];
}

export enum PortfolioSortTypes {
  ALPHABETICAL = 'alphabetical',
  RECENT = 'recent',
  OLDEST = 'oldest'
}
