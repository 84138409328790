import { IDynamicTemplateBody, IMailBody } from '../../types/API/Mail';
import { baseApi } from './index';

export const mailApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation<any, IMailBody>({
      query(body) {
        return {
          url: '/mail/send',
          method: 'POST',
          body
        };
      }
    }),
    sendProMail: builder.mutation<any, IDynamicTemplateBody>({
      query(body) {
        return {
          url: '/mail/send/pro',
          method: 'POST',
          body
        };
      }
    }),
    sendContactUsMail: builder.mutation<any, IDynamicTemplateBody>({
      query(body) {
        return {
          url: '/mail/send/contact',
          method: 'POST',
          body
        };
      }
    }),
    sendRequestUpgrade: builder.mutation<any, IDynamicTemplateBody>({
      query(body) {
        return {
          url: '/mail/send/request-pro',
          method: 'POST',
          body
        };
      }
    })
  })
});

export const {
  useSendEmailMutation,
  useSendProMailMutation,
  useSendContactUsMailMutation,
  useSendRequestUpgradeMutation
} = mailApi;
