import { MouseEvent, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { setRegionIdsToEditOrDelete, setRegionToEditOrDelete } from '../../../redux/features/region/region-slice';
import { useAppDispatch } from '../../../redux/hooks';
import { IRegionResponse } from '../../../types/API/Region';
import { DropdownMenu, DropdownToggle } from '../../Admin/PublicProjects/style';
import Icon from '../../Common/Icon';
import PolygonCanvas from '../../Common/PolygonCanvas';
import MultiPolygonCanvas from '../../Common/PolygonCanvas/MultipolygonCanvas';
import Draggable from '../../DragAndDrop/Draggable';
import { ItemMap, MenuItem } from '../../SavedRegions/style';
import FolderRegionArea from '../Folder/FolderRegionArea';
import {
  RegionItem,
  RegionItemDetails,
  RegionItemName,
  RegionItemProperties,
  RegionItemProperty,
  RegionListWrapper,
  RegionToggle,
  RegionToggleIconWrapper
} from './style';

interface RegionListProps {
  regions: IRegionResponse[];
  selectMode: boolean;
  selectedRegions: string[];
  handleSelectRegion: (id: IRegionResponse['id']) => void;
  menuToShow: number;
  menuRef: RefObject<HTMLButtonElement>;
  handleOpenMenu: (e: MouseEvent<HTMLButtonElement>, index: number) => void;
  handleCloseMenu: () => void;
}

const RegionList = ({
  regions,
  selectMode,
  selectedRegions,
  handleSelectRegion,
  menuToShow,
  menuRef,
  handleOpenMenu,
  handleCloseMenu
}: RegionListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLinkClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  const handleShowOnMap = useCallback(
    (e: MouseEvent<HTMLButtonElement>, data: IRegionResponse) => {
      handleLinkClick(e);
      window.open(`/?region=${String(data.id)}`, '_blank');
    },
    [handleLinkClick]
  );

  const handleEditRegion = useCallback(
    (e: MouseEvent<HTMLButtonElement>, feature: IRegionResponse) => {
      handleLinkClick(e);
      dispatch(setRegionToEditOrDelete(feature));
      dispatch(openModal('editRegion'));
    },
    [dispatch, handleLinkClick]
  );

  const handleRegionDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>, data: IRegionResponse) => {
      handleLinkClick(e);
      dispatch(setRegionToEditOrDelete(data));
      dispatch(openModal('deleteRegion'));
    },
    [dispatch, handleLinkClick]
  );

  const handleMoveToFolder = useCallback(
    (e: MouseEvent<HTMLButtonElement>, region: IRegionResponse) => {
      handleLinkClick(e);
      if (region.id) {
        dispatch(setRegionIdsToEditOrDelete([String(region.id)]));
        dispatch(openModal('changeRegionsFolder'));
      }
    },
    [dispatch, handleLinkClick]
  );

  return (
    <RegionListWrapper>
      {regions?.map((region, index) => (
        <Draggable
          key={region.id}
          id={String(region.id)}
          folderId={region.folderId}
          regionId={String(region.id)}
          disabled={selectMode}
        >
          <RegionItem
            onClick={
              selectMode
                ? () => handleSelectRegion(region.id)
                : () =>
                    navigate(`/portfolio/folder/${region.folderId || 'unassigned'}/project/${region.id || ''}`, {
                      relative: 'path'
                    })
            }
          >
            <ItemMap
              style={{
                margin: 0
              }}
            >
              {region.geometry.type === 'Polygon' ? (
                <PolygonCanvas
                  // data-test-id={`saved-regions-region-polygon-canvas-${index}`}
                  width={71}
                  fillColor="rgba(20, 162, 241, 0.56)"
                  strokeColor="rgba(20, 162, 241, 1)"
                  height={71}
                  polygon={region}
                />
              ) : (
                <MultiPolygonCanvas
                  // data-test-id={`saved-regions-region-multipolygon-canvas-${index}`}
                  fillColor="rgba(20, 162, 241, 0.56)"
                  strokeColor="rgba(20, 162, 241, 1)"
                  width={71}
                  height={71}
                  polygon={region as unknown as GeoJSON.Feature<GeoJSON.MultiPolygon>}
                />
              )}
            </ItemMap>
            <RegionItemDetails>
              <RegionItemName>{region.name}</RegionItemName>
              <RegionItemProperties>
                {region.properties?.type ? <RegionItemProperty>{region.properties.type}</RegionItemProperty> : null}
                <FolderRegionArea region={region} />
                {region.properties?.registry ? (
                  <RegionItemProperty>{region.properties?.registry}</RegionItemProperty>
                ) : null}
              </RegionItemProperties>
            </RegionItemDetails>
            <RegionToggle ref={menuRef}>
              {selectMode ? (
                <RegionToggleIconWrapper selected={selectedRegions.includes(String(region.id))}>
                  <Icon
                    variant={selectedRegions.includes(String(region.id)) ? 'SELECT_CHECKED' : 'SELECT'}
                    size={selectedRegions.includes(String(region.id)) ? 24 : 18}
                    color={selectedRegions.includes(String(region.id)) ? 'green' : 'silver'}
                    data-test-id="portfolio-regions-list-select"
                  />
                </RegionToggleIconWrapper>
              ) : (
                <>
                  <DropdownToggle
                    onClick={(e) => handleOpenMenu(e, index)}
                    data-test-id="portfolio-regions-list-toggle"
                  >
                    <Icon variant="THREE_DOTS_HORIZONTAL" size={24} color="white" />
                  </DropdownToggle>
                  <DropdownMenu
                    position="right"
                    active={menuToShow === index}
                    data-test-id="portfolio-regions-list-menu"
                    style={{
                      right: '25px'
                    }}
                  >
                    <MenuItem
                      data-test-id="portfolio-regions-list-view-on-map"
                      onClick={(e) => handleShowOnMap(e, region)}
                    >
                      {t('View on Map')}
                    </MenuItem>
                    <MenuItem data-test-id="portfolio-regions-list-edit" onClick={(e) => handleEditRegion(e, region)}>
                      {t('Edit Details')}
                    </MenuItem>
                    <MenuItem
                      data-test-id="portfolio-regions-list-move-to-folder"
                      onClick={(e) => handleMoveToFolder(e, region)}
                    >
                      {t('Move to Folder')}
                    </MenuItem>
                    <MenuItem
                      data-test-id="portfolio-regions-list-delete"
                      onClick={(e) => handleRegionDelete(e, region)}
                    >
                      {t('Delete Project')}
                    </MenuItem>
                  </DropdownMenu>
                </>
              )}
            </RegionToggle>
          </RegionItem>
        </Draggable>
      ))}
    </RegionListWrapper>
  );
};

export default RegionList;
