import styled from 'styled-components';

export const FolderListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FolderItem = styled.li`
  display: grid;
  grid-template-columns: 112px 1fr;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blackII};
  position: relative;
`;

export const FolderItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const FolderItemName = styled.h3`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.silver};
  font-weight: 700;
  margin-bottom: 5px;
`;

export const FolderItemProperties = styled.div`
  display: flex;
  gap: 30px;
  align-items: baseline;
`;

export const FolderItemProperty = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 500;
`;

export const FolderToggle = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  border: 0;
`;

export const FolderToggleIconWrapper = styled.div<{ selected: boolean }>`
  margin-top: ${({ selected }) => (selected ? '-3px' : 0)};
  margin-right: ${({ selected }) => (selected ? '-3px' : 0)};
`;
