import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import { useGetHeatmapsZipLinkMutation, useGetSingleHeatmapLinkMutation } from '../redux/api/biomassApi';
import { useAppSelector } from '../redux/hooks';

const useDownloadImages = () => {
  const [getHeatmapsZipLink] = useGetHeatmapsZipLinkMutation();
  const [getSingleImageLink] = useGetSingleHeatmapLinkMutation();
  const { selectedPolygon, deforestation } = useAppSelector((state) => state.regionState);
  const [isDownloading, setIsDownloading] = useState(false);

  const preparePolygonData = useCallback((): GeoJSON.FeatureCollection<GeoJSON.Polygon> | null => {
    if (!selectedPolygon) {
      return null;
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: selectedPolygon.properties,
          geometry: selectedPolygon?.geometry
        }
      ]
    };
  }, [selectedPolygon]);

  const downloadFile = async (url: string, fileName: string) => {
    const fileResponse = await fetch(url);
    const blob = await fileResponse.blob();
    saveAs(blob, fileName);
  };

  const downloadSingleImage = useCallback(
    async (index: number) => {
      if (!selectedPolygon) return;

      setIsDownloading(true);
      try {
        const polygon = preparePolygonData();
        if (polygon) {
          const response = await getSingleImageLink({ polygon, index });

          if (!('error' in response)) {
            await downloadFile(
              response.data.url,
              `${selectedPolygon?.name ?? 'Deforestation'}-${new Date(
                deforestation?.times[index] || ''
              ).toLocaleDateString()}.tif`
            );
          }
        }
      } finally {
        setIsDownloading(false);
      }
    },
    [getSingleImageLink, preparePolygonData, selectedPolygon, deforestation?.times]
  );

  const downloadImages = useCallback(async () => {
    if (!selectedPolygon) return;

    setIsDownloading(true);
    try {
      const body = preparePolygonData();
      if (body) {
        const response = await getHeatmapsZipLink(body);

        if (!('error' in response)) {
          await downloadFile(response.data.url, 'images.zip');
        }
      }
    } finally {
      setIsDownloading(false);
    }
  }, [getHeatmapsZipLink, preparePolygonData, selectedPolygon]);

  return {
    downloadSingleImage,
    downloadImages,
    isDownloading
  };
};

export default useDownloadImages;
