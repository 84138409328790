import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VALIDATION_ERRORS } from '../../../../constants/validation';
import { useUpdatePublicProjectMutation } from '../../../../redux/api/publicProjectsApi';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { setProjectToEditOrDelete } from '../../../../redux/features/public-projects/public-projects-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  ContinentType,
  EnumProjectType,
  IRegionPolygonProperties,
  UpdatePublicProjectDto
} from '../../../../types/API/PublicProjects';
import {
  validateLetterSpaceCertainCharacters,
  validateLettersSpace,
  validateLettersSpacePlus
} from '../../../../utils/validation';
import Button from '../../../Common/Button';
import Input from '../../../Common/Input';
import Modal from '../../../Common/Modal';
import Select from '../../../Common/Select';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';
import { continentOptions, IErrors, projectTypeOptions } from '../CreatePublicProjectModal/MetadataStep';

const UpdatePublicProjectModal = () => {
  const dispatch = useAppDispatch();
  const { projectToEditOrDelete } = useAppSelector((state) => state.publicProjectsState);
  const [updateProject, { isLoading, reset, error }] = useUpdatePublicProjectMutation();
  const { t } = useTranslation();
  const [name, setName] = useState<string>(projectToEditOrDelete?.name || '');
  const [properties, setProperties] = useState<IRegionPolygonProperties>(
    projectToEditOrDelete?.properties || {
      continent: ContinentType.AFRICA,
      country: '',
      pdfUrl: '',
      projectType: EnumProjectType.ARR
    }
  );
  const [errors, setErrors] = useState<IErrors>({});

  // params are fields (keyof IErrors) and validation function for each one of them (same length as keyof IErrors)
  const validateFields = useCallback(() => {
    const isNameCorrect = validateLetterSpaceCertainCharacters(name.trim());
    const isCountryCorrect = properties.country ? validateLettersSpace(properties.country.trim()) : false;
    const isContinentCorrect = properties.continent ? validateLettersSpace(properties.continent.trim()) : false;
    const isProjectTypeCorrect = properties.projectType
      ? validateLettersSpacePlus(properties.projectType.trim())
      : false;

    // go through all incorrect fields and set errors
    const incorrectFields: (keyof IErrors)[] = [];

    if (!isNameCorrect) incorrectFields.push('name');
    if (!isCountryCorrect) incorrectFields.push('country');
    if (!isContinentCorrect) incorrectFields.push('continent');
    if (!isProjectTypeCorrect) incorrectFields.push('projectType');

    const newErrors: IErrors = {
      name: '',
      country: '',
      continent: '',
      projectType: ''
    };

    incorrectFields.forEach((field) => {
      newErrors[field] =
        field === 'name' ? VALIDATION_ERRORS(t).LETTERS_SPACES_AND_SPECIAL : VALIDATION_ERRORS(t).LETTERS_AND_SPACES;
    });

    if (newErrors.name || newErrors.country || newErrors.continent || newErrors.projectType) {
      setErrors(newErrors);
      return false;
    }

    return true;
  }, [properties.continent, properties.country, properties.projectType, name, t]);

  const handleOnSave = async () => {
    if (!projectToEditOrDelete?.id) {
      return;
    }

    const isValid = validateFields();
    if (!isValid) {
      toast.error(VALIDATION_ERRORS(t).GENERIC);
      return;
    }

    if (
      JSON.stringify(properties) === JSON.stringify(projectToEditOrDelete?.properties) &&
      name === projectToEditOrDelete?.name
    ) {
      dispatch(setProjectToEditOrDelete(undefined));
      dispatch(closeModal());
      return;
    }

    const trimmedName = name?.trim();
    if (trimmedName?.length === 0) {
      toast.error(
        t('Project name cannot be empty', {
          toastId: 'project-name-empty',
          autoClose: 3000
        })
      );
      return;
    }

    const body: UpdatePublicProjectDto = {
      properties,
      name: trimmedName
    };

    const data = await updateProject({
      id: projectToEditOrDelete.id,
      body
    });

    if (!('error' in data)) {
      toast.success(
        t('Project updated successfully', {
          toastId: 'project-updated-successfully',
          autoClose: 3000
        })
      );
      dispatch(setProjectToEditOrDelete(undefined));
      dispatch(closeModal());
    }
  };

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'update-region-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  const isButtonDisabled = useMemo(() => {
    const isNameNotEmptyAndChanged = name?.trim() !== '' && name !== projectToEditOrDelete?.name;
    const isCountryNotEmptyAndChanged =
      properties.country?.trim() !== '' && properties.country !== projectToEditOrDelete?.properties.country;
    const isContinentChanged = properties.continent !== projectToEditOrDelete?.properties.continent;
    const isProjectTypeChanged = properties.projectType !== projectToEditOrDelete?.properties.projectType;

    return !isNameNotEmptyAndChanged && !isCountryNotEmptyAndChanged && !isContinentChanged && !isProjectTypeChanged;
  }, [
    name,
    projectToEditOrDelete?.name,
    projectToEditOrDelete?.properties.continent,
    projectToEditOrDelete?.properties.country,
    projectToEditOrDelete?.properties.projectType,
    properties.continent,
    properties.country,
    properties.projectType
  ]);

  return (
    <Modal
      modalType="editPublicProject"
      dataTestId="update-public-project-modal"
      onClose={() => dispatch(setProjectToEditOrDelete(undefined))}
    >
      <Title data-test-id="update-public-project-modal-title">{t('Edit Public Project')}</Title>
      {projectToEditOrDelete?.properties.name ? (
        <Subtitle data-test-id="update-public-project-modal-subtitle">{projectToEditOrDelete.properties.name}</Subtitle>
      ) : null}
      <Spacer size="20px 0 0" />
      <Input
        value={name || ''}
        required
        label={String(t('Project name'))}
        name="update-public-project-name"
        id="update-public-project-name"
        onChange={(value) => setName(value)}
        dataTestId="update-public-project-metadata-name"
        noMargin
        maxLength={50}
        fullWidth
        error={errors.name}
        onBlur={() => setName(name.trim())}
      />
      <Spacer size={'20px 0 0'} />
      <Select
        value={properties.continent || ContinentType.AFRICA}
        onChange={(value: ContinentType) => setProperties({ ...properties, continent: value })}
        label={String(t('Continent'))}
        options={continentOptions}
        error={errors.continent}
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
        fullWidth
        autoWidth={false}
      />
      <Spacer size={'20px 0 0'} />
      <Input
        value={properties.country || ''}
        required
        label={String(t('Country'))}
        name="update-public-project-country"
        id="update-public-project-country"
        onChange={(value) => setProperties({ ...properties, country: value })}
        dataTestId="update-public-project-metadata-country"
        noMargin
        maxLength={50}
        fullWidth
        error={errors.country}
        onBlur={() =>
          setProperties({
            ...properties,
            country: properties.country?.trim()
          })
        }
      />
      <Spacer size={'20px 0 0'} />
      <Select
        value={properties.projectType}
        onChange={(value: EnumProjectType) => setProperties({ ...properties, projectType: value })}
        label={String(t('Project type'))}
        options={projectTypeOptions}
        error={errors.projectType}
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
        fullWidth
        autoWidth={false}
      />
      <Spacer size={'20px 0 0'} />
      {properties.pdfUrl ? (
        <Input
          value={properties.pdfUrl || ''}
          readOnly
          disabled
          label={String(t('PDF Link'))}
          name="update-public-project-pdf-url"
          id="update-public-project-pdf-url"
          dataTestId="update-public-project-metadata-pdf-url"
          noMargin
          maxLength={25}
          fullWidth
        />
      ) : null}
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="outline-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          dataTestId="update-public-project-modal-delete-region-button"
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="purple"
          fullWidth
          dataTestId="update-public-project-modal-update-button"
          disabled={isButtonDisabled || isLoading}
          onClick={handleOnSave}
        >
          {t('Update')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default UpdatePublicProjectModal;
