import styled from 'styled-components';
import { AlignType } from '.';

export const Wrapper = styled.div<{ align: AlignType }>`
  display: flex;
  flex-direction: column;
  max-width: 176px;
  margin: ${({ align }) => (align === 'center' ? '0 auto' : 0)};
`;

export const LogoTitle = styled.span<{ align: AlignType }>`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.white};
  text-align: ${({ align }) => align};
  margin-top: 8px;
`;
