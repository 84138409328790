import { useTranslation } from 'react-i18next';
import Button from '../Common/Button';
import { Description, Title, Wrapper } from './style';

interface Props {
  onConfirm: () => void;
  active: boolean;
}

const MobileBanner = ({ onConfirm, active }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper active={active}>
      <Title>{t('Whoops!')}</Title>
      <Description>
        {t('We regret to inform you that our current application interface is not optimised for mobile devices.')}
        <br />
        <br />
        {t(
          'For the optimal experience, we recommend accessing our web application from a desktop device. We apologise for any inconvenience this may cause.'
        )}
      </Description>
      <Button variant="green" fullWidth onClick={onConfirm}>
        {t('I understand')}
      </Button>
    </Wrapper>
  );
};

export default MobileBanner;
