import { useTranslation } from 'react-i18next';
import Logo from '../../Common/Logo';
import { LogoTitle, Wrapper } from './style';

export type AlignType = 'center' | 'left' | 'right';

interface Props {
  align: AlignType;
}

const AdminLogo = ({ align = 'center' }: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper align={align}>
      <Logo dataTestId="admin-logo" />
      <LogoTitle align={align} data-test-id="admin-logo-title">
        {t('Admin Panel')}
      </LogoTitle>
    </Wrapper>
  );
};

export default AdminLogo;
