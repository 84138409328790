import PolicyLayout from '../components/Layout/PolicyLayout';
import Policy from '../components/Policy';
import PrivacyPolicy from '../constants/Policies/PP';

const PrivacyPolicyPage = () => {
  return (
    <PolicyLayout title="Privacy policy" lastUpdated={new Date()}>
      <Policy content={PrivacyPolicy} />
    </PolicyLayout>
  );
};

export default PrivacyPolicyPage;
