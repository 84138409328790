import { SpacerComponent } from './style';

interface Props {
  size: string | number;
  dataTestId?: string;
}

const Spacer = ({ size }: Props) => {
  return <SpacerComponent margin={size} />;
};

export default Spacer;
