import { useParams } from 'react-router-dom';
import Project from '../../components/Portfolio/Project';

const ProjectPage = () => {
  const { projectId } = useParams();

  if (!projectId) {
    return <div>No project ID found</div>;
  }

  return <Project />;
};

export default ProjectPage;
