import { useTranslation } from 'react-i18next';
import { COUNTRIES_LIST } from '../../../../../constants/countries';
import { useGetUserFoldersQuery } from '../../../../../redux/api/folderApi';
import { useAppSelector } from '../../../../../redux/hooks';
import { IRegionProperties } from '../../../../../types/API/Region';
import Input from '../../../../Common/Input';
import Select from '../../../../Common/Select';
import Textarea from '../../../../Common/Textarea';
import { IProps } from '../index';

const Overview = ({ submitData, setSubmitData }: IProps) => {
  useGetUserFoldersQuery();

  const { t } = useTranslation();
  const { folders } = useAppSelector((state) => state.folderState);
  const folderOptions = [
    {
      value: 'empty',
      label: String(t('Select folder from the list')),
      isPlaceholder: true
    }
  ].concat(
    folders.map((folder) => ({
      value: folder.id,
      label: folder.name,
      isPlaceholder: false
    }))
  );

  const STATUS_OPTIONS = [
    {
      value: 'empty',
      label: String(t('Select Status')),
      isPlaceholder: true
    },
    {
      value: 'active',
      label: String(t('Active')),
      isPlaceholder: false
    },
    {
      value: 'inactive',
      label: String(t('Inactive')),
      isPlaceholder: false
    }
  ];

  if (!submitData) return null;

  return (
    <form>
      <Input
        value={submitData.name}
        onChange={(value) => setSubmitData({ ...submitData, name: value })}
        label={String(t('Project Name'))}
        omitPlaceholder
        required
      />
      {folders.length > 0 ? (
        <Select
          value={submitData.folderId || 'empty'}
          onChange={(value: string) => setSubmitData({ ...submitData, folderId: value })}
          additionalPaperSx={{
            ul: {
              padding: 0
            }
          }}
          optionWidth={'398px'}
          fullWidth
          options={folderOptions}
          label={String(t('Add to Folder (optional)'))}
        />
      ) : null}
      <Select
        value={submitData.polygon.properties.status || 'empty'}
        onChange={(value: IRegionProperties['status']) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: { ...submitData.polygon.properties, status: value }
            }
          })
        }
        options={STATUS_OPTIONS}
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
        label={String(t('Project Status (optional)'))}
        fullWidth
        optionWidth={'398px'}
      />
      <Select
        value={submitData.polygon.properties.country || []}
        multiple
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
        onChange={(value: string[]) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: {
                ...submitData.polygon.properties,
                country: value
              }
            }
          })
        }
        options={COUNTRIES_LIST}
        label={String(t('Country (optional)'))}
        fullWidth
        optionWidth={'398px'}
      />
      <Textarea
        value={submitData.summary || ''}
        onChange={(value) => setSubmitData({ ...submitData, summary: value })}
        label={String(t('Project Summary (optional)'))}
        minRows={5}
        omitPlaceholder
      />
    </form>
  );
};

export default Overview;
