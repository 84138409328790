import { ChangeEvent, DragEventHandler, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Common/Icon';
import { SUPPORTED_SHAPEFILE_EXTENSIONS } from '../../../constants/region';
import { UploadSection, UploadSectionButton, UploadSectionText, UploadSectionTitle } from './style';

export interface Props {
  inputRef: RefObject<HTMLInputElement>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDragOver: DragEventHandler;
  handleDragLeave: DragEventHandler;
  handleDrop: DragEventHandler;
  accept: string;
}

const UploadSectionComponent = ({
  inputRef,
  onChange,
  handleDragLeave,
  handleDragOver,
  handleDrop,
  accept = SUPPORTED_SHAPEFILE_EXTENSIONS
}: Props) => {
  const { t } = useTranslation();

  return (
    <UploadSection
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      data-test-id="upload-section"
    >
      <Icon variant="UPLOAD" color="white" size={24} />
      <UploadSectionTitle data-test-id="upload-section-title">{t('Drag & Drop your file here or')}</UploadSectionTitle>
      <UploadSectionButton>
        {t('Browse files')}
        <input
          type="file"
          hidden
          onChange={onChange}
          accept={accept}
          ref={inputRef}
          data-test-id="upload-section-input"
        />
      </UploadSectionButton>
      <UploadSectionText data-test-id="upload-section-formats">
        {t(`Accepted formats: ${accept.replaceAll(',', ', ')}`)}
      </UploadSectionText>
    </UploadSection>
  );
};

export default UploadSectionComponent;
