import { Card, CardSubtitle, CardTitle } from './style';

interface IProps {
  title: string;
  subtitle: string;
  id?: string;
}

const SummaryCard = ({ title, subtitle, id }: IProps) => {
  return (
    <Card id={id}>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle>{subtitle}</CardSubtitle>
    </Card>
  );
};

export default SummaryCard;
