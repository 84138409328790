import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  h1 {
    color: ${({ theme }) => theme.colors.silver};
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 50px;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;

  .MuiTabs-flexContainer {
    align-items: flex-start;
    gap: 8px;
  }

  button {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 20px;
    line-height: 27.32px;
    text-transform: none;
    padding: 10px 32px 10px 32px;
    min-height: initial;
  }

  button.Mui-selected {
    color: ${({ theme }) => theme.colors.green};

    .MuiTab-iconWrapper {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }

  .MuiTabs-indicator {
    background-color: transparent;
  }
`;

export const SidebarButtonContainer = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 121px;
  width: 300px;
  background-color: ${({ theme }) => theme.colors.blackII};
  border: 1px solid ${({ theme }) => theme.colors.blackII};
  gap: 10px;
  padding: 24px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 24px;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.green};
  }

  p {
    color: ${({ theme }) => theme.colors.silver};
    font-size: 16px;
    line-height: 21.86px;
    font-weight: 700;
  }
`;

export const MenuOptions = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 30px;
  width: 200px;
  background-color: ${({ theme }) => theme.colors.blackIV};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

export const MenuItem = styled.button<{ active?: boolean }>`
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: ${({ active, theme }) => (active ? theme.colors.green : theme.colors.silver)};
  cursor: pointer;
  text-align: left;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const Divider = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.blackIV};
  width: 300px;
`;
