import styled from 'styled-components';

export const DeforestationWrapper = styled.div`
  position: relative;
  max-width: 1050px;

  div:last-child {
    flex-direction: column;
    gap: 32px;
    max-width: 1050px;
  }

  div {
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 1050px;

    &:before {
      border-radius: 8px;
      background: none;
      max-width: 1050px;
    }

    button {
      align-items: center;
    }
  }
`;

export const ProjectControlsWrapper = styled.div`
  display: flex;
  padding: 0 0 24px;
  gap: 20px;
  width: 100%;
  justify-content: center;

  div {
    flex: 1;
  }
`;

export const TabWrapper = styled.div`
  padding: 30px;
  gap: 24px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(25, 43, 52, 0) 0%, #192b34 76.56%);
  height: 510px;
  width: 100%;
  max-width: 1090px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  div {
    position: relative;
    height: 420px;
    align-items: center;
    justify-content: center;
  }
`;
