import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadLocalStorage } from 'usehooks-ts';
import { useRecacheTileMutation } from '../../../redux/api/biomassApi';
import { useGetJobStatusQuery, useRunTilesJobQuery } from '../../../redux/api/jobsApi';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch } from '../../../redux/hooks';
import { JobStatus } from '../../../types/API/Jobs';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import { ButtonWrapper, Section, SectionSubtitle, SectionTitle, Wrapper } from './style';

const LOCAL_STORAGE_CACHING_JOB_ID = 'caching_job';
const IN_PROGRESS_STATUSES: JobStatus[] = ['running', 'queued', 'retried'];
const ENDED_STATUSES: JobStatus[] = ['success', 'failed', 'canceled'];

const Cache = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [recacheTile] = useRecacheTileMutation();

  const [isLocked, setIsLocked] = useState(false);
  const [skip, setSkip] = useState(true);
  const jobId = useReadLocalStorage<string>(LOCAL_STORAGE_CACHING_JOB_ID) || '';

  const [tileName, setTileName] = useState('');

  const { data: jobData } = useRunTilesJobQuery(null, {
    skip
  });
  const { data: jobStatusData } = useGetJobStatusQuery(
    { jobId },
    {
      skip: jobId === ''
    }
  );

  useEffect(() => {
    if (jobData?.jobId) {
      localStorage.setItem(LOCAL_STORAGE_CACHING_JOB_ID, JSON.stringify(jobData.jobId));
      setIsLocked(true);
    }
  }, [jobData?.jobId]);

  const handleSetTileName = (value: string) => {
    setTileName(value);
  };

  const handleGetJob = useCallback(() => {
    setSkip(false);
  }, []);

  const handleRecacheTile = useCallback(async () => {
    await recacheTile({ tileName });
  }, [recacheTile, tileName]);

  const setUIBasedOnJobState = useCallback(() => {
    if (jobId !== '') {
      try {
        if (jobStatusData?.items) {
          const item = jobStatusData.items.find((el) => el.pipeline_id === jobId);
          if (item) {
            setIsLocked(IN_PROGRESS_STATUSES.includes(item.status));

            if (ENDED_STATUSES.includes(item.status)) {
              localStorage.removeItem(LOCAL_STORAGE_CACHING_JOB_ID);
            }
          }
        }
      } catch (err) {
        setIsLocked(false);
      }
    }
  }, [jobStatusData?.items, jobId]);

  useEffect(() => {
    if (jobId !== '' && jobStatusData?.items) {
      setUIBasedOnJobState();
    }
  }, [setUIBasedOnJobState, jobStatusData?.items, jobId]);

  return (
    <Wrapper>
      <Section>
        <SectionTitle data-test-id="admin-cache-title">{t('All tiles invalidation')}</SectionTitle>
        {isLocked ? (
          <SectionSubtitle data-test-id="admin-cache-subtitle">
            {t('Your data is being cached. To check status again refresh page.')}
          </SectionSubtitle>
        ) : (
          <>
            <SectionSubtitle data-test-id="admin-cache-subtitle">
              {t('Invalidate data for all the cached tiles')}
            </SectionSubtitle>
            <ButtonWrapper>
              <Button
                data-test-id="admin-cache-button-invalidate"
                onClick={() => dispatch(openModal('adminCacheInvalidation'))}
                variant="red"
              >
                {t('Invalidate all data')}
              </Button>
              <Button data-test-id="admin-cache-button-cache-all" onClick={handleGetJob} variant="purple">
                {t('Cache all tiles')}
              </Button>
            </ButtonWrapper>
            <br />
            <ButtonWrapper>
              <Input
                required
                value={tileName}
                variant="black"
                label={String(t('Recache Tile'))}
                placeholder={String(t('Tile Name'))}
                onChange={handleSetTileName}
                width="100"
                dataTestId="recache-tile-form-tile-name"
              />
              <br />
              <Button data-test-id="admin-cache-button-invalidate-tile" onClick={handleRecacheTile} variant="red">
                {t('Erase & Recache')}
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Section>
    </Wrapper>
  );
};

export default Cache;
