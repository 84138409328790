import styled from 'styled-components';
import { IconWrapper } from '../../Common/Icon/style';

export const BackToSavedRegions = styled.button`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.lightBlueTransparent};
  margin-bottom: 20px;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  display: flex;
  align-items: center;

  ${IconWrapper} {
    background-color: ${({ theme }) => theme.colors.lightBlueTransparent};
    margin-right: 4px;
    margin-top: -2px;
    margin-left: -4px;
    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    opacity: 0.6;
    ${IconWrapper} {
      transform: translate3d(-5px, 0, 0);
    }
  }
`;

export const TextSections = styled.div`
  display: flex;
  gap: 25px;
  max-width: 505px;
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextTitle = styled.p`
  text-transform: capitalize;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 23px;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 30px;
  display: flex;
  word-wrap: anywhere;

  sup {
    font-size: 50%;
    margin-left: 3px;
    margin-top: -5px;
  }
`;

export const Navigation = styled.div`
  margin-bottom: -32px;

  .MuiTabs-root {
    height: 40px;
    width: 100%;
  }

  .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.green};
  }

  .MuiTabs-fixed .MuiTabs-flexContainer > button {
    margin-right: 32px;
    text-transform: none;
    color: rgba(208, 247, 255, 0.6);
  }

  .MuiTabs-fixed .MuiTabs-flexContainer > .Mui-selected {
    color: #2cac7e;
  }

  .MuiTabPanel-root {
    padding: 0 0 30px;
  }

  button {
    font-size: 16px;
    min-width: unset;
    line-height: 19px;
    padding: 0;
    margin-right: 10px;
    align-items: flex-start;
  }
`;
