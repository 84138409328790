import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { NO_DATA_COVERAGE_MODAL_STEPS } from '../../../../constants/joyride';
import { useRegionContext } from '../../../../context/Region';
import { useDrawHook } from '../../../../hooks/useDrawHook';
import useJoyride from '../../../../hooks/useJoyride';
import { removeSteps, rerunJoyrideWithNoCleanup } from '../../../../redux/features/joyride/joyride-slice';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import InformationBanner from '../../../Common/InformationBanner';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';
import { ModalSx } from '../style';

const EditRegionNoCoverageModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [reportMissingData, setReportMissingData] = useState(false);
  const [polygonMessage, setPolygonMessage] = useState('');
  const { user } = useAppSelector((state) => state.userState);
  const { drawnFeatures } = useAppSelector((state) => state.drawState);
  const { reportRegion } = useRegionContext();
  const { startOverFromModal } = useDrawHook();
  const { activeModal } = useAppSelector((state) => state.modalState);

  // reset message when modal is closed
  useEffect(() => {
    return () => {
      setPolygonMessage('');
      setReportMissingData(false);
      dispatch(rerunJoyrideWithNoCleanup());
    };
  }, [dispatch]);

  const handleChangeDiagnosticsSwitch = useCallback(() => {
    setReportMissingData((wasSent) => !wasSent);
  }, []);

  const handleReportMissingPolygonParts = useCallback(async () => {
    // Send a request to prioritize adding missing data to this region
    if (reportMissingData && user?.email) {
      if (polygonMessage.length === 0) {
        toast.error(
          t('Please provide a reason to use data', {
            toastId: 'edit-region-no-data-coverage-modal-error',
            autoClose: 3000
          })
        );
        return;
      }
      const elToGet = Object.values(drawnFeatures)[0];
      const polygonCollection: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: [elToGet]
      };
      await reportRegion(polygonCollection, user.email);
    }
  }, [drawnFeatures, polygonMessage.length, reportRegion, reportMissingData, t, user?.email]);

  const handleStartOver = useCallback(async () => {
    await handleReportMissingPolygonParts();
    startOverFromModal();
  }, [handleReportMissingPolygonParts, startOverFromModal]);

  useJoyride(NO_DATA_COVERAGE_MODAL_STEPS(t), activeModal !== 'editRegionNoCoverage');

  return (
    <Modal
      modalType="editRegionNoCoverage"
      withCloseIcon={false}
      zIndex={999}
      additionalSx={ModalSx}
      dataTestId="edit-region-no-data-coverage-modal"
      onClose={() => {
        dispatch(removeSteps(NO_DATA_COVERAGE_MODAL_STEPS(t)));
        setTimeout(() => {
          dispatch(rerunJoyrideWithNoCleanup());
        }, 0);
      }}
    >
      <Title id="no-data-coverage-modal-title" data-test-id="edit-region-no-data-coverage-modal-title">
        {t('No data coverage for this area')}
      </Title>
      <Subtitle data-test-id="edit-region-no-data-coverage-modal-subtitle">
        {user?.role === 'free'
          ? t('Data is not available for this region for free accounts. Pro accounts may request data from our team')
          : t('Your region must be within the the data tiles. Please adjust your region by starting over.')}
      </Subtitle>
      <Spacer size="24px 0 0" />
      {user?.role !== 'free' ? (
        <>
          <InformationBanner
            checked={reportMissingData}
            onToggle={handleChangeDiagnosticsSwitch}
            text={t('Send a request to prioritize adding missing data to this region')}
            message={polygonMessage}
            setMessage={setPolygonMessage}
          />
          <Spacer size="20px 0 0" />
        </>
      ) : null}
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => {
            dispatch(removeSteps(NO_DATA_COVERAGE_MODAL_STEPS(t)));
            dispatch(closeModal());
          }}
          dataTestId="edit-region-no-data-coverage-modal-button-dismiss"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto'
          }}
        >
          {t('Dismiss')}
        </Button>
        <Button
          variant="purple"
          fullWidth
          dataTestId="edit-region-no-data-coverage-modal-button-start-over"
          onClick={handleStartOver}
          disabled={reportMissingData && polygonMessage.length === 0}
        >
          {reportMissingData ? t('Send Request') : t('Start Over')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default EditRegionNoCoverageModal;
