import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAddFolderMutation } from '../../../../redux/api/folderApi';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import Input from '../../../Common/Input';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import Textarea from '../../../Common/Textarea';
import { ButtonWrapper, Title } from '../../ModalCommon.style';

const CreateFolderModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [addFolder, { isLoading, error, reset }] = useAddFolderMutation();

  const handleNameChange = useCallback((newName: string) => {
    setName(newName);
    setErrorMessage(undefined);
  }, []);

  const handleSummaryChange = useCallback((newSummary: string) => {
    setSummary(newSummary);
  }, []);

  const handleOnSave = useCallback(async () => {
    const data = await addFolder({ name, summary });

    if (!('error' in data)) {
      toast.success(t('Folder created successfully'), {
        autoClose: 3000,
        toastId: 'save-folder-toast'
      });

      navigate(`/portfolio/folder/${data.data.id}`);
      dispatch(closeModal());
    }
  }, [addFolder, dispatch, name, navigate, summary, t]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      if ((error.data as any).statusCode === 409) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        setErrorMessage((error.data as any).message);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        toast.error((error.data as any).message, {
          autoClose: 3000,
          toastId: 'create-folder-error-toast'
        });
      }
      reset();
    }
  }, [error, reset, t]);

  return (
    <Modal withCloseIcon={false} dataTestId={'create-folder-modal'} modalType="createFolder">
      <Title data-test-id="create-folder-modal-title">{t('Add new folder')}</Title>
      <Spacer size="30px 0 0" />
      <Input
        dataTestId="create-folder-modal-name-input"
        value={name}
        required
        label={String(t('Folder name'))}
        name="folder-name"
        id="folder-name"
        onChange={handleNameChange}
        noMargin
        fullWidth
        error={errorMessage}
      />
      <Spacer size="20px 0 0" />
      <Textarea
        dataTestId="create-folder-modal-summary-input"
        value={summary}
        required
        label={String(t('Summary'))}
        name="folder-summary"
        id="folder-summary"
        onChange={handleSummaryChange}
        noMargin
        fullWidth
        maxLength={50}
        minRows={5}
      />
      <Spacer size="30px 0 0" />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
          dataTestId="create-folder-modal-cancel-button"
          additionalSx={{
            justifyContent: 'flex-start',
            paddingLeft: 0,
            fontSize: '14px'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          dataTestId="create-folder-modal-save-button"
          variant="purple"
          fullWidth
          disabled={name.length === 0 || isLoading}
          onClick={handleOnSave}
        >
          {t('Create')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default CreateFolderModal;
