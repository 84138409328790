import { TFunction } from 'i18next';
import { JoyrideStep } from '../redux/features/joyride/joyride-slice';

export const MAP_SEARCH_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '[data-test-id="map-search-bar"]',
    content: t(
      'Need to find a specific location? Use the search bar at the top to type in a location name. Select from the dropdown list, and the map will automatically pan to your chosen area.'
    ),
    title: String(t('Search Bar')),
    mediaType: 'video',
    placement: 'right',
    mediaUrl: '/assets/search-bar.mp4'
  }
];

export const MAP_TOOLS_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '#map-tools',
    content: t('This section allows interaction with different map layers and ways to create new region'),
    title: String(t('Map Tools'))
  },
  {
    target: '#map-tools-layers',
    content: t('Toggle between different map layers, including public projects and saved regions'),
    title: String(t('Map Layers'))
  },
  {
    target: '[data-test-id="map-tools-checkbox-default-regions"]',
    content: t('These green regions on the map have CYCLOPS data available to all users'),
    title: String(t('Default Regions'))
  },
  {
    target: '[data-test-id="map-tools-checkbox-public-projects"]',
    content: t(
      'These regions correspond to real-world published carbon projects. Click on them to see more details and data.'
    ),
    title: String(t('Public Projects')),
    mediaType: 'image',
    placement: 'right',
    mediaUrl: '/assets/joyride-public-projects.png'
  },
  {
    target: '[data-test-id="map-tools-checkbox-saved-regions"]',
    content: t(
      'Any files you upload or shapes you draw on the map will be saved as regions here. You can easily access and manage all your saved regions here and by visiting your Portfolio.'
    ),
    title: String(t('Saved Regions')),
    mediaType: 'image',
    placement: 'right',
    mediaUrl: '/assets/joyride-saved-regions.png'
  },
  {
    target: '#map-tools-buttons',
    content: t('Use these buttons to create a new project and save it to your profile.'),
    title: String(t('Create and Upload'))
  },
  {
    target: '[data-test-id="map-tools-draw-button"]',
    content: t(
      `You can also draw a polygon within the covered areas to get data without uploading a file. Click 'Draw region' to start outlining your area of interest.`
    ),
    title: String(t('Drawing Custom Regions')),
    mediaType: 'video',
    placement: 'right',
    mediaUrl: '/assets/draw-shape.mp4'
  },
  {
    target: '[data-test-id="map-tools-upload-button"]',
    content: `To view data, upload a geospatial file for one of the supported regions. Click 'Upload GIS file' to get started. Files outside the supported regions will prompt a notification, allowing you to request data coverage. <br /><br /> Total size of uploaded geometry must be under 4,500 km² and we support geojson, .shp, .kml, .json files.`,
    title: String(t('File uploading')),
    placement: 'right',
    mediaType: 'video',
    mediaUrl: '/assets/file-upload.mp4'
  }
];

export const NAVIGATION_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '[data-test-id="header-item-portfolio"]',
    content: t(
      `Here’s where you can manage all your saved regions and projects. Click 'My Portfolio' to view and organize your data.`
    ),
    title: String(t('My Portfolio'))
  },
  {
    target: '[data-test-id="header-item-explore"]',
    content: t(
      'Main app page, which shows the map and the main functionalities, like creating regions and exploring projects.'
    ),
    title: String(t('Main app page'))
  },
  {
    target: '[data-test-id="header-item-notifications-button"]',
    title: String(t('App Push Notifications')),
    content: t(
      `Stay updated with notifications. We'll alert you about the status of your data queries and any updates to requested regions.`
    )
  },
  {
    target: '#menu-user',
    title: String(t('User Menu')),
    content: t('Manage account settings, view a list of your saved regions, and more')
  },
  {
    target: '[data-test-id="header-item-joyride-replay"]',
    title: String(t('Replay Joyride')),
    content: t(
      'Click the play button in the navigation bar anytime to replay this walkthrough and get a refresher on how to use the platform.'
    ),
    forceLastStep: true
  }
];

export const PORTFOLIO_SIDEBAR_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '#portfolio-sidebar-unassigned',
    content: t('This tab shows the regions that are not assigned to any folder.'),
    title: String(t('Unassigned Regions'))
  },
  {
    target: '#portfolio-sidebar-summary',
    content: t('This view provides a summary of all of your user provided regions'),
    title: String(t('Summary'))
  },
  {
    target: '#portfolio-sidebar-add-new',
    content: t('Projects can be created and saved to your account from here as well as on the Explore page'),
    title: String(t('Add New'))
  }
];

export const PORTFOLIO_SIDEBAR_FOLDERS_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '#portfolio-sidebar-folders',
    content: t('Use folders to organize projects and provide summaries for different sections of your portfolio'),
    title: String(t('Folders'))
  }
];

export const PORTFOLIO_SUMMARY_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '#summary-stats',
    content: t('This section shows a summary of all your regions and projects that are assigned to folders'),
    title: String(t('Summary Stats'))
  },
  {
    target: '#summary-stats-count',
    content: t('Total number of regions and projects in your portfolio'),
    title: String(t('Total Regions'))
  },
  {
    target: '#summary-stats-area',
    content: t('Total area of all regions and projects in your portfolio'),
    title: String(t('Total Area'))
  },
  {
    target: '#summary-stats-emission',
    content: t('Total projected emission reductions of all regions and projects in your portfolio'),
    title: String(t('Total Projected Emission Reductions'))
  }
];

export const UPLOAD_MODAL_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '#upload-modal-upload-step',
    content: t('This modal allows you to upload a shapefile and create a new region from it.'),
    title: 'Upload Step',
    placement: 'top-start'
  },
  {
    target: '#upload-modal-upload-step',
    content: t('This is the main upload section. You can see the regions you are about to upload here.')
  },
  {
    target: '#upload-modal-upload-step',
    content: t('If you have multiple regions in your shapefile, they will be combined into a single region.')
  },
  {
    target: '#upload-modal-upload-step',
    content: t('If you want to remove a region from the upload, click on the trash icon next to the filename.')
  },
  {
    target: '#upload-modal-upload-step',
    content: t('Once you are ready to upload, click on the next button to start the process.')
  }
];

export const UPLOAD_MODAL_EDIT_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '#upload-modal-edit-step',
    content: t(
      'This section allows you to edit the region you are about to upload. You can add details and overview information here.'
    ),
    title: String(t('Upload Modal Metadata'))
  },
  {
    target: '#upload-modal-edit-step',
    content: t('Click on the tabs to switch between the overview and details sections.')
  },
  {
    target: '#upload-modal-edit-step',
    content: t('Once you are ready to save the region, click on the save button.')
  }
];

export const NO_DATA_COVERAGE_MODAL_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '[data-test-id="edit-region-no-data-coverage-modal-title"]',
    content: t(
      `If your file isn't within a supported area, you’ll receive a notification. You can request data for this area by filling out a short form.`
    ),
    title: String(t('Handling Unsupported Areas')),
    placement: 'top'
  }
];

export const DATA_BOARD_STEPS = (t: TFunction<'translation', undefined>): JoyrideStep[] => [
  {
    target: '#data-board',
    content: t('Select a region on the map or a saved project in your account to view CYCLOPS data.'),
    title: String(t('Data Board'))
  },
  {
    target: '#data-board',
    content: t("Here you'll find tabs for carbon sequestration data as well as deforestation information.")
  }
];
