import { AreaUnitType, AreaUnitTypeFullName, AreaWeightEnum } from '../types/Geo';
import { IUser } from '../types/User';

export const TONNE_TO_KG_RATIO = 1000;

// Convert Weight unit (tonne) to a more adequate unit type when divided by a certain Area Unit
export const returnTonneOrKilograms = (weightInTonne: number, user?: IUser | null): number => {
  if (user && user.settings) {
    const { unit } = user.settings;
    switch (unit) {
      case AreaUnitType.squareKilometre:
      case AreaUnitType.squareMile:
      case AreaUnitType.hectare:
      case AreaUnitType.acre:
      default: {
        return weightInTonne; // no conversion required
      }
    }
  }
  return weightInTonne;
};

// Get the name of a more adequate Weight unit type when divided by a certain Area Unit
export const returnWeightByUnit = (areaUnit?: AreaUnitType | null): AreaWeightEnum => {
  if (areaUnit) {
    switch (areaUnit) {
      case AreaUnitType.squareKilometre:
      case AreaUnitType.squareMile:
      case AreaUnitType.hectare:
      case AreaUnitType.acre:
      default: {
        return AreaWeightEnum.ton;
      }
    }
  }
  return AreaWeightEnum.ton;
};

export const convertSqKmTo = (value: number, unit: AreaUnitType = AreaUnitType.squareKilometre): number => {
  switch (unit) {
    case AreaUnitType.hectare: {
      return value * 100;
    }
    case AreaUnitType.acre: {
      return value * 247.105;
    }
    case AreaUnitType.squareMile: {
      return value / 2.59;
    }
    case AreaUnitType.squareKilometre:
    default: {
      return value;
    }
  }
};

export const convertUnitToFullName = (value?: AreaUnitType): AreaUnitTypeFullName => {
  if (!value) {
    return AreaUnitTypeFullName.hectare;
  }

  return AreaUnitTypeFullName[value];
};
