export interface ISidebarContext {
  generateSidebarContent: () => {
    header: JSX.Element | null;
    content: JSX.Element | null;
    footer: JSX.Element | null;
  } | null;
  onClose: () => void;
}

export const defaultState: ISidebarContext = {
  generateSidebarContent: () => ({
    header: null,
    content: null,
    footer: null
  }),
  onClose: () => {}
};
