import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import SwitchComponent from '../Switch';
import Textarea from '../Textarea';
import { Text, Wrapper } from './style';

interface Props {
  text: string;
  checked: boolean;
  onToggle: () => void;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
}

const InformationBanner = ({ text, checked, onToggle, message, setMessage }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper data-test-id="information-banner-wrapper">
        <Text data-test-id="information-banner-text">{text}</Text>
        <SwitchComponent data-test-id="information-banner-switch" checked={checked} onToggle={onToggle} />
      </Wrapper>
      {checked ? (
        <Textarea
          value={message}
          required
          label={String(t(`Tell us more about how you'll use this data`))}
          onChange={(val) => setMessage(val)}
          variant="light-blue"
          noMargin
          fullWidth
        />
      ) : null}
    </>
  );
};

export default InformationBanner;
