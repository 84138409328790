import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { COMMON_FEATURE_BODY_TYPE } from '../../../constants/data-board';
import { usePolygonContext } from '../../../context/Polygon';
import {
  CarbonDataType,
  CarbonDisplayPerType,
  ForestDataType,
  ForestTemporalType
} from '../../../context/Polygon/types';
import {
  setCarbonDataType,
  setCarbonDisplayPer,
  setForestDataType,
  setForestTemporalType
} from '../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { returnWeightByUnit } from '../../../utils/units';
import Select, { Props } from '../../Common/Select';
import { renderCustomTooltip } from './ControlsTooltip';

const Controls = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { getPolygonCarbonAccountingData, getPolygonForestCoverData } = usePolygonContext();

  const { carbonDataType, carbonDisplayPer, forestDataType, forestTemporalType, selectedPolygon } = useAppSelector(
    (state) => state.regionState
  );
  const { dataBoardTab } = useAppSelector((state) => state.uiState);
  const { user } = useAppSelector((state) => state.userState);

  type CustomSelectProps = {
    value: Props['value'];
    onChange: Props['onChange'];
    dataTestId: Props['dataTestId'];
    label: string;
    options: Props['options'];
    optionWidth?: string;
    additionalSx?: any;
  };

  const IS_CARBON_TAB_SELECTED = useMemo(() => dataBoardTab === '0', [dataBoardTab]);

  /**
   * Sorting controls setup and call of sorting function data fetch
   */
  const handleCarbonDataDisplayChange = useCallback(
    async (val: CarbonDataType) => {
      dispatch(setCarbonDataType(val));
      if (selectedPolygon) {
        await getPolygonCarbonAccountingData(COMMON_FEATURE_BODY_TYPE(selectedPolygon), val, carbonDisplayPer);
      }
    },
    [dispatch, selectedPolygon, getPolygonCarbonAccountingData, carbonDisplayPer]
  );

  const handleCarbonDisplayPerChange = useCallback(
    async (val: CarbonDisplayPerType) => {
      dispatch(setCarbonDisplayPer(val));
      if (selectedPolygon) {
        await getPolygonCarbonAccountingData(COMMON_FEATURE_BODY_TYPE(selectedPolygon), carbonDataType, val);
      }
    },
    [dispatch, selectedPolygon, getPolygonCarbonAccountingData, carbonDataType]
  );

  const CustomSelect = useCallback(
    ({ value, onChange, dataTestId, label, options, optionWidth }: CustomSelectProps) => (
      <Select
        value={value}
        onChange={onChange}
        dataTestId={dataTestId}
        label={String(t(label))}
        optionWidth={optionWidth}
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
        options={options}
      />
    ),
    [t]
  );

  const renderCarbonControls = useCallback(() => {
    const unit =
      carbonDisplayPer === 'data'
        ? t('mton')
        : `${returnWeightByUnit(user?.settings.unit)} / ${user?.settings.unit as string}`;

    return (
      <>
        <CustomSelect
          value={carbonDisplayPer}
          onChange={handleCarbonDisplayPerChange}
          dataTestId={'data-board-carbon-data-display'}
          label={'Display'}
          options={[
            { value: 'data', label: t('Display per unit') },
            {
              value: 'data/area',
              label: t('Display per unit area stats')
            }
          ]}
        />
        <CustomSelect
          value={carbonDataType}
          onChange={handleCarbonDataDisplayChange}
          dataTestId="carbon-controls-select"
          label={'Type'}
          options={[
            {
              label: t('Sequestered Carbon ({{unit}})', { unit }),
              value: 'sequestered-carbon',
              extraElement: renderCustomTooltip({
                title: t('Sequestered Carbon ({{unit}})', { unit }),
                content: (
                  <p>
                    {t(`Carbon stored multiplied by 3.66 to estimate the overall amount of CO2 that was absorbed to generate
                    the carbon stored.`)}
                  </p>
                )
              })
            },
            {
              label: t('Above Ground Biomass ({{unit}})', { unit }),
              value: 'above-ground-biomass',
              extraElement: renderCustomTooltip({
                title: t('Above Ground Biomass ({{unit}})', { unit }),
                content: (
                  <p>
                    {t(`All living biomass above the soil including stem, stump, branches, bark, seeds and foliage. Oven-dry
                    weight of a tree, stand or forest plus oven-dry weight of branches, twigs, foliage, top, but not
                    stump.`)}
                  </p>
                )
              })
            },
            {
              label: t('Change in Biomass ({{unit}})', { unit }),
              value: 'change-in-biomass',
              extraElement: renderCustomTooltip({
                title: t('Change in Biomass ({{unit}})', { unit }),
                content: (
                  <p>
                    {t(`Difference in Living Biomass between two timestamps. This is estimated by removing biomass of a
                    region that has experienced tree cover loss from the total living biomass summation.`)}
                  </p>
                )
              })
            },
            {
              label: t('Below Ground Biomass ({{unit}})', { unit }),
              value: 'below-ground-biomass',
              extraElement: renderCustomTooltip({
                title: t('Below Ground Biomass ({{unit}})', { unit }),
                content: (
                  <p>
                    {t(`Oven dry weight of stump and live roots. Fine roots of less than (suggested) 2mm diameter are
                    sometimes excluded because these often cannot be distinguished empirically from soil organic matter
                    or litter.`)}
                  </p>
                )
              })
            },
            {
              label: t('Total Living Biomass ({{unit}})', { unit }),
              value: 'total-living-biomass',
              extraElement: renderCustomTooltip({
                title: t('Total Living Biomass ({{unit}})', { unit }),
                content: (
                  <p>
                    {t(`Organic material both above-ground and below-ground, and both living and dead, e.g., trees, crops,
                    grasses, tree litter, roots etc. Biomass includes above – and below – ground biomass. Expressed as a
                    summation of Above Ground Biomass and Below Ground Biomass.`)}
                  </p>
                )
              })
            },
            {
              label: t('Carbon Stored ({{unit}})', { unit }),
              value: 'carbon-stored',
              extraElement: renderCustomTooltip({
                title: t('Carbon Stored ({{unit}})', { unit }),
                content: (
                  <p>
                    {t(`Carbon Stored in the Above Ground Biomass. The quantity of carbon in a “pool”, meaning a reservoir
                    or system which has the capacity to accumulate or release carbon. For FRA 2005 purposes, examples of
                    carbon pools are Living biomass (including Above and below-ground biomass); Dead organic matter
                    (including dead wood and litter); Soils (soils organic matter). The units are mass.`)}
                  </p>
                )
              })
            }
          ]}
        />
      </>
    );
  }, [
    carbonDisplayPer,
    t,
    user?.settings.unit,
    CustomSelect,
    handleCarbonDisplayPerChange,
    carbonDataType,
    handleCarbonDataDisplayChange
  ]);

  const handleForestDataTypeChange = useCallback(
    async (val: ForestDataType) => {
      dispatch(setForestDataType(val));
      if (selectedPolygon) {
        await getPolygonForestCoverData(COMMON_FEATURE_BODY_TYPE(selectedPolygon), forestTemporalType, val);
      }
    },
    [dispatch, forestTemporalType, getPolygonForestCoverData, selectedPolygon]
  );

  const handleForestTemporalTypeChange = useCallback(
    async (val: ForestTemporalType) => {
      dispatch(setForestTemporalType(val));
      if (selectedPolygon) {
        await getPolygonForestCoverData(COMMON_FEATURE_BODY_TYPE(selectedPolygon), val, forestDataType);
      }
    },
    [dispatch, forestDataType, getPolygonForestCoverData, selectedPolygon]
  );
  const renderForestControls = useCallback(() => {
    const unit = user?.settings.unit;

    return (
      <>
        <CustomSelect
          value={forestTemporalType}
          onChange={handleForestTemporalTypeChange}
          dataTestId="forestCover-controls-select-type"
          label={'Display'}
          options={[
            {
              label: t('Forest cover ({{unit}})', { unit }),
              value: ForestTemporalType.LINEAR,
              extraElement: renderCustomTooltip({
                title: t('Forest cover ({{unit}})', { unit }),
                content: (
                  <p>
                    {t(`Carbon Stored in the Above Ground Biomass. The quantity of carbon in a “pool”, meaning a reservoir
                    or system which has the capacity to accumulate or release carbon. For FRA 2005 purposes, examples of
                    carbon pools are Living biomass (including Above and below-ground biomass); Dead organic matter
                    (including dead wood and litter); Soils (soils organic matter). The units are mass.`)}
                  </p>
                )
              })
            },
            {
              label: t('YoY Tree Cover Loss ({{unit}})', { unit }),
              value: ForestTemporalType.YoY,
              extraElement: renderCustomTooltip({
                title: t('YoY Tree Cover Loss ({{unit}})', { unit }),
                content: (
                  <>
                    <p>
                      {t(`While deforestation is the direct human-induced conversion of forested land to non-forested land,
                      tree cover loss is a broader term that includes:`)}
                    </p>
                    <ul>
                      <li>
                        <strong>{t('Human-caused loss: ')}</strong>
                        {t(`The complete removal of tree cover, including the conversion
                        of forest to other land uses, such as agriculture, pasture, water reservoirs, and urban areas`)}
                      </li>
                      <li>
                        <strong>{t(`Natural events: `)}</strong>
                        {t(`Natural losses, such as wildfires`)}
                      </li>
                      <li>
                        <strong>{t(`Changes in plantation forests: `)}</strong>
                        {t(`Changes in plantation forests, where land use as
                        a forest remains the same`)}
                      </li>
                    </ul>
                    <p>
                      {t(`Tree cover loss can be permanent or temporary. For example, temporary tree cover loss can occur
                      when trees are harvested for wood production and then replanted or allowed to regenerate
                      naturally. This also includes slash-and-burn, or shifting agriculture type activities.`)}
                    </p>
                  </>
                )
              })
            }
          ]}
        />
        <CustomSelect
          value={forestDataType}
          onChange={handleForestDataTypeChange}
          dataTestId="forestCover-controls-select"
          label={'Type'}
          options={[
            {
              value: ForestDataType.PERCENTAGE,
              label: t('Percent')
            },
            {
              value: ForestDataType.AREA,
              label: t('Area')
            }
          ]}
        />
      </>
    );
  }, [
    user?.settings.unit,
    CustomSelect,
    forestTemporalType,
    handleForestTemporalTypeChange,
    t,
    forestDataType,
    handleForestDataTypeChange
  ]);

  const renderControls = useCallback(() => {
    switch (IS_CARBON_TAB_SELECTED) {
      case true: {
        return renderCarbonControls();
      }
      case false: {
        return renderForestControls();
      }
      default: {
        return null;
      }
    }
  }, [IS_CARBON_TAB_SELECTED, renderCarbonControls, renderForestControls]);

  return renderControls() || null;
};

export default Controls;
