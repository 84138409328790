import { createContext, useCallback, useContext, useEffect } from 'react';
import DataBoard from '../../components/DataBoard';
import DataBoardFooter from '../../components/DataBoard/Footer';
import DataBoardHeader from '../../components/DataBoard/Header';
import EditRegion from '../../components/EditRegion';
import EditRegionFooter from '../../components/EditRegion/Footer';
import EditRegionHeader from '../../components/EditRegion/Header';
import Notifications from '../../components/Notifications';
import NotificationsFooter from '../../components/Notifications/Footer';
import NotificationsHeader from '../../components/Notifications/Header';
import SavedRegions from '../../components/SavedRegions';
import SavedRegionsHeader from '../../components/SavedRegions/Header';
import { resetDrawSliceState } from '../../redux/features/draw/draw-slice';
import { resetUIState, setShowSidebar } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useMapContext } from '../Map';
import { usePolygonContext } from '../Polygon';
import { Props } from '../types';
import { defaultState, ISidebarContext } from './types';

const SidebarContext = createContext<ISidebarContext>(defaultState);

export const SidebarProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { showSavedRegions, showNotifications } = useAppSelector((state) => state.uiState);
  const { messages } = useAppSelector((state) => state.socketState);
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { drawnFeatures } = useAppSelector((state) => state.drawState);
  const { resetPolygonData } = usePolygonContext();
  const { removeMapSelection } = useMapContext();

  useEffect(() => {
    if (selectedPolygon || drawnFeatures.length > 0 || showSavedRegions || showNotifications) {
      dispatch(setShowSidebar(true));
    }
  }, [dispatch, drawnFeatures, selectedPolygon, showNotifications, showSavedRegions]);

  const onClose = useCallback(() => {
    removeMapSelection();
    dispatch(resetDrawSliceState());
    resetPolygonData();

    dispatch(resetUIState());
  }, [removeMapSelection, resetPolygonData, dispatch]);

  const generateSelectedPolygonContent = useCallback(() => {
    return {
      content: <DataBoard />,
      header: <DataBoardHeader />,
      footer: <DataBoardFooter />
    };
  }, []);

  const generateEditRegionContent = useCallback(() => {
    return {
      content: <EditRegion />,
      header: <EditRegionHeader />,
      footer: <EditRegionFooter />
    };
  }, []);

  const generateUserTilesContent = useCallback(() => {
    return {
      content: <SavedRegions />,
      header: <SavedRegionsHeader />,
      footer: null
    };
  }, []);

  const generateNotificationsContent = useCallback(() => {
    return {
      content: <Notifications />,
      header: <NotificationsHeader />,
      footer: messages.length > 0 ? <NotificationsFooter /> : null
    };
  }, [messages.length]);

  const generateSidebarContent = useCallback(() => {
    if (selectedPolygon && drawnFeatures.length === 0) {
      return generateSelectedPolygonContent();
    }

    if (drawnFeatures.length > 0 && !selectedPolygon) {
      return generateEditRegionContent();
    }

    if (showSavedRegions && !selectedPolygon && drawnFeatures.length === 0) {
      return generateUserTilesContent();
    }

    if (showNotifications && !selectedPolygon && drawnFeatures.length === 0) {
      return generateNotificationsContent();
    }

    return {
      header: null,
      footer: null,
      content: null
    };
  }, [
    drawnFeatures?.length,
    generateEditRegionContent,
    generateNotificationsContent,
    generateSelectedPolygonContent,
    generateUserTilesContent,
    selectedPolygon,
    showNotifications,
    showSavedRegions
  ]);

  return (
    <SidebarContext.Provider
      value={{
        generateSidebarContent,
        onClose
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
