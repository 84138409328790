import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  dataBoardTab: string;
  showDeforestationComponent: boolean;
  showSidebar: boolean;
  showNotifications: boolean;
  showSavedRegions: boolean;
}

const initialState: UIState = {
  dataBoardTab: '0',
  showDeforestationComponent: false,
  showSidebar: false,
  showNotifications: false,
  showSavedRegions: false
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setDataBoardTab: (state, action: PayloadAction<string>) => {
      state.dataBoardTab = action.payload;
    },
    setShowDeforestationComponent: (state, action: PayloadAction<boolean>) => {
      state.showDeforestationComponent = action.payload;
    },
    toggleDeforestationComponent: (state) => {
      state.showDeforestationComponent = !state.showDeforestationComponent;
      document.body.classList.toggle('deforestation');
    },
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.showSidebar = action.payload;
    },
    setShowNotifications: (state, action: PayloadAction<boolean>) => {
      state.showNotifications = action.payload;
    },
    setShowSavedRegions: (state, action: PayloadAction<boolean>) => {
      state.showSavedRegions = action.payload;
    },
    resetUIState: () => initialState
  }
});

export default uiSlice.reducer;
export const {
  setDataBoardTab,
  setShowDeforestationComponent,
  setShowSidebar,
  toggleDeforestationComponent,
  resetUIState,
  setShowNotifications,
  setShowSavedRegions
} = uiSlice.actions;
