import { createContext, useContext } from 'react';
import AccountModal from '../../components/Modals/AccountModal';
import CreatePublicProjectModal from '../../components/Modals/AdminModals/CreatePublicProjectModal';
import DeletePublicProjectModal from '../../components/Modals/AdminModals/DeletePublicProjectModal';
import DeletePublicProjectPDFModal from '../../components/Modals/AdminModals/DeletePublicProjectPDFModal';
import EditPublicProjectPDFModal from '../../components/Modals/AdminModals/EditPublicProjectPDFModal';
import EditUserModal from '../../components/Modals/AdminModals/EditUserModal';
import FindUserModal from '../../components/Modals/AdminModals/FindUserModal';
import InvalidateCacheModal from '../../components/Modals/AdminModals/InvalidateCacheModal';
import UpdatePublicProjectModal from '../../components/Modals/AdminModals/UpdatePublicProjectModal';
import ApiKeyModal from '../../components/Modals/ApiKeyModal';
import ChangeRegionsFolderModal from '../../components/Modals/ChangeRegionsFolderModal';
import ContactUsModal from '../../components/Modals/ContactUsModal';
import DeleteDocumentModal from '../../components/Modals/DeleteDocumentModal';
import DeleteRegionModal from '../../components/Modals/DeleteRegionModal';
import DeleteRegionsModal from '../../components/Modals/DeleteRegionsModal';
import EditRegionInvalidShapeModal from '../../components/Modals/EditRegionModals/InvalidShape';
import EditRegionMissingDataModal from '../../components/Modals/EditRegionModals/MissingData';
import EditRegionNoCoverageModal from '../../components/Modals/EditRegionModals/NoDataCoverage';
import EditRegionTooLargeShapeModal from '../../components/Modals/EditRegionModals/TooLargeShape';
import CreateFolderModal from '../../components/Modals/FolderModals/CreateFolderModal';
import DeleteFolderModal from '../../components/Modals/FolderModals/DeleteFolderModal';
import EditFolderModal from '../../components/Modals/FolderModals/EditFolderModal';
import JoyrideWelcomeModal from '../../components/Modals/JoyrideWelcomeModal';
import RemoveAccountModal from '../../components/Modals/RemoveAccountModal';
import RenameDocumentModal from '../../components/Modals/RenameDocumentModal';
import ReportModal from '../../components/Modals/ReportModal';
import SaveRegionModal from '../../components/Modals/SaveRegionModal';
import UserSettingsModal from '../../components/Modals/SettingsModal';
import UnsubscribeRegionModal from '../../components/Modals/UnsubscribeRegionModal';
import UpdateRegionModal from '../../components/Modals/UpdateRegionModal';
import UpgradeModal from '../../components/Modals/UpgradeModal';
import UploadDocumentModal from '../../components/Modals/UploadDocumentModal';
import UploadModal from '../../components/Modals/UploadModal';
import { ModalType } from '../../redux/features/modal/modal-slice';
import { useAppSelector } from '../../redux/hooks';
import { Props } from '../types';

const ModalContext = createContext({});

const modalsByType: {
  [key in ModalType]: JSX.Element;
} = {
  account: <AccountModal />,
  adminCacheInvalidation: <InvalidateCacheModal />,
  adminEditUser: <EditUserModal />,
  adminFindUser: <FindUserModal />,
  apiKey: <ApiKeyModal />,
  contactUs: <ContactUsModal />,
  createPublicProject: <CreatePublicProjectModal />,
  deleteAccount: <RemoveAccountModal />,
  deleteRegion: <DeleteRegionModal />,
  deleteRegions: <DeleteRegionsModal />,
  deletePublicProject: <DeletePublicProjectModal />,
  deletePublicProjectPDF: <DeletePublicProjectPDFModal />,
  editRegionInvalidShape: <EditRegionInvalidShapeModal />,
  editRegionMissingData: <EditRegionMissingDataModal />,
  editRegionNoCoverage: <EditRegionNoCoverageModal />,
  editRegionTooLargeShape: <EditRegionTooLargeShapeModal />,
  pdfReport: <ReportModal />,
  saveRegion: <SaveRegionModal />,
  unsubscribeRegion: <UnsubscribeRegionModal />,
  upgradeAccount: <UpgradeModal />,
  uploadShapefile: <UploadModal />,
  editRegion: <UpdateRegionModal />,
  changeRegionsFolder: <ChangeRegionsFolderModal />,
  editPublicProject: <UpdatePublicProjectModal />,
  editPublicProjectPDF: <EditPublicProjectPDFModal />,
  createFolder: <CreateFolderModal />,
  editFolder: <EditFolderModal />,
  deleteFolder: <DeleteFolderModal />,
  renameDocument: <RenameDocumentModal />,
  deleteDocument: <DeleteDocumentModal />,
  uploadDocument: <UploadDocumentModal />,
  joyrideWelcome: <JoyrideWelcomeModal />,
  userSettings: <UserSettingsModal />
};

export const ModalProvider = ({ children }: Props) => {
  const { activeModal } = useAppSelector((state) => state.modalState);
  const modal = activeModal ? modalsByType[activeModal] : null;

  return (
    <ModalContext.Provider value={{}}>
      <>
        {children}
        {modal}
      </>
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
