import styled from 'styled-components';

export const UploadItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.colors.blackIII};
`;

const UploadItemButton = styled.button`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  border: 0;
  padding: 0;
  border-radius: 8px;
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;
`;

export const UploadItemDeleteButton = styled(UploadItemButton)`
  background-color: transparent;
  border: 0;
`;

export const UploadItemSection = styled.div<{ column?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;

export const UploadItemFileName = styled.p`
  font-size: 16px;
  line-height: 22px;
  transition: ${({ theme }) => theme.transition};
  color: ${({ theme }) => theme.colors.white};
`;

export const IncorrectIndicator = styled.div<{ visible?: boolean }>`
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.red};
  border-radius: 4px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: -10px;
  right: 20px;
  transition: ${({ theme }) => theme.transition};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const ItemIconWrapper = styled.div<{ isIncorrect?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isIncorrect }) => (isIncorrect ? 'rgba(239, 51, 82, 0.2)' : 'rgba(136, 253, 211, 0.1)')};
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;
