import union from '@turf/union';
import { useCallback } from 'react';
import { useRegionContext } from '../../../context/Region';
import { useGetSplitPolygonDataMutation } from '../../../redux/api/biomassApi';
import { useAppSelector } from '../../../redux/hooks';
import { IRegionProperties, IRegionRequest } from '../../../types/API/Region';
import { ISplitPolygonState } from '../../../types/Geo';
import { IItem } from './index';

interface IUseUploadModal {
  regionsToSave: IItem[];
  setPolygonMessage: (message: string) => void;
  setReportMissingData: (value: boolean) => void;
  setHasMissingData: (value: boolean) => void;
  setPolygonsWithoutDataParts: (value: IItem[]) => void;
  setPolygonsWithDataParts: (value: IItem[]) => void;
  preparedSubmitData: IRegionRequest | null;
  setPreparedSubmitData: (value: IRegionRequest | null) => void;
  reportMissingData: boolean;
  polygonMessage: string;
}

export const useUploadModal = ({
  regionsToSave,
  setPolygonMessage,
  setReportMissingData,
  setHasMissingData,
  setPolygonsWithoutDataParts,
  setPolygonsWithDataParts,
  preparedSubmitData,
  setPreparedSubmitData,
  reportMissingData,
  polygonMessage
}: IUseUploadModal) => {
  const { reportRegion } = useRegionContext();
  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();
  const { user } = useAppSelector((state) => state.userState);

  /**
   * Processes a single region, verifies the split polygon data, and updates the relevant arrays and flags.
   * @param {IItem} region - The region to process.
   * @param {IItem[]} regionsWithData - The array of regions with data.
   * @param {IItem[]} regionsWithoutDataParts - The array of regions without data parts.
   * @param {IItem[]} entirelyWithoutDataParts - The array of regions entirely without data.
   * @param {ISplitPolygonState[]} splitPolygonDataParts - The array of split polygon data parts.
   * @returns {Promise<boolean>} - Returns a promise that resolves to a boolean indicating if missing data appeared.
   */
  const processRegion = useCallback(
    async (
      region: IItem,
      regionsWithData: IItem[],
      regionsWithoutDataParts: IItem[],
      entirelyWithoutDataParts: IItem[],
      splitPolygonDataParts: ISplitPolygonState[]
    ) => {
      const splitData = await verifySplitPolygon({
        feature: region.data,
        omitCache: true,
        withStateSet: false
      }).unwrap();

      let missingDataAppeared = false;

      if (splitData.drawnDataOnTile.features.length > 0) {
        regionsWithData.push(region);
        splitPolygonDataParts.push(splitData);
      }

      if (splitData.drawnDataOutsideTile.features.length > 0) {
        regionsWithoutDataParts.push(region);
        missingDataAppeared = true;
      }

      if (splitData.drawnDataOnTile.features.length === 0 && missingDataAppeared) {
        entirelyWithoutDataParts.push(region);
      }

      return missingDataAppeared;
    },
    [verifySplitPolygon]
  );

  /**
   * Handles the case when there is missing data.
   * If there was a change in the missing data, the message and flags are restarted.
   * @param {boolean} missingDataAppeared - A flag indicating if missing data appeared.
   * @param {(message: string) => void} setPolygonMessage - The function to set the polygon message.
   * @param {(value: boolean) => void} setReportMissingData - The function to set the report missing data flag.
   * @param {(value: boolean) => void} setHasMissingData - The function to set the has missing data flag.
   */
  const handleMissingData = useCallback(
    (missingDataAppeared: boolean) => {
      // restart the message if the missing data disappeared
      if (!missingDataAppeared) {
        setPolygonMessage('');
        setReportMissingData(false);
      }

      setHasMissingData(missingDataAppeared);
    },
    [setHasMissingData, setPolygonMessage, setReportMissingData]
  );

  /**
   * Handles the case when there are regions with data.
   * It sets states accordingly and updates the polygon arrays.
   * If there are regions with data and regions without data parts,
   * all regions are considered to have data as they are merged in the end
   * @param {IItem[]} regionsWithData - The array of regions with data.
   * @param {IItem[]} regionsWithoutDataParts - The array of regions without data parts.
   * @param {IItem[]} entirelyWithoutDataParts - The array of regions entirely without data.
   * @param {(value: IItem[]) => void} setPolygonsWithoutDataParts - The function to set the polygons without data parts.
   * @param {(value: IItem[]) => void} setPolygonsWithDataParts - The function to set the polygons with data parts.
   */
  const handleRegionsWithData = useCallback(
    (regionsWithData: IItem[], regionsWithoutDataParts: IItem[], entirelyWithoutDataParts: IItem[]) => {
      if (regionsWithData.length > 0 && (regionsWithoutDataParts.length > 0 || entirelyWithoutDataParts.length > 0)) {
        regionsWithData = regionsToSave;
      }

      if (entirelyWithoutDataParts.length > 0) {
        setPolygonsWithoutDataParts(entirelyWithoutDataParts);
      }

      if (regionsWithData.length > 0) {
        setPolygonsWithDataParts(regionsWithData);
      }
    },
    [regionsToSave, setPolygonsWithDataParts, setPolygonsWithoutDataParts]
  );

  const checkRegionsData = useCallback(async () => {
    const regionsWithData: IItem[] = [];
    const regionsWithoutDataParts: IItem[] = [];
    const splitPolygonDataParts: ISplitPolygonState[] = [];
    const entirelyWithoutDataParts: IItem[] = [];

    await Promise.all(
      regionsToSave.map(async (region) => {
        const missingDataAppeared = await processRegion(
          region,
          regionsWithData,
          regionsWithoutDataParts,
          entirelyWithoutDataParts,
          splitPolygonDataParts
        );
        handleMissingData(missingDataAppeared);
      })
    );

    handleRegionsWithData(regionsWithData, regionsWithoutDataParts, entirelyWithoutDataParts);

    return {
      regionsWithData,
      regionsWithoutDataParts,
      splitPolygonDataParts
    };
  }, [handleMissingData, handleRegionsWithData, processRegion, regionsToSave]);

  /**
   * Get the latest date from the split polygon data parts.
   * @param {ISplitPolygonState[]} splitPolygonDataParts - The array of split polygon data parts.
   * @returns {Date | undefined} - The latest date or undefined if there are no dates.
   */
  const getLatestDate = (splitPolygonDataParts: ISplitPolygonState[]): Date | undefined => {
    let dates: Date[] = [];
    splitPolygonDataParts.map(
      (splitData) =>
        (dates = splitData.drawnDataOnTile.features
          .filter((splitDataEl) => splitDataEl.properties?.last_modified)
          .map((splitDataEl) => new Date(String(splitDataEl.properties?.last_modified))))
    );

    if (dates.length > 0) {
      return dates.reduce((a, b) => (a > b ? a : b));
    }

    return undefined;
  };

  /**
   * Prepare the data for a single region.
   * @param {IItem} region - The region to prepare data for.
   * @param {Date | undefined} dataUpdatedAt - The latest date from the split polygon data parts.
   * @returns {IRegionRequest} - The prepared data for the region.
   */
  const prepareSingleRegionData = (region: IItem, dataUpdatedAt: Date | undefined): IRegionRequest => {
    return {
      name: region.name,
      polygon: {
        ...region.data,
        properties: {
          ...(region.data.properties as IRegionRequest['polygon']['properties'])
        }
      },
      dataUpdatedAt
    };
  };

  /**
   * Union the polygons if there is more than one.
   * @param {IRegionRequest[]} preparedData - The prepared data for the regions.
   * @param {Date | undefined} dataUpdatedAt - The latest date from the split polygon data parts.
   * @returns {IRegionRequest} - The union of the polygons.
   */
  const unionPolygons = (preparedData: IRegionRequest[], dataUpdatedAt: Date | undefined): IRegionRequest => {
    const reducedData = preparedData.reduce((acc: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>, el) => {
      return union(acc, el.polygon, {
        properties: { dataUpdatedAt }
      }) as GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon, IRegionProperties>;
    }, preparedData[0].polygon);

    return {
      name: '',
      polygon: {
        ...reducedData,
        properties: {
          ...reducedData.properties
        }
      },
      summary: '',
      documents: [],
      folderId: ''
    } as IRegionRequest;
  };

  /**
   * Prepare data to be sent to the backend, with the dataUpdatedAt field, to be used in the backend.
   * @param {IItem[]} regionsWithData - The array of regions with data.
   * @param {ISplitPolygonState[]} splitPolygonDataParts - The array of split polygon data parts.
   * @returns {IRegionRequest[] | IRegionRequest} - The prepared data to be sent to the backend.
   */
  const prepareSubmitData = (
    regionsWithData: IItem[],
    splitPolygonDataParts: ISplitPolygonState[]
  ): IRegionRequest[] | IRegionRequest => {
    const dataUpdatedAt = getLatestDate(splitPolygonDataParts);

    const preparedData: IRegionRequest[] = regionsWithData.map((region) =>
      prepareSingleRegionData(region, dataUpdatedAt)
    );

    if (preparedData.length === 1) {
      return preparedData[0];
    }

    return unionPolygons(preparedData, dataUpdatedAt);
  };

  const handleReportMissingPolygonParts = async (regionsWithoutDataParts: IItem[]) => {
    if (reportMissingData && user?.email) {
      if (polygonMessage.length === 0) {
        return false;
      }

      const polygonCollection: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: regionsWithoutDataParts.map((region) => region.data)
      };

      await reportRegion(polygonCollection, user.email);
      return true;
    }
  };

  return {
    checkRegionsData,
    prepareSubmitData,
    preparedSubmitData,
    setPreparedSubmitData,
    handleReportMissingPolygonParts
  };
};
