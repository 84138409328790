import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PublicProject } from '../../../types/API/PublicProjects';

interface PublicProjectsState {
  publicProjects: PublicProject[];
  projectToEditOrDelete?: PublicProject;
}

const initialState: PublicProjectsState = {
  publicProjects: [],
  projectToEditOrDelete: undefined
};

export const publicProjectsSlice = createSlice({
  name: 'publicProjects',
  initialState,
  reducers: {
    setPublicProjects: (state, action: PayloadAction<PublicProject[]>) => {
      state.publicProjects = action.payload;
    },
    setProjectToEditOrDelete: (state, action: PayloadAction<PublicProject | undefined>) => {
      state.projectToEditOrDelete = action.payload;
    },
    resetPublicProjectsState: (state) => {
      state.publicProjects = [];
      state.projectToEditOrDelete = undefined;
    }
  }
});

export default publicProjectsSlice.reducer;
export const { setProjectToEditOrDelete, setPublicProjects, resetPublicProjectsState } = publicProjectsSlice.actions;
