import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.lightBlueTransparent};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.blue};
  margin: 0;
  width: calc(100% - 78px);
`;
