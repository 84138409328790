import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRemoveAllBiomassCacheMutation, useRemoveAllTilesCacheMutation } from '../../../../redux/api/cacheApi';
import { useDeleteAllStorageMutation } from '../../../../redux/api/storageApi';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';

const InvalidateCacheModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [removeBiomassCache] = useRemoveAllBiomassCacheMutation();
  const [removeTilesCache] = useRemoveAllTilesCacheMutation();
  const [deleteAllStorage] = useDeleteAllStorageMutation();

  const handleInvalidateCache = useCallback(async () => {
    await removeBiomassCache();
    await removeTilesCache();
    await deleteAllStorage();

    dispatch(closeModal());

    toast.success(t('Cache invalidated successfully'), {
      toastId: 'invalidate-cache-toast',
      autoClose: 3000
    });
  }, [deleteAllStorage, dispatch, removeBiomassCache, removeTilesCache, t]);

  return (
    <Modal
      modalType="adminCacheInvalidation"
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="admin-invalidate-cache-modal"
      additionalSx={{
        width: '500px'
      }}
    >
      <Title data-test-id="admin-invalidate-cache-modal-title">{t('Invalidate selected data')}</Title>
      <Subtitle data-test-id="admin-invalidate-cache-modal-subtitle">
        {t(`Are you sure you want to invalidate this data? This can’t be undone`)}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="outline-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          dataTestId="admin-invalidate-cache-modal-cancel-button"
          additionalSx={{
            width: '190px'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="red"
          fullWidth
          onClick={handleInvalidateCache}
          dataTestId="admin-invalidate-cache-modal-invalidate-button"
          additionalSx={{
            width: '190px'
          }}
        >
          {t('Invalidate')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default InvalidateCacheModal;
