import { useTranslation } from 'react-i18next';
import ResetPasswordForm from '../components/Forms/ResetPasswordForm';
import AuthLayout from '../components/Layout/AuthLayout';
import { Subtitle, Title } from '../components/Layout/AuthLayout/style';

const ResetPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <Title>{t('Forgot Password')}</Title>
      <Subtitle>{t('Create a new password for your account.')}</Subtitle>
      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
