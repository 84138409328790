import { unparse } from 'papaparse';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { COMMON_FEATURE_BODY_TYPE } from '../constants/data-board';
import { usePolygonContext } from '../context/Polygon';
import { CarbonAccountingData, ForestCoverData, ForestDataType, ForestTemporalType } from '../context/Polygon/types';
import { useUpdateRegionMutation } from '../redux/api/regionApi';
import { openModal } from '../redux/features/modal/modal-slice';
import { setSelectedPolygon } from '../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { AreaUnitType, AreaWeightFullName } from '../types/Geo';
import { returnWeightByUnit } from '../utils/units';

export const useActionsMenu = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { user } = useAppSelector((state) => state.userState);
  const [updateRegion, { reset }] = useUpdateRegionMutation();
  const { getPolygonCarbonAccountingData, getPolygonForestCoverData } = usePolygonContext();

  const handleSubscribe = useCallback(async () => {
    if (selectedPolygon && selectedPolygon.id) {
      if (selectedPolygon?.isSubscribed) {
        dispatch(openModal('unsubscribeRegion'));
      } else {
        const data = await updateRegion({
          id: String(selectedPolygon?.id),
          body: {
            isSubscribed: true
          }
        });

        if (!('error' in data)) {
          dispatch(setSelectedPolygon(data.data));
          reset();
        } else {
          toast.error(t('We were not able to unsubscribe your region at this time'), {
            toastId: 'subscribe-region-error',
            autoClose: 3000
          });
          reset();
        }
      }
    }
  }, [dispatch, reset, selectedPolygon, t, updateRegion]);

  const handleDownloadGeoJson = useCallback(() => {
    if (!selectedPolygon) return;
    const GeoJSON = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: selectedPolygon.properties,
          geometry: selectedPolygon.geometry
        }
      ]
    };
    const blob = new Blob([JSON.stringify(GeoJSON)], {
      type: 'application/json'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${
      String(selectedPolygon.name) || String(selectedPolygon.properties?.name) || 'Cyclops-Region'
    }-${new Date().toLocaleDateString()}.geojson`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [selectedPolygon]);

  const handleDownloadCSV = useCallback(async () => {
    if (!selectedPolygon) return;

    const query = COMMON_FEATURE_BODY_TYPE(selectedPolygon);

    const promises = [
      getPolygonCarbonAccountingData(query, 'above-ground-biomass', 'data', true),
      getPolygonCarbonAccountingData(query, 'above-ground-biomass', 'data/area', true),
      getPolygonForestCoverData(query, ForestTemporalType.LINEAR, ForestDataType.AREA, true),
      getPolygonForestCoverData(query, ForestTemporalType.LINEAR, ForestDataType.PERCENTAGE, true)
    ];

    const [carbonAGB, carbonAGBArea, forestArea, forestPercent] = await Promise.all(promises);

    const dataMap: any = {};

    const addDataToMap = (
      data: (CarbonAccountingData | ForestCoverData)[],
      dataType: string,
      dataField: string,
      customDecimalPlaces?: string
    ) => {
      data.forEach(({ time, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!dataMap[time]) {
          const [year, month] = time.split('-');
          const quarter = Math.ceil(parseInt(month, 10) / 3);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          dataMap[time] = { Timestamp: `${year} Q${quarter}` };
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const fieldValue = (rest as any)[dataField];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        dataMap[time][dataType] = customDecimalPlaces
          ? customDecimalPlaces === '0'
            ? Math.round(+fieldValue)
            : +(+fieldValue).toFixed(parseInt(customDecimalPlaces))
          : fieldValue;
      });
    };

    const headers = {
      time: 'Timestamp',
      'above-ground-biomass-area': `${t('Mean Above Ground Biomass')} (${AreaWeightFullName[
        returnWeightByUnit(user?.settings.unit as AreaUnitType)
      ].toLowerCase()}/${user?.settings.unit as AreaUnitType})`,
      'above-ground-biomass': `${t('Total Above Ground Biomass')} (megatonnes)`,
      'forest-cover-area': `${t('Total Forested Area')} (${user?.settings.unit as AreaUnitType})`,
      'non-forest-cover-area': `${t('Total Non-forested Area')} (${user?.settings.unit as AreaUnitType})`,
      'forest-cover-percentage': t('Forested Area Ratio')
    };

    if (carbonAGB) addDataToMap(carbonAGB, headers['above-ground-biomass'], 'carbon', '0');
    if (carbonAGBArea) addDataToMap(carbonAGBArea, headers['above-ground-biomass-area'], 'carbon', '0');
    if (forestArea) addDataToMap(forestArea, headers['forest-cover-area'], 'forest', '0');
    if (forestArea) addDataToMap(forestArea, headers['non-forest-cover-area'], 'deforestation', '0');
    if (forestPercent) addDataToMap(forestPercent, headers['forest-cover-percentage'], 'forest', '2');

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const csv = unparse(Object.values(dataMap), {
      header: true,
      columns: Object.values(headers)
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [t, user?.settings.unit, selectedPolygon, getPolygonCarbonAccountingData, getPolygonForestCoverData]);

  return {
    handleSubscribe,
    handleDownloadGeoJson,
    handleDownloadCSV
  };
};
