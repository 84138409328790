import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import styled from 'styled-components';
import Icon from '../../Common/Icon';
import { TooltipContainer, TooltipIconContainer, TooltipSX, TooltipText, TooltipTitle } from './style';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(() => TooltipSX);

interface CustomTooltipProps {
  title: string;
  content: JSX.Element;
}

export const renderCustomTooltip = ({ title, content }: CustomTooltipProps) => (
  <HtmlTooltip
    title={
      <TooltipContainer>
        <TooltipTitle>{title}</TooltipTitle>
        <TooltipText>{content}</TooltipText>
      </TooltipContainer>
    }
    placement={'top-end'}
    followCursor
  >
    <TooltipIconContainer>
      <Icon color={'gray'} variant="INFO_CIRCLE" size={18} />
    </TooltipIconContainer>
  </HtmlTooltip>
);
