import { useTranslation } from 'react-i18next';
import { COUNTRIES_LIST } from '../../../../constants/countries';
import { FOLDER_OPTIONS, STATUS_OPTIONS } from '../../../../constants/region';
import { useGetUserFoldersQuery } from '../../../../redux/api/folderApi';
import { setRegionToEditOrDelete } from '../../../../redux/features/region/region-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { IRegionProperties } from '../../../../types/API/Region';
import Input from '../../../Common/Input';
import Select from '../../../Common/Select';
import Textarea from '../../../Common/Textarea';

const Overview = () => {
  useGetUserFoldersQuery();

  const dispatch = useAppDispatch();
  const { regionToEditOrDelete } = useAppSelector((state) => state.regionState);
  const { folders } = useAppSelector((state) => state.folderState);
  const { t } = useTranslation();

  if (!regionToEditOrDelete) return null;

  return (
    <form>
      <Input
        value={regionToEditOrDelete.name}
        onChange={(value) => dispatch(setRegionToEditOrDelete({ ...regionToEditOrDelete, name: value }))}
        label={String(t('Project Name'))}
        omitPlaceholder
        required
      />
      {folders.length > 0 ? (
        <Select
          value={regionToEditOrDelete.folderId || 'empty'}
          onChange={(value: string) =>
            dispatch(
              setRegionToEditOrDelete({
                ...regionToEditOrDelete,
                folderId: value
              })
            )
          }
          additionalPaperSx={{
            ul: {
              padding: 0
            }
          }}
          optionWidth={'398px'}
          fullWidth
          options={FOLDER_OPTIONS(folders, t)}
          label={String(t('Add to Folder (optional)'))}
        />
      ) : null}
      <Select
        value={regionToEditOrDelete.properties?.status || 'empty'}
        onChange={(value: IRegionProperties['status']) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                status: value
              }
            })
          )
        }
        options={STATUS_OPTIONS(t)}
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
        label={String(t('Project Status (optional)'))}
        fullWidth
        optionWidth={'398px'}
      />
      <Select
        value={regionToEditOrDelete.properties?.country || []}
        multiple
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
        onChange={(value: string[]) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              properties: {
                ...regionToEditOrDelete.properties,
                country: value
              }
            })
          )
        }
        options={COUNTRIES_LIST}
        label={String(t('Country (optional)'))}
        fullWidth
        optionWidth={'398px'}
      />
      <Textarea
        value={regionToEditOrDelete.summary || ''}
        onChange={(value) =>
          dispatch(
            setRegionToEditOrDelete({
              ...regionToEditOrDelete,
              summary: value
            })
          )
        }
        label={String(t('Project Summary (optional)'))}
        minRows={5}
        omitPlaceholder
      />
    </form>
  );
};

export default Overview;
