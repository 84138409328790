import center from '@turf/center';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-map-gl';
import { MAPBOX_MAP_STYLES } from '../../../../constants/map';
import { useAppSelector } from '../../../../redux/hooks';
import PolygonCanvas from '../../../Common/PolygonCanvas';
import MultiPolygonCanvas from '../../../Common/PolygonCanvas/MultipolygonCanvas';
import { MapButton, MapContainer, PolygonContainer } from './style';

const ProjectDetailMap = () => {
  const { t } = useTranslation();
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const centerPoint = useMemo(() => (selectedPolygon ? center(selectedPolygon?.geometry) : null), [selectedPolygon]);

  const handleShowOnMap = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      window.open(`/?region=${String(selectedPolygon?.id)}`, '_blank');
    },
    [selectedPolygon?.id]
  );

  return (
    <MapContainer>
      <MapButton onClick={(e) => handleShowOnMap(e)}>{t('View on Map')}</MapButton>
      <Map
        longitude={centerPoint?.geometry.coordinates[0] || 0}
        latitude={centerPoint?.geometry.coordinates[1] || 0}
        mapStyle={MAPBOX_MAP_STYLES.satellite}
        zoom={7.2}
        preserveDrawingBuffer
        id="projectMap"
        data-test-id="project-map"
        style={{
          width: '500px',
          height: '400px',
          borderRadius: '8px',
          pointerEvents: 'none'
        }}
        renderWorldCopies={false}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      />
      <PolygonContainer>
        {selectedPolygon?.geometry.type === 'Polygon' ? (
          <PolygonCanvas
            key={selectedPolygon.id}
            data-test-id={`saved-regions-region-polygon-canvas`}
            fillColor="rgba(20, 162, 241, 0.56)"
            strokeColor="rgba(20, 162, 241, 1)"
            width={130}
            height={130}
            polygon={selectedPolygon as GeoJSON.Feature<GeoJSON.Polygon>}
          />
        ) : (
          <MultiPolygonCanvas
            key={selectedPolygon?.id}
            data-test-id={`saved-regions-region-multipolygon-canvas`}
            fillColor="rgba(20, 162, 241, 0.56)"
            strokeColor="rgba(20, 162, 241, 1)"
            width={130}
            height={130}
            polygon={selectedPolygon as unknown as GeoJSON.Feature<GeoJSON.MultiPolygon>}
          />
        )}
      </PolygonContainer>
    </MapContainer>
  );
};

export default ProjectDetailMap;
