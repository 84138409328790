import styled from 'styled-components';
import { IconWrapper } from '../../../Common/Icon/style';

export const FolderSelectActionsWrapper = styled.div<{
  active?: boolean;
}>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  background-color: ${({ theme }) => theme.colors.blackII};
  height: 96px;
  width: 100vw;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: ${({ theme }) => theme.transition};
  transform: translateY(${({ active }) => (active ? 0 : 100)}%);
  filter: ${({ active }) => (active ? 'none' : 'blur(6px)')};

  ${IconWrapper} {
    margin-right: 10px;
  }
`;
