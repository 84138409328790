import styled, { css } from 'styled-components';

export const DrawInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: absolute;
  z-index: 9999999;
  padding: 8px;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

const CommonTextCss = css`
  display: block;
`;

export const DrawInfoCoordinates = styled.span`
  ${CommonTextCss};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
`;
