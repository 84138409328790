import MUIButton from '@mui/material/Button';
import { MouseEventHandler, ReactNode } from 'react';
import { ButtonVariant, ColorVariant } from '../../../types/Theme';
import Icon, { IconVariant } from '../Icon';
import { ButtonSX } from './style';

export interface Props {
  variant: ButtonVariant;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'button' | 'submit' | 'reset';
  additionalSx?: any;
  iconName?: IconVariant;
  iconPosition?: 'start' | 'end';
  iconColor?: ColorVariant;
  iconSize?: number;
  dataTestId?: string;
}

const Button = ({
  variant,
  onClick,
  children,
  disabled,
  fullWidth,
  type = 'button',
  additionalSx,
  iconName,
  iconPosition,
  iconColor,
  iconSize,
  dataTestId
}: Props) => {
  const renderButtonIcon = () =>
    iconName && iconSize && iconColor && <Icon variant={iconName} size={iconSize} color={iconColor} />;

  return (
    <MUIButton
      type={type}
      disabled={disabled}
      onClick={onClick}
      startIcon={iconPosition && iconPosition === 'start' && renderButtonIcon()}
      endIcon={iconPosition && iconPosition === 'end' && renderButtonIcon()}
      fullWidth={fullWidth}
      data-test-id={dataTestId}
      sx={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        additionalSx ? { ...ButtonSX(variant), ...additionalSx } : ButtonSX(variant)
      }
    >
      {children}
    </MUIButton>
  );
};

export default Button;
