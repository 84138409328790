import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PolicyContentBox,
  PolicyContentDescription,
  PolicyContentItem,
  PolicyContentParagraph,
  PolicyContentProgressBar,
  PolicyContentTitle,
  PolicyContentWrapper,
  PolicyGradient,
  PolicySidebar,
  PolicySidebarList,
  PolicySidebarListItem
} from './style';

interface IPolicy {
  content: {
    title: string;
    content?: string[];
    description?: string[];
  }[];
}

const Policy = ({ content }: IPolicy) => {
  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [progress, setProgress] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const element = contentRef.current;
    if (element) {
      const scrollPosition = element.scrollTop;
      const totalHeight = element.scrollHeight - element.offsetHeight;
      const percentage = (scrollPosition / totalHeight) * 100;

      if (Object.values(content).length) {
        const calculatedIndex = Math.floor(Object.values(content).length * (scrollPosition / totalHeight));
        setActiveIndex(calculatedIndex);
      }
      setProgress(percentage);
    }
  }, [content]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', '')) as HTMLDivElement;
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location.hash]);

  return (
    <PolicyContentBox>
      <PolicySidebar data-test-id="policy-sidebar">
        <PolicySidebarList data-test-id="policy-sidebar-list">
          {content.map((item, index) => (
            <PolicySidebarListItem
              data-test-id="policy-sidebar-list-item"
              key={`${item.title.split(' ').join('-')}-sidebar`}
              active={activeIndex ? activeIndex >= index : false}
            >
              <a href={`#${item.title.split(' ').join('-')}`}>{item.title}</a>
            </PolicySidebarListItem>
          ))}
        </PolicySidebarList>
      </PolicySidebar>
      <PolicyContentWrapper onScroll={handleScroll} ref={contentRef}>
        {content.map((item) => (
          <PolicyContentItem
            key={item.title.split(' ').join('-')}
            id={item.title.split(' ').join('-')}
            data-test-id="policy-content-item"
          >
            <PolicyContentTitle>{item.title}</PolicyContentTitle>
            {item.description?.map((paragraph, index) => (
              <PolicyContentDescription
                data-test-id="policy-content-description"
                key={`${item.title.split(' ').join('-')}-description-${index}`}
                dangerouslySetInnerHTML={{
                  __html: paragraph
                }}
              />
            ))}
            {item.content?.map((paragraph, index) => (
              <PolicyContentParagraph
                data-test-id="policy-content-paragraph"
                key={`${item.title.split(' ').join('-')}-paragraph-${index}`}
                dangerouslySetInnerHTML={{
                  __html: paragraph
                }}
              />
            ))}
          </PolicyContentItem>
        ))}
        <PolicyGradient />
        <PolicyContentProgressBar progress={progress} data-test-id="policy-content-progress-bar" />
      </PolicyContentWrapper>
    </PolicyContentBox>
  );
};

export default Policy;
