import { useCallback, useEffect, useRef } from 'react';
import { theme } from '../../../theme/Theme';

interface Props {
  polygon: GeoJSON.Feature<GeoJSON.MultiPolygon>; // Update the type to MultiPolygon
  width?: number;
  height?: number;
  fillColor?: string;
  strokeColor?: string;
  dataTestId?: string;
  lineWidth?: number;
}

interface BoundingBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

interface CanvasTransformParams {
  ratio: number;
  xOffset: number;
  yOffset: number;
}

const MultiPolygonCanvas = ({
  polygon,
  width = 400,
  height = 400,
  fillColor = 'rgba(135, 139, 235, 0.6)',
  strokeColor = theme.colors.purple,
  dataTestId,
  lineWidth = 1
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  /**
   * Calculates the bounding box of a multipolygon's coordinates
   *
   * @param {GeoJSON.Position[][]} coordinates - The coordinates of the multipolygon
   * @returns {{ minX: number, minY: number, maxX: number, maxY: number }} The bounding box of the coordinates
   */
  const getBoundingBox = (coordinates: number[][][]): BoundingBox => {
    return coordinates.reduce(
      (box, polygonCoords) => {
        return polygonCoords.reduce((polygonBox, coord) => {
          const [x, y] = coord;
          polygonBox.minX = Math.min(polygonBox.minX, x);
          polygonBox.maxX = Math.max(polygonBox.maxX, x);
          polygonBox.minY = Math.min(polygonBox.minY, y);
          polygonBox.maxY = Math.max(polygonBox.maxY, y);
          return polygonBox;
        }, box);
      },
      { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
    );
  };

  /**
   * Calculates the canvas transform parameters to fit a multipolygon into a canvas
   *
   * @param {{ minX: number, minY: number, maxX: number, maxY: number }} boundingBox - The bounding box of the multipolygon's coordinates
   * @param {number} canvasWidth - The width of the canvas
   * @param {number} canvasHeight - The height of the canvas
   * @returns {{ ratio: number, xOffset: number, yOffset: number }} The canvas transform parameters
   */
  const getCanvasTransformParams = useCallback(
    (boundingBox: BoundingBox): CanvasTransformParams => {
      const xRatio = width / (boundingBox.maxX - boundingBox.minX);
      const yRatio = height / (boundingBox.maxY - boundingBox.minY);
      const ratio = Math.min(xRatio, yRatio);
      const xOffset = -boundingBox.minX * ratio;
      const yOffset = -boundingBox.minY * ratio;

      return { ratio, xOffset, yOffset };
    },
    [width, height]
  );

  /**
   * Draws a MultiPolygon on a canvas
   *
   * @param {CanvasRenderingContext2D} ctx - The 2D rendering context of the canvas
   * @param {GeoJSON.Position[][]} coordinates - The coordinates of the MultiPolygon
   * @param {number} ratio - The ratio used to scale the MultiPolygon to fit the canvas
   * @param {number} xOffset - The x-axis offset used to position the MultiPolygon in the canvas
   * @param {number} yOffset - The y-axis offset used to position the MultiPolygon in the canvas
   */
  const drawMultipolygon = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      coordinates: GeoJSON.Position[][][],
      ratio: number,
      xOffset: number,
      yOffset: number
    ) => {
      ctx.beginPath();
      for (let i = 0; i < coordinates.length; i++) {
        const polygonCoords = coordinates[i];
        // Move to the first point of the polygon
        ctx.moveTo(polygonCoords[0][0][0] * ratio + xOffset, height - (polygonCoords[0][0][1] * ratio + yOffset));

        // Draw the polygon
        for (let j = 0; j < polygonCoords[0].length; j++) {
          const [x, y] = polygonCoords[0][j];
          ctx.lineTo(x * ratio + xOffset, height - (y * ratio + yOffset));
        }
        ctx.closePath();
      }

      ctx.fillStyle = fillColor;
      ctx.strokeStyle = strokeColor;
      ctx.fill();
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    },
    [fillColor, height, lineWidth, strokeColor]
  );

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        const coordinates = polygon.geometry.coordinates;
        const boundingBox = getBoundingBox(coordinates.flat());
        const { ratio, xOffset, yOffset } = getCanvasTransformParams(boundingBox);

        drawMultipolygon(ctx, coordinates, ratio, xOffset, yOffset);
      }
    }
  }, [polygon, width, height, getCanvasTransformParams, drawMultipolygon]);

  return <canvas data-test-id={dataTestId} ref={canvasRef} width={width} height={height} />;
};

export default MultiPolygonCanvas;
