import styled from 'styled-components';

export const UploadSection = styled.div`
  padding: 30px 83px;
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.purple};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const UploadSectionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.grey};
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  margin: 10px 0 30px;
`;

export const UploadSectionText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  margin: 20px 0 0;
`;

export const UploadSectionSeparator = styled.h3`
  color: ${({ theme }) => theme.colors.purple};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const UploadSectionButton = styled.label`
  width: 190px;
  height: 46px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.purple};
  border: 1px solid ${({ theme }) => theme.colors.purple};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple};
    color: ${({ theme }) => theme.colors.white};
  }
`;
