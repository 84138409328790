import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DATA_BOARD_STEPS } from '../../constants/joyride';
import useJoyride from '../../hooks/useJoyride';
import { openModal } from '../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Banner from '../Common/Banner';
import Spacer from '../Common/Spacer';
import CarbonAccounting from './CarbonAccounting';
import Controls from './Controls';
import Deforestation from './Deforestation';
import FloatingActionsButtons from './FloatingActionsButtons';
import ForestCover from './ForestCover';
import Loader from './Loader';
import DataBoardSkeletonComponent from './Skeleton';
import { ContentWrapper, ControlsAreaWrapper, LastUpdated } from './style';

const DataBoard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { showDeforestationComponent, dataBoardTab } = useAppSelector((state) => state.uiState);
  const { user } = useAppSelector((state) => state.userState);
  const { selectedPolygon, carbonLoading, forestCoverLoading, deforestation, carbonAccounting, forestCover } =
    useAppSelector((state) => state.regionState);

  const isDataLoading = useMemo(
    () => (carbonLoading || forestCoverLoading) && !forestCover && !carbonAccounting,
    [carbonLoading, forestCoverLoading, forestCover, carbonAccounting]
  );

  const renderContent = () => {
    if (isDataLoading) return <DataBoardSkeletonComponent />;

    return (
      <ContentWrapper>
        <ControlsAreaWrapper>
          <Controls />
        </ControlsAreaWrapper>
        {dataBoardTab === '0' ? <CarbonAccounting /> : <ForestCover />}
        {selectedPolygon?.dataUpdatedAt ? (
          <LastUpdated data-test-id="data-board-last-updated">
            Data Updated: {new Date(selectedPolygon.dataUpdatedAt).toLocaleString()}
          </LastUpdated>
        ) : null}
      </ContentWrapper>
    );
  };

  useJoyride(DATA_BOARD_STEPS(t));

  return (
    <ContentWrapper id="data-board">
      {!isDataLoading && user?.role === 'free' ? (
        <>
          <Banner
            dataTestId="data-board-upgrade-banner"
            title={t('Viewing Old Data')}
            message={t('To see real-time data and more, request to upgrade to pro.')}
            buttonLabel={t('Upgrade to Pro')}
            onClick={() => dispatch(openModal('upgradeAccount'))}
            icon="WARNING"
            type="danger"
          />
          <Spacer size={'24px 0 0'} />
        </>
      ) : null}
      {renderContent()}
      {deforestation ? (
        <Deforestation
          visible={showDeforestationComponent}
          deforestationData={deforestation}
          dataTestId={'data-board-deforestation'}
        />
      ) : null}
      <Spacer size="30px 0 0" />
      {isDataLoading ? (
        <Loader
          title={t('Keep Exploring...')}
          description={t("Your data is loading. We'll notify you when it's ready.")}
        />
      ) : null}
      <FloatingActionsButtons isDataLoading={isDataLoading} />
    </ContentWrapper>
  );
};

export default DataBoard;
