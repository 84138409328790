import styled from 'styled-components';

export const DroppableWrapper = styled.div<{
  isOver?: boolean;
  selected?: boolean;
}>`
  border: ${({ isOver, theme }) => (isOver ? `1px solid ${theme.colors.green}` : '1px solid transparent')};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const DraggableWrapper = styled.button<{
  transform: { x: number; y: number };
}>`
  transform: ${({ transform }) => (transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined)};
  background: transparent;
  text-align: left;
  z-index: ${({ transform }) => (transform ? 1 : undefined)};
`;
