import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PORTFOLIO_SIDEBAR_FOLDERS_STEPS } from '../../../../constants/joyride';
import useJoyride from '../../../../hooks/useJoyride';
import { useAppSelector } from '../../../../redux/hooks';
import Icon from '../../../Common/Icon';
import Draggable from '../../../DragAndDrop/Draggable';
import Droppable from '../../../DragAndDrop/Droppable';
import { FolderItem, FolderProject, FolderProjects, FoldersWrapper, FolderUnfoldDiv } from './style';

const FolderList = () => {
  const { t } = useTranslation();
  const { folders } = useAppSelector((state) => state.folderState);
  const [unfoldedFolders, setUnfoldedFolders] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle folder open/close on page load
  useEffect(() => {
    if (location.pathname.includes('/portfolio/folder')) {
      const folderId = location.pathname.split('/')[3];
      if (folderId) {
        setUnfoldedFolders((u) => [...u, folderId]);
      }
    }
  }, [location.pathname]);

  const handleFolderClick = useCallback(
    (e: MouseEvent, folderId: string) => {
      e.stopPropagation();

      if (unfoldedFolders.includes(folderId)) {
        setUnfoldedFolders((u) => u.filter((id) => id !== folderId));
      } else {
        setUnfoldedFolders((u) => [...u, folderId]);
      }
    },
    [unfoldedFolders]
  );

  useJoyride(folders.length > 0 ? PORTFOLIO_SIDEBAR_FOLDERS_STEPS(t) : []);

  return (
    <FoldersWrapper id="portfolio-sidebar-folders">
      {folders.map((folder) => (
        <Droppable key={folder.id} id={`sidebar=${folder.id}`} folderId={folder.id}>
          <FolderItem
            active={location.pathname.includes(`/portfolio/folder/${folder.id}`)}
            onClick={() => navigate(`/portfolio/folder/${folder.id}`)}
          >
            <FolderUnfoldDiv
              active={unfoldedFolders.includes(folder.id)}
              onClick={folder.regions && folder.regions.length > 0 ? (e) => handleFolderClick(e, folder.id) : undefined}
            >
              {folder.regions && folder.regions.length > 0 ? (
                <div>
                  <Icon variant="CHEVRON_RIGHT" color="gray" size={16} />
                </div>
              ) : (
                <span style={{ width: '16px' }}></span>
              )}
              <Icon variant="FOLDER" color="gray" size={24} />
            </FolderUnfoldDiv>
            <span>{folder.name}</span>
          </FolderItem>
          {folder.regions && folder.regions.length > 0 ? (
            <FolderProjects unfolded={unfoldedFolders.includes(folder.id)}>
              {[...(folder.regions || [])]
                .sort((a, b) => ((a.name?.toLowerCase() || '') > (b.name?.toLowerCase() || '') ? 1 : -1))
                .map((region) => (
                  <Draggable
                    key={region.id}
                    id={`sidebar-${String(region.id)}`}
                    regionId={String(region.id)}
                    folderId={folder.id}
                  >
                    <FolderProject
                      key={region.id}
                      active={location.pathname.includes(`/portfolio/folder/${folder.id}/project/${String(region.id)}`)}
                      onClick={() => navigate(`/portfolio/folder/${folder.id}/project/${String(region.id)}`)}
                    >
                      {region.name}
                    </FolderProject>
                  </Draggable>
                ))}
            </FolderProjects>
          ) : null}
        </Droppable>
      ))}
    </FoldersWrapper>
  );
};

export default FolderList;
