import { Navigate, Outlet } from 'react-router-dom';
import { ModalProvider } from '../../context/Modal';
import { getCookie } from '../../utils/helpers';

const AdminPrivateRoutes = () => {
  const isAuthenticated = getCookie('authorized');

  if (isAuthenticated === 'true') {
    return (
      <ModalProvider>
        <Outlet />
      </ModalProvider>
    );
  }
  return <Navigate to="/login/admin" />;
};

export default AdminPrivateRoutes;
