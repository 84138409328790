import { Path, Svg } from '@react-pdf/renderer';

const ReportLogo = () => {
  return (
    <Svg width="76" height="13" viewBox="0 0 76 13">
      <Path
        d="M27.3366 12.2217C29.5007 12.2217 30.7831 11.4518 31.9373 10.2168L30.3663 8.62898C29.4847 9.43091 28.6992 9.94417 27.4167 9.94417C25.4931 9.94417 24.1626 8.34027 24.1626 6.41561V6.38352C24.1626 4.45882 25.5252 2.88702 27.4167 2.88702C28.5389 2.88702 29.4205 3.36819 30.2862 4.1541L31.8572 2.34169C30.8152 1.3152 29.5488 0.609486 27.4328 0.609486C23.9862 0.609486 21.5817 3.22384 21.5817 6.41561V6.44765C21.5817 9.67152 24.0344 12.2217 27.3366 12.2217Z"
        fill="#09141D"
      />
      <Path d="M33.9135 12.0292H41.9768V9.78379H36.3822V0.801952H33.9135V12.0292Z" fill="#09141D" />
      <Path
        d="M15.3333 12.0292H17.802V7.55436L22.1141 0.801952H19.3088L16.5836 5.3089L13.9066 0.801952H11.0211L15.3333 7.60246V12.0292Z"
        fill="#09141D"
      />
      <Path
        d="M5.75488 12.2217C7.91898 12.2217 9.2014 11.4518 10.3556 10.2168L8.78461 8.62898C7.90296 9.43091 7.11749 9.94417 5.83503 9.94417C3.9114 9.94417 2.58089 8.34027 2.58089 6.41561V6.38352C2.58089 4.45882 3.94346 2.88702 5.83503 2.88702C6.95715 2.88702 7.83884 3.36819 8.70446 4.1541L10.2755 2.34169C9.23348 1.3152 7.96709 0.609486 5.85109 0.609486C2.40455 0.609486 0 3.22384 0 6.41561V6.44765C0 9.67152 2.45264 12.2217 5.75488 12.2217Z"
        fill="#09141D"
      />
      <Path
        d="M57.4327 12.0292H59.9014V8.66107H61.7772C64.2938 8.66107 66.3134 7.31379 66.3134 4.71546V4.68337C66.3134 2.38981 64.6946 0.801952 62.0177 0.801952H57.4327V12.0292ZM59.9014 6.46372V3.03137H61.8092C63.0433 3.03137 63.8128 3.62481 63.8128 4.73152V4.76356C63.8128 5.72591 63.0917 6.46372 61.8571 6.46372H59.9014Z"
        fill="#09141D"
      />
      <Path
        d="M71.8239 12.1896C74.2447 12.1896 75.9439 10.9386 75.9439 8.70917V8.67708C75.9439 6.72034 74.6614 5.90235 72.3848 5.3089C70.4451 4.81171 69.9645 4.5711 69.9645 3.83332V3.80124C69.9645 3.25591 70.4615 2.82286 71.4072 2.82286C72.3529 2.82286 73.3309 3.23987 74.3245 3.92955L75.607 2.06903C74.4688 1.15481 73.0744 0.641563 71.4391 0.641563C69.1466 0.641563 67.5117 1.98884 67.5117 4.02578V4.05786C67.5117 6.28727 68.9704 6.9128 71.2306 7.49019C73.1064 7.97136 73.4911 8.29216 73.4911 8.91765V8.94973C73.4911 9.60735 72.8818 10.0083 71.8718 10.0083C70.5897 10.0083 69.5314 9.47901 68.5218 8.645L67.0627 10.3933C68.4095 11.5962 70.1247 12.1896 71.8239 12.1896Z"
        fill="#09141D"
      />
      <Path
        fillRule="evenodd"
        d="M55.8604 6.47727C55.8604 10.0546 52.9621 12.9545 49.3866 12.9545C45.8112 12.9545 42.9128 10.0546 42.9128 6.47727C42.9128 2.89997 45.8112 0 49.3866 0C52.9621 0 55.8604 2.89997 55.8604 6.47727ZM49.3896 2.13325C46.9905 2.13325 45.0455 4.07928 45.0455 6.47982C45.0455 8.88038 46.9905 10.8264 49.3896 10.8264C51.7893 10.8264 53.7342 8.88038 53.7342 6.47982H54.5857C54.5857 9.35111 52.2595 11.6787 49.3896 11.6787C46.5202 11.6787 44.1936 9.35111 44.1936 6.47982C44.1936 3.60859 46.5202 1.28098 49.3896 1.28098V2.13325ZM49.3896 3.83778C47.9314 3.83778 46.7491 5.02066 46.7491 6.47982C46.7491 7.93898 47.9314 9.12186 49.3896 9.12186C50.8483 9.12186 52.0306 7.93898 52.0306 6.47982H52.8822C52.8822 8.40966 51.3186 9.97414 49.3896 9.97414C47.4611 9.97414 45.8975 8.40966 45.8975 6.47982C45.8975 4.54998 47.4611 2.9855 49.3896 2.9855V3.83778ZM49.3896 8.01394C50.2364 8.01394 50.923 7.32709 50.923 6.47982C50.923 5.63259 50.2364 4.94574 49.3896 4.94574C48.5428 4.94574 47.8567 5.63259 47.8567 6.47982C47.8567 7.32709 48.5428 8.01394 49.3896 8.01394Z"
        fill="#58DFAF"
      />
    </Svg>
  );
};

export default ReportLogo;
