import { Text, View } from '@react-pdf/renderer';
import FooterLogo from '../../Assets/FooterLogo';
import { styles } from './style';

interface Props {
  regionName?: string;
}

const Footer = ({ regionName }: Props) => {
  return (
    <View style={styles.footerWrapper} fixed>
      <FooterLogo />
      <Text
        render={({ pageNumber }) => (regionName ? `${regionName} | ${pageNumber}` : pageNumber)}
        style={styles.footerText}
      />
    </View>
  );
};

export default Footer;
