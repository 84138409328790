import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CarbonAccountingData,
  CarbonDataType,
  CarbonDisplayPerType,
  ForestCoverData,
  ForestDataType,
  ForestTemporalType
} from '../../../context/Polygon/types';
import { IRegionDocument, IRegionResponse, ISelectedPolygon } from '../../../types/API/Region';
import { IBiomass } from '../../../types/Geo';

interface RegionState {
  carbonAccounting: IBiomass | null;
  carbonAccountingChartData: CarbonAccountingData[];
  carbonDisplayPer: CarbonDisplayPerType;
  carbonDataType: CarbonDataType;
  carbonLoading: boolean;

  deforestation: IBiomass | null;

  forestCover: IBiomass | null;
  forestCoverChartData: ForestCoverData[];
  forestTemporalType: ForestTemporalType;
  forestDataType: ForestDataType;
  forestCoverLoading: boolean;

  selectedPolygon: ISelectedPolygon | null;
  calculatedArea: number;

  coveredArea: GeoJSON.Feature<GeoJSON.Polygon>[];
  userTiles: IRegionResponse[];
  regionToEditOrDelete: IRegionResponse | ISelectedPolygon | null;
  regionIdsToEditOrDelete: string[];
  documentToEditOrDelete: IRegionDocument | null;
}

const initialState: RegionState = {
  carbonAccounting: null,
  carbonAccountingChartData: [],
  carbonDisplayPer: 'data',
  carbonDataType: 'sequestered-carbon',
  carbonLoading: false,

  deforestation: null,

  forestCover: null,
  forestCoverChartData: [],
  forestTemporalType: ForestTemporalType.LINEAR,
  forestDataType: ForestDataType.PERCENTAGE,
  forestCoverLoading: false,

  selectedPolygon: null,
  calculatedArea: 0,

  coveredArea: [],
  userTiles: [],
  regionToEditOrDelete: null,
  regionIdsToEditOrDelete: [],

  documentToEditOrDelete: null
};

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setSelectedPolygon: (state, action: PayloadAction<ISelectedPolygon | null>) => {
      state.selectedPolygon = action.payload;
    },
    setCoveredArea: (state, action: PayloadAction<GeoJSON.Feature<GeoJSON.Polygon>[]>) => {
      state.coveredArea = action.payload;
    },
    setUserTiles: (state, action: PayloadAction<IRegionResponse[]>) => {
      state.userTiles = action.payload;
    },
    setCalculatedArea: (state, action: PayloadAction<number>) => {
      state.calculatedArea = action.payload;
    },
    setCarbonAccounting: (state, action: PayloadAction<IBiomass | null>) => {
      state.carbonAccounting = action.payload;
    },
    setCarbonAccountingChartData: (state, action: PayloadAction<CarbonAccountingData[]>) => {
      state.carbonAccountingChartData = action.payload;
    },
    setCarbonDisplayPer: (state, action: PayloadAction<CarbonDisplayPerType>) => {
      state.carbonDisplayPer = action.payload;
    },
    setCarbonDataType: (state, action: PayloadAction<CarbonDataType>) => {
      state.carbonDataType = action.payload;
    },
    setDeforestation: (state, action: PayloadAction<IBiomass>) => {
      state.deforestation = action.payload;
    },
    setForestCover: (state, action: PayloadAction<IBiomass | null>) => {
      state.forestCover = action.payload;
    },
    setForestCoverChartData: (state, action: PayloadAction<ForestCoverData[]>) => {
      state.forestCoverChartData = action.payload;
    },
    setForestTemporalType: (state, action: PayloadAction<ForestTemporalType>) => {
      state.forestTemporalType = action.payload;
    },
    setForestDataType: (state, action: PayloadAction<ForestDataType>) => {
      state.forestDataType = action.payload;
    },
    resetRegionState: ({ coveredArea, userTiles }) => ({
      ...initialState,
      coveredArea,
      userTiles
    }),
    setCarbonLoading: (state, action: PayloadAction<boolean>) => {
      state.carbonLoading = action.payload;
    },
    setForestCoverLoading: (state, action: PayloadAction<boolean>) => {
      state.forestCoverLoading = action.payload;
    },
    setRegionToEditOrDelete: (state, action: PayloadAction<IRegionResponse | ISelectedPolygon>) => {
      state.regionToEditOrDelete = action.payload;
    },
    setRegionIdsToEditOrDelete: (state, action: PayloadAction<string[]>) => {
      state.regionIdsToEditOrDelete = action.payload;
    },
    setDocumentToEditOrDelete: (state, action: PayloadAction<IRegionDocument | null>) => {
      state.documentToEditOrDelete = action.payload;
    }
  }
});

export default regionSlice.reducer;
export const {
  setSelectedPolygon,
  setCoveredArea,
  setUserTiles,
  setCalculatedArea,
  setCarbonAccounting,
  setCarbonAccountingChartData,
  setCarbonDisplayPer,
  setCarbonDataType,
  setDeforestation,
  setForestCover,
  setForestCoverChartData,
  setForestTemporalType,
  setForestDataType,
  resetRegionState,
  setCarbonLoading,
  setForestCoverLoading,
  setRegionToEditOrDelete,
  setRegionIdsToEditOrDelete,
  setDocumentToEditOrDelete
} = regionSlice.actions;
