import area from '@turf/area';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PORTFOLIO_SUMMARY_STEPS } from '../../../constants/joyride';
import useJoyride from '../../../hooks/useJoyride';
import { useAppSelector } from '../../../redux/hooks';
import { IRegionResponse } from '../../../types/API/Region';
import { AreaUnitType, SQ_METER_TO_KILOMETER_RATIO } from '../../../types/Geo';
import { convertSqKmTo } from '../../../utils/units';
import { StatsWrapper } from './style';
import SummaryCard from './SummaryCard';

interface IProps {
  regions: IRegionResponse[];
}

const SummaryStats = ({ regions }: IProps) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.userState.user);

  const totalArea = useMemo(() => {
    return regions.reduce((acc, tile) => {
      const calculatedArea = Math.floor(convertSqKmTo(area(tile) / SQ_METER_TO_KILOMETER_RATIO, user?.settings.unit));
      return acc + calculatedArea;
    }, 0);
  }, [regions, user?.settings.unit]);

  const totalProjectedEmissionReductions = useMemo(() => {
    return regions.reduce((acc, tile) => {
      return acc + Number(tile.properties?.totalProjectedEmissionReduction || 0);
    }, 0);
  }, [regions]);

  useJoyride(PORTFOLIO_SUMMARY_STEPS(t));

  return (
    <StatsWrapper id="summary-stats">
      <SummaryCard
        title={String(regions.length)}
        subtitle={t(`Total Region${regions.length > 1 ? 's' : ''}`)}
        id="summary-stats-count"
      />
      <SummaryCard
        title={new Intl.NumberFormat('en', {
          maximumFractionDigits: 2
        }).format(totalArea)}
        subtitle={`Total ${String(user?.settings.unit.replace('sq-', ''))}${
          totalArea > 1 && user?.settings.unit !== AreaUnitType.squareKilometre ? 's' : ''
        }${
          user?.settings.unit === AreaUnitType.squareKilometre || user?.settings.unit === AreaUnitType.squareMile
            ? '\u00B2'
            : ''
        }`}
        id="summary-stats-area"
      />
      <SummaryCard
        title={`${new Intl.NumberFormat('en', {
          maximumFractionDigits: 2
        }).format(totalProjectedEmissionReductions)} CO${'\u2082'}`}
        subtitle={t('Total Projected Emission Reductions')}
        id="summary-stats-emission"
      />
    </StatsWrapper>
  );
};

export default SummaryStats;
