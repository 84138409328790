import { ReactElement, ReactNode } from 'react';
import { Details, Text, Wrapper } from './style';

export interface Props {
  children?: ReactNode;
  text?: ReactElement | string;
  details?: any;
  dataTestId?: string;
}

const Message = ({ children, text, details, dataTestId }: Props) => {
  return (
    <Wrapper data-test-id={dataTestId}>
      {children}
      {text ? <Text data-test-id={dataTestId && `${dataTestId}-text`}>{text}</Text> : <></>}
      {details ? <Details data-test-id={dataTestId && `${dataTestId}-details`}>{details}</Details> : <></>}
    </Wrapper>
  );
};

export default Message;
