import styled from 'styled-components';
import { IconWrapper } from '../../../Common/Icon/style';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0px;
  margin-bottom: 50px;
`;

export const TitleAndDetails = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;

  h2 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.silver};
    font-size: 20px;
    line-height: 27px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const UploadButton = styled.button`
  border: 0;
  display: flex;
  align-items: center !important;
  gap: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.silver};
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  ${IconWrapper} {
    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
    ${IconWrapper} {
      transform: translateX(-5px);
    }
  }
`;

export const DocumentList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  padding: 0;
  margin: 0;
`;

export const Document = styled.li`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blackII};
  position: relative;
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const FileNameAndType = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;

  h3 {
    color: ${({ theme }) => theme.colors.silver};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }

  span {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const DocumentActions = styled.button`
  background-color: transparent;
  border: 0;

  && {
    margin-right: 0px;
  }
`;
