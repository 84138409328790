import area from '@turf/area';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import { AreaUnitType, SQ_METER_TO_KILOMETER_RATIO } from '../../../../types/Geo';
import { convertSqKmTo } from '../../../../utils/units';
import ProjectDetailMap from './ProjectDetailMap';
import ProjectDetailRow from './ProjectDetailRow';
import { DetailColumn, SectionWrapper } from './style';

const ProjectDetails = () => {
  const user = useAppSelector((state) => state.userState.user);
  const { selectedPolygon } = useAppSelector((state) => state.regionState);
  const { t } = useTranslation();

  if (!selectedPolygon) {
    return null;
  }

  const calculatedArea = Math.floor(
    convertSqKmTo(area(selectedPolygon?.geometry) / SQ_METER_TO_KILOMETER_RATIO, user?.settings.unit)
  );

  const formatArea = `${new Intl.NumberFormat('en', {
    maximumFractionDigits: 2
  }).format(calculatedArea)} ${String(user?.settings.unit.replace('sq-', ''))}${
    calculatedArea > 1 && user?.settings.unit !== AreaUnitType.squareKilometre ? 's' : ''
  }`;

  return (
    <SectionWrapper>
      <DetailColumn>
        <ProjectDetailRow label={t('Area')} value={formatArea} />
        <ProjectDetailRow label={t('Project Status')} value={selectedPolygon?.properties?.status} />
        <ProjectDetailRow label={t('Country')} value={selectedPolygon?.properties?.country} />
        <ProjectDetailRow label={t('Project Type')} value={selectedPolygon?.properties?.projectType} />
        <ProjectDetailRow label={t('Registry')} value={selectedPolygon?.properties?.registry} />
        <ProjectDetailRow label={t('Standard')} value={selectedPolygon?.properties?.registryStandard} />
      </DetailColumn>
      <DetailColumn>
        <ProjectDetailRow label={t('Methodology')} value={selectedPolygon?.properties?.methodology} />
        <ProjectDetailRow label={t('Co-benefits Certification')} value={selectedPolygon?.properties?.certification} />
        <ProjectDetailRow
          label={t('Sustainable Development Goals')}
          value={selectedPolygon?.properties?.sustainableDevelopmentGoals}
        />
        <ProjectDetailRow
          label={t('Crediting Period')}
          value={
            selectedPolygon?.properties?.creditingPeriodStart && selectedPolygon?.properties?.creditingPeriodEnd
              ? `${new Date(selectedPolygon?.properties?.creditingPeriodStart).toLocaleDateString()} - ${new Date(
                  selectedPolygon?.properties?.creditingPeriodEnd
                ).toLocaleDateString()}`
              : '--'
          }
        />
        <ProjectDetailRow
          label={t('Total Projected Emission Reductions')}
          value={new Intl.NumberFormat('en', {
            maximumFractionDigits: 2
          }).format(selectedPolygon?.properties?.totalProjectedEmissionReduction || 0)}
        />
      </DetailColumn>
      <ProjectDetailMap />
    </SectionWrapper>
  );
};

export default ProjectDetails;
