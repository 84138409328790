import styled from 'styled-components';

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectSx = (hasError: any) => ({
  backgroundColor: 'var(--blackIII)',
  color: 'var(--white)',
  height: '46px',
  borderRadius: '10px',
  fontSize: '14px',
  fontWeight: '500',
  transition: 'var(--transition)',
  border: `1px solid ${hasError ? 'var(--errorRed)' : 'transparent'}`,
  width: '100%',

  '.MuiSelect-select': {
    height: '46px',
    display: 'block',
    boxSizing: 'border-box',
    padding: '12.5px 40px 12.5px 16px !important',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    '&:before': {
      transition: 'var(--transition)',
      content: '""',
      position: 'absolute',
      right: '14px',
      top: 'calc(50% - 9px)',
      width: '18px',
      height: '18px',
      maskImage: 'url(/assets/icons/chevron-down.svg)',
      maskPosition: 'center',
      maskRepeat: 'no-repeat',
      backgroundColor: 'var(--lightBlueTransparent)'
    },

    '&[aria-expanded="true"]:before': {
      transform: 'rotate(180deg)'
    }
  },

  svg: {
    display: 'none'
  },
  fieldset: {
    border: 0,
    display: 'none'
  },
  '&:before, &:after': {
    display: 'none'
  }
});

export const SelectRelativeContainer = styled.div`
  position: relative;
`;

export const ErrorMsg = styled.p`
  color: ${({ theme }) => theme.colors.red};
  opacity: 0.5;
  font-size: 12px;
  padding-left: 14px;
  margin-top: 7px;
`;

export const MenuItemSx = {
  color: '#929BA2',
  height: '40px',
  textAlign: 'center',
  padding: '8px',
  transition: 'var(--transition)',
  justifyContent: 'space-between',
  fontSize: '14px',
  lineHeight: '17px',
  backgroundColor: 'transparent',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: 'transparent'
  }
};
