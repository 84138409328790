import FormLabel from '@mui/material/FormLabel';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'rsuite';
import { EnumProjectType } from '../../../../../types/API/PublicProjects';
import Icon from '../../../../Common/Icon';
import Input from '../../../../Common/Input';
import { LabelSx } from '../../../../Common/Input/style';
import Select from '../../../../Common/Select';
import { projectTypeOptions } from '../../../AdminModals/CreatePublicProjectModal/MetadataStep';
import { IProps } from '../index';
import { DatePickerWrapper, TotalProjectedLabel, TotalProjectedWrapper } from './style';

const Details = ({ submitData, setSubmitData }: IProps) => {
  const { t } = useTranslation();

  if (!submitData) return null;

  return (
    <form>
      <Select
        value={submitData.polygon.properties.projectType || 'empty'}
        onChange={(value: EnumProjectType) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: {
                ...submitData.polygon.properties,
                projectType: value
              }
            }
          })
        }
        label={String(t('Project type (optional)'))}
        options={projectTypeOptions}
        fullWidth
        autoWidth={false}
        additionalPaperSx={{
          ul: {
            padding: 0
          }
        }}
      />
      <Input
        value={submitData?.polygon.properties.registry || ''}
        onChange={(value) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: {
                ...submitData.polygon.properties,
                registry: value
              }
            }
          })
        }
        label={String(t('Registry (optional)'))}
        omitPlaceholder
      />
      <Input
        value={submitData?.polygon.properties.registryStandard || ''}
        onChange={(value) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: {
                ...submitData.polygon.properties,
                registryStandard: value
              }
            }
          })
        }
        label={String(t('Standard (optional)'))}
        omitPlaceholder
      />
      <Input
        value={submitData?.polygon.properties.methodology || ''}
        onChange={(value) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: {
                ...submitData.polygon.properties,
                methodology: value
              }
            }
          })
        }
        label={String(t('Methodology (optional)'))}
        omitPlaceholder
      />
      <Input
        value={submitData?.polygon.properties.certification || ''}
        onChange={(value) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: {
                ...submitData.polygon.properties,
                certification: value
              }
            }
          })
        }
        label={String(t('Certification (optional)'))}
        omitPlaceholder
      />
      <Input
        value={submitData?.polygon.properties.sustainableDevelopmentGoals || ''}
        onChange={(value) =>
          setSubmitData({
            ...submitData,
            polygon: {
              ...submitData.polygon,
              properties: {
                ...submitData.polygon.properties,
                sustainableDevelopmentGoals: value
              }
            }
          })
        }
        label={String(t('Sustainable Development Goals (optional)'))}
        omitPlaceholder
      />
      <DatePickerWrapper>
        <FormLabel htmlFor={'creditingPeriod'} sx={LabelSx}>
          {t('Crediting Period (optional)')}
        </FormLabel>
        <Icon color={'gray'} variant={'CALENDAR'} size={24} />
        <DateRangePicker
          id={'creditingPeriod'}
          name={'creditingPeriod'}
          placeholder={t('Select...')}
          character={'  -  '}
          placement={'topStart'}
          ranges={[
            {
              label: t('Reset'),
              value: [new Date(1), new Date(1)]
            }
          ]}
          value={
            submitData?.polygon.properties.creditingPeriodStart && submitData?.polygon.properties.creditingPeriodEnd
              ? [
                  new Date(submitData?.polygon.properties.creditingPeriodStart),
                  new Date(submitData?.polygon.properties.creditingPeriodEnd)
                ]
              : null
          }
          onOk={(value) => {
            setSubmitData({
              ...submitData,
              polygon: {
                ...submitData.polygon,
                properties: {
                  ...submitData.polygon.properties,
                  creditingPeriodStart: value[0],
                  creditingPeriodEnd: value[1]
                }
              }
            });
          }}
          onShortcutClick={() => {
            setSubmitData({
              ...submitData,
              polygon: {
                ...submitData.polygon,
                properties: {
                  ...submitData.polygon.properties,
                  creditingPeriodStart: undefined,
                  creditingPeriodEnd: undefined
                }
              }
            });
          }}
        />
      </DatePickerWrapper>
      <TotalProjectedWrapper>
        <Input
          value={submitData?.polygon.properties.totalProjectedEmissionReduction || ''}
          inputMode="numeric"
          type="number"
          withReset={false}
          onChange={(value) =>
            setSubmitData({
              ...submitData,
              polygon: {
                ...submitData.polygon,
                properties: {
                  ...submitData.polygon.properties,
                  totalProjectedEmissionReduction: value ? Number(value) : undefined
                }
              }
            })
          }
          label={String(t('Total Projected Emission Reductions (optional)'))}
          omitPlaceholder
        />
        <TotalProjectedLabel>
          CO<small>2</small>
        </TotalProjectedLabel>
      </TotalProjectedWrapper>
    </form>
  );
};

export default Details;
