import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FOLDER_OPTIONS } from '../../../constants/region';
import { useUpdateMultipleRegionsMutation } from '../../../redux/api/regionApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Select from '../../Common/Select';
import Spacer from '../../Common/Spacer';
import { ModalSx } from '../DeleteRegionModal/style';
import { ButtonWrapper, Title } from '../ModalCommon.style';

const ChangeRegionsFolderModal = () => {
  const dispatch = useAppDispatch();
  const { folders } = useAppSelector((state) => state.folderState);
  const [folderId, setFolderId] = useState<string>('empty');
  const { t } = useTranslation();
  const [updateRegions, { isLoading, error, reset }] = useUpdateMultipleRegionsMutation();
  const { regionIdsToEditOrDelete } = useAppSelector((state) => state.regionState);

  const handleChangeFolder = useCallback(async () => {
    const data = await updateRegions({
      body: {
        regionIds: regionIdsToEditOrDelete,
        folderId: folderId === 'empty' ? null : folderId
      }
    });

    if (!('error' in data)) {
      toast.success(t('Regions folder changed successfully'), {
        autoClose: 3000,
        toastId: 'change-regions-folder-toast'
      });

      window.postMessage('regionUpdated', '*');
      dispatch(closeModal());
    }
  }, [folderId, updateRegions, regionIdsToEditOrDelete, t, dispatch]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'change-regions-folder-modal-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <Modal
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="change-regions-folder-modal"
      modalType="changeRegionsFolder"
    >
      <Title data-test-id="change-regions-folder-modal-title">{t('Move to Folder')}</Title>
      <Spacer size={'30px 0 0'} />
      {folders.length > 0 ? (
        <Select
          value={folderId}
          onChange={(value: string) => {
            setFolderId(value);
          }}
          additionalPaperSx={{
            ul: {
              padding: 0
            }
          }}
          optionWidth={'398px'}
          fullWidth
          options={FOLDER_OPTIONS(folders, t)}
          label={String(t('Folder'))}
        />
      ) : null}
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          dataTestId="change-regions-folder-modal-cancel-button"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="purple"
          additionalSx={{
            width: '190px'
          }}
          onClick={handleChangeFolder}
          disabled={isLoading}
          dataTestId="change-regions-folder-modal-delete-button"
        >
          {t('Update')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default ChangeRegionsFolderModal;
