import { Dispatch, SetStateAction, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showGeneralErrorMessage } from '../../../constants/UI';
import { useSendRequestUpgradeMutation } from '../../../redux/api/mailApi';
import { useUpdateUserMutation } from '../../../redux/api/userApi';
import { useAppSelector } from '../../../redux/hooks';
import { IDynamicTemplateBody } from '../../../types/API/Mail';
import { IUserUpdate } from '../../../types/User';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import Textarea from '../../Common/Textarea';
import { InputSx } from '../../Modals/AccountModal/style';

interface Props {
  setSubmitted: Dispatch<SetStateAction<boolean>>;
}

const ContactForm = ({ setSubmitted }: Props) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const { user } = useAppSelector((state) => state.userState);

  const [updateUser, { isLoading, isError, isSuccess }] = useUpdateUserMutation();
  const [sendRequestUpgrade] = useSendRequestUpgradeMutation();

  const handleSetFirstName = (value: string) => {
    setFirstName(value);
  };

  const handleSetLastName = (value: string) => {
    setLastName(value);
  };

  const handleSetCompany = (value: string) => {
    setCompany(value);
  };

  const handleSetMessage = (value: string) => {
    setMessage(value);
  };

  const handleOnSubmit = useCallback(
    async (e: SyntheticEvent) => {
      if (user) {
        e.preventDefault();

        const body: IDynamicTemplateBody = {
          to: process.env.REACT_APP_EMAIL_UPGRADE_TO as string,
          from: process.env.REACT_APP_EMAIL_CONTACT_US_FROM as string,
          dynamicTemplateData: {
            full_name: `${firstName} ${lastName}`,
            cyclops_url: process.env.REACT_APP_APP_URL as string,
            company,
            message,
            email: user.email
          }
        };
        await sendRequestUpgrade(body);

        const updatedUser: IUserUpdate = {
          firstName,
          lastName,
          company
        };
        await updateUser(updatedUser);
      }
    },
    [user, firstName, lastName, company, message, sendRequestUpgrade, updateUser]
  );

  useEffect(() => {
    setFirstName(user?.firstName ? user.firstName : '');
    setLastName(user?.lastName ? user.lastName : '');
    setCompany(user?.company ? user.company : '');
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      setSubmitted(true);
    }
  }, [isSuccess, setSubmitted]);

  useEffect(() => {
    if (isError) {
      showGeneralErrorMessage();
    }
  }, [isError]);

  return (
    <form onSubmit={handleOnSubmit}>
      <Input
        required
        value={firstName}
        variant="black"
        onChange={handleSetFirstName}
        label={String(t('First Name'))}
        width={InputSx.width}
        dataTestId="contact-form-first-name"
      />
      <Input
        required
        value={lastName}
        variant="black"
        onChange={handleSetLastName}
        label={String(t('Last Name'))}
        dataTestId="contact-form-last-name"
        width={InputSx.width}
      />
      <Input
        required
        value={company}
        variant="black"
        onChange={handleSetCompany}
        label={String(t('Company'))}
        dataTestId="contact-form-company"
        width={InputSx.width}
      />
      <Textarea
        value={message}
        required
        variant="black"
        onChange={handleSetMessage}
        dataTestId="contact-form-message"
        label={String(t('How will you use Cyclops?'))}
        minRows={5}
        width={InputSx.width}
      />
      <Button dataTestId="contact-form-submit-btn" disabled={isLoading} variant="green" type="submit" fullWidth>
        {t('Send')}
      </Button>
    </form>
  );
};

export default ContactForm;
