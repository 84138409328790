import { useTranslation } from 'react-i18next';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import AccountContactForm from '../AccountModal/ContactForm';
import { UpgradeProText } from '../AccountModal/style';
import { ContentWrapper, UpgradeModalSx, Wrapper } from './style';

const UpgradeModal = () => {
  const { t } = useTranslation();
  return (
    <Modal
      modalType="upgradeAccount"
      title={String(t('Upgrade to Pro'))}
      additionalSx={UpgradeModalSx}
      dataTestId="upgrade-modal"
    >
      <Wrapper>
        <ContentWrapper>
          <UpgradeProText data-test-id="upgrade-modal-pro-text">
            {t(
              'If you would like to view up-to-date and real time data, reach out to us for a Cyclops Pro Subscription.'
            )}
            <Spacer size="20px 0 0" />
            <b>{t('Much more to come in future releases.')}</b>
          </UpgradeProText>
        </ContentWrapper>
        <AccountContactForm />
      </Wrapper>
    </Modal>
  );
};

export default UpgradeModal;
