import styled from 'styled-components';
import { IconWrapper } from '../../../Common/Icon/style';

export const ActionButton = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.silver};
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  ${IconWrapper} {
    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.purple};
    ${IconWrapper} {
      transform: translateX(-5px);
    }
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const FolderSummary = styled.p`
  color: ${({ theme }) => theme.colors.silver};
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
`;

export const HeaderTopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const NameAndDetails = styled.div`
  display: flex;
  align-items: flex-end;

  h2 {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.silver};
    font-size: 20px;
    line-height: 27px;
    margin-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 550px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;
