import styled from 'styled-components';
import { Wrapper as AdminWrapper } from '../../Admin/style';

export const Wrapper = styled(AdminWrapper)`
  background-color: rgba(31, 42, 52, 0.9);
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const LoginBox = styled.div`
  width: 460px;
  height: 417px;
  border-radius: 10px;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  form {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 30px;

    button {
      margin-top: auto;
    }
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: 40px auto 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Content = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.lightGrey};
  line-height: 24px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.purple};
    font-weight: 700;
  }
`;
