import { useTranslation } from 'react-i18next';
import { useDrawHook } from '../../../../hooks/useDrawHook';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { Subtitle, Title } from '../../ModalCommon.style';
import { ModalSx } from '../style';

const EditRegionInvalidShapeModal = () => {
  const { t } = useTranslation();
  const { startOverFromModal } = useDrawHook();

  return (
    <Modal
      modalType="editRegionInvalidShape"
      withCloseIcon={false}
      additionalSx={ModalSx}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="edit-region-invalid-shape-modal"
    >
      <Title data-test-id="edit-region-invalid-shape-modal-title">{t('This is not a valid shape')}</Title>
      <Subtitle data-test-id="edit-region-invalid-shape-modal-subtitle">
        {t('Your region cannot overlap itself')}
      </Subtitle>
      <Spacer size="32px 0 0" />
      <Button
        variant="purple"
        fullWidth
        dataTestId="edit-region-invalid-shape-modal-button-reset"
        onClick={startOverFromModal}
      >
        {t('Start again')}
      </Button>
    </Modal>
  );
};

export default EditRegionInvalidShapeModal;
