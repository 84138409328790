import { useTranslation } from 'react-i18next';
import { openModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Icon from '../../../Common/Icon';
import Spacer from '../../../Common/Spacer';
import {
  ActionButton,
  ActionButtonsWrapper,
  FolderSummary,
  HeaderTopBar,
  HeaderWrapper,
  NameAndDetails
} from './style';

const FolderHeader = () => {
  const { currentFolder } = useAppSelector((state) => state.folderState);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <HeaderTopBar>
        <NameAndDetails>
          <h2>{currentFolder?.name}</h2>
          <span>
            {currentFolder?.regions?.length} {t('Projects')}
          </span>
        </NameAndDetails>
        <ActionButtonsWrapper>
          <ActionButton onClick={() => dispatch(openModal('editFolder'))}>
            <Icon color="silver" variant="PENCIL" size={24} />
            {t('Edit')}
          </ActionButton>
          <ActionButton onClick={() => dispatch(openModal('deleteFolder'))}>
            <Icon color="silver" variant="TRASH" size={24} />
            {t('Delete')}
          </ActionButton>
        </ActionButtonsWrapper>
      </HeaderTopBar>
      {currentFolder?.summary ? <FolderSummary>{currentFolder?.summary}</FolderSummary> : null}
      <Spacer size={'48px 0 0'} />
    </HeaderWrapper>
  );
};

export default FolderHeader;
