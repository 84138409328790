import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropZone } from '../../../../../../hooks/useDropZone/useDropZone';
import { FileInfoSubtitle, FileInfoTitle, FileInfoWrapper, FileResetButton } from '../../style';

interface Props {
  name: string;
}

const usePDFUploadStep = ({ name }: Props) => {
  const { t } = useTranslation();
  const preparePDFFormData = useCallback(
    (files: FileList) => {
      const data = new FormData();
      data.append('file', files[0], `${name.trim()}.pdf`);
      return data;
    },
    [name]
  );

  const { renderUploadSection, formData, setFormData, filename, setFilename, inputRef } = useDropZone({
    prepareData: preparePDFFormData,
    desiredFilename: `${name.trim()}.pdf`,
    accept: '.pdf'
  });

  const renderContent = () =>
    filename ? (
      <FileInfoWrapper>
        <FileResetButton
          onClick={() => {
            if (inputRef.current) inputRef.current.value = '';
            setFilename('');
          }}
        >
          {t('Reset')}
        </FileResetButton>
        <FileInfoTitle>{filename.split('.')[0]}</FileInfoTitle>
        <FileInfoSubtitle>
          {t('File name:')} {filename.toLocaleLowerCase()}
        </FileInfoSubtitle>
      </FileInfoWrapper>
    ) : (
      renderUploadSection()
    );

  return {
    renderPDFUploadStep: renderContent,
    pdfFormData: formData,
    setPdfFormData: setFormData,
    pdfFilename: filename,
    setPdfFilename: setFilename,
    pdfFileInputRef: inputRef
  };
};

export default usePDFUploadStep;
