import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin-top: auto;
    width: 500px;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.8;
  margin-bottom: 40px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:first-of-type {
    margin-bottom: 20px;
  }
`;

export const SelectLabel = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: 700;
  margin-bottom: 6px;
`;
