import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks';
import { RegionPoint, RegionPointInput, RegionPointInputs, RegionPointsList, Title } from './style';

const EditRegion = () => {
  const { t } = useTranslation();

  const { drawnFeatures } = useAppSelector((state) => state.drawState);

  const [data, setData] = useState(drawnFeatures[0]);

  useEffect(() => {
    if (drawnFeatures) {
      setData(drawnFeatures[0]);
    }
  }, [drawnFeatures]);

  return (
    <>
      <Title data-test-id="edit-region-title">{t('Refine the points of your region before continuing.')}</Title>
      {drawnFeatures.length > 0 ? (
        <RegionPointsList>
          {data.geometry.coordinates[0].map((feature, index) => {
            return (
              <RegionPoint key={`${JSON.stringify(feature)}-${index}`} data-test-id="edit-region-point">
                <p data-test-id="edit-region-point-index">{`Point ${index + 1}`}</p>
                <RegionPointInputs>
                  <RegionPointInput value={feature[0]} readOnly data-test-id="edit-region-point-latitude" />
                  <RegionPointInput value={feature[1]} readOnly data-test-id="edit-region-point-longitude" />
                </RegionPointInputs>
              </RegionPoint>
            );
          })}
        </RegionPointsList>
      ) : null}
    </>
  );
};
export default EditRegion;
