import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeleteAccountMutation } from '../../../redux/api/userApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../ModalCommon.style';
import { ModalSx } from './style';

const RemoveAccountModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.userState);

  const [deleteAccount, { isLoading, error, reset }] = useDeleteAccountMutation();

  const handleDeleteAccount = useCallback(async () => {
    if (user) await deleteAccount({ id: user.id });
  }, [deleteAccount, user]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'remove-account-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <Modal
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="remove-account-modal"
      modalType="deleteAccount"
    >
      <Title data-test-id="remove-account-modal-title">{t('Remove Account')}</Title>
      <Subtitle data-test-id="remove-account-modal-subtitle">
        {t(`Are you sure you want to remove your account? This is irreversible, all of your data will be lost.`)}
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
          dataTestId="remove-account-modal-cancel-button"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="red"
          fullWidth
          onClick={handleDeleteAccount}
          disabled={isLoading}
          dataTestId="remove-account-modal-remove-button"
        >
          {t('Continue')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default RemoveAccountModal;
