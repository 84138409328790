import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyTemplate from '../../components/Portfolio/Folder/EmptyTemplate';
import FloatingActionsBar from '../../components/Portfolio/Folder/FloatingActionsBar';
import FolderActions from '../../components/Portfolio/Folder/FolderActions';
import RegionList from '../../components/Portfolio/RegionList';
import { Title } from '../../components/Portfolio/style';
import { useMenu } from '../../hooks/useMenu';
import { useSorting } from '../../hooks/useSorting';
import { folderApi } from '../../redux/api/folderApi';
import { useGetUserRegionsQuery } from '../../redux/api/regionApi';
import { setCurrentFolder } from '../../redux/features/folder/folder-slice';
import { useAppDispatch } from '../../redux/hooks';
import { IRegionResponse } from '../../types/API/Region';

const UnassignedPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentFolder(null));
  }, [dispatch]);

  const { sorting, sortingDirection, handlePortfolioSorting } = useSorting('createdAt', 'desc');
  const { data } = useGetUserRegionsQuery(
    {
      skipCallback: true,
      filter: 'unassigned',
      sorting,
      sortingDirection
    },
    {
      refetchOnMountOrArgChange: true
    }
  );
  const { menuToShow, menuRef, handleOpenMenu, handleCloseMenu } = useMenu();
  const [selectMode, setSelectMode] = useState(false);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);

  const handleToggleSelectMode = () => {
    if (selectMode) {
      setSelectedRegions([]);
    }
    setSelectMode((wasSelected) => !wasSelected);
  };

  const handleSelectRegion = (regionId: IRegionResponse['id']) => {
    setSelectedRegions((regions) => {
      if (regions.includes(String(regionId))) {
        return regions.filter((region) => region !== regionId);
      }
      return [...regions, String(regionId)];
    });
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'regionUpdated') {
        // should update the folder now
        // invalidateTag Folder by id
        dispatch(folderApi.util.invalidateTags(['Folder', 'Folders']));
        setSelectedRegions([]);
        setSelectMode(false);
      }
    });
  }, [dispatch]);

  if (!data) {
    return <div>{t('No data found')}</div>;
  }

  return (
    <>
      <Title>{t('Unassigned')}</Title>
      {data.length > 0 ? (
        <FolderActions
          setSelectMode={setSelectMode}
          selectMode={selectMode}
          handleToggleSelectMode={handleToggleSelectMode}
          selectedProjects={selectedRegions}
          handleSortBy={handlePortfolioSorting}
        />
      ) : (
        <EmptyTemplate withHeader={false} isFolder={false} isUnassigned folderId="unassigned" />
      )}
      <RegionList
        regions={data}
        selectMode={selectMode}
        selectedRegions={selectedRegions}
        handleSelectRegion={handleSelectRegion}
        menuToShow={menuToShow}
        menuRef={menuRef}
        handleOpenMenu={handleOpenMenu}
        handleCloseMenu={handleCloseMenu}
      />
      <FloatingActionsBar active={selectedRegions.length > 0} ids={selectedRegions} />
    </>
  );
};

export default UnassignedPage;
