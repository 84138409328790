import styled from 'styled-components';

export type TitleSizeVariations = 'small' | 'large';
export type TitlePositionVariations = 'left' | 'center';

export const ModalSX = {
  backgroundColor: 'var(--blackII)',
  padding: '50px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  position: 'relative',
  minWidth: '500px',
  borderRadius: '10px',
  backdropFilter: 'blur(8px)',

  '&::-webkit-scrollbar': {
    display: 'none'
  }
};

export const CloseIcon = styled.button`
  width: 24px;
  height: 24px;
  border: 0;
  padding: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  background-color: transparent;

  &:hover {
    opacity: 0.6;
  }
`;

const sizeVariations = {
  small: '18px',
  large: '30px'
};

export const ModalTitle = styled.h3<{
  titleSize: TitleSizeVariations;
  titlePosition: TitlePositionVariations;
}>`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ titleSize }) => sizeVariations[titleSize]};
  text-align: ${({ titlePosition }) => titlePosition};
  line-height: 36px;
`;
