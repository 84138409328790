import styled from 'styled-components';

export const AccountPlanWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 440px;
  column-gap: 140px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
