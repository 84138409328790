import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import type { TooltipRenderProps } from 'react-joyride';
import { JoyrideStep } from '../../redux/features/joyride/joyride-slice';
import Icon from '../Common/Icon';
import { Button, ButtonWrapper, Content, IconWrapper, Title, Wrapper } from './style';

interface Props extends Omit<TooltipRenderProps, 'step'> {
  step: JoyrideStep;
}

const JoyrideTooltip: ElementType<Props> = ({
  continuous,
  index,
  step,
  primaryProps,
  size,
  backProps,
  closeProps
}: Props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <IconWrapper {...closeProps}>
        <Icon variant="X" color="gray" size={16} />
      </IconWrapper>
      {step.title ? <Title>{step.title}</Title> : null}
      <Content dangerouslySetInnerHTML={{ __html: typeof step.content === 'string' ? step.content : '' }} />
      {step.mediaType === 'video' && step.mediaUrl ? (
        <video controls autoPlay muted>
          <source src={step.mediaUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : null}
      {step.mediaType === 'image' && step.mediaUrl ? (
        <picture>
          <source srcSet={step.mediaUrl} type="image/webp" />
          <img src={step.mediaUrl} alt={step.title} />
        </picture>
      ) : null}
      <ButtonWrapper>
        {index > 0 ? <Button {...backProps}>{`Prev (${index}/${size})`}</Button> : null}
        {continuous ? (
          <Button {...primaryProps}>
            {index + 1 !== size ? `${primaryProps.title} (${index + 1}/${size})` : t('Continue to Cyclops')}
          </Button>
        ) : null}
      </ButtonWrapper>
    </Wrapper>
  );
};
export default JoyrideTooltip;
