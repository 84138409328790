import { styled as muiStyled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 20px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.white};
`;

export const EditUser = styled.span`
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  line-height: 17px;
  transition: ${({ theme }) => theme.transition};
  color: ${({ theme }) => theme.colors.purple};

  &:hover {
    opacity: 0.6;
  }
`;

export const StyledTableCell = muiStyled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isSortable' && prop !== 'isSortedByThis' && prop !== 'sortingDirection'
})<{
  isSortable?: boolean;
  isSortedByThis?: boolean;
  sortingDirection?: 'asc' | 'desc';
}>(({ isSortable, isSortedByThis, sortingDirection }) => {
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'var(--navy)',
      borderBottom: 0,
      position: 'relative',
      cursor: isSortable ? 'pointer' : 'auto',
      color: isSortedByThis ? 'var(--white)' : 'var(--purple)',
      transition: 'all 300ms ease',

      '&:hover': {
        color: isSortable ? (isSortedByThis ? 'var(--white)' : 'rgba(255,255,255,0.6)') : 'var(--purple)'
      },

      div: {
        position: 'absolute',
        right: '5px',
        top: 'calc(50% - 12px)',
        transition: 'all 300ms ease',
        transform: isSortedByThis && sortingDirection === 'desc' ? 'rotate(180deg)' : 'rotate(0)'
      },
      ['&:first-of-type']: {
        borderTopLeftRadius: '8px'
      },
      ['&:last-of-type']: {
        borderTopRightRadius: '8px'
      }
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    },
    [`&.${tableCellClasses.alignCenter}`]: {
      div: {
        margin: '0 auto'
      }
    }
  };
});

export const StyledTableRow = muiStyled(TableRow)(() => {
  return {
    borderBottom: '1px solid var(--blue)',
    cursor: 'pointer',
    transition: 'all 300ms ease',

    '&:first-of-type': {
      borderTop: 0
    },
    '&:last-of-type': {
      borderBottom: 0
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'rgba(255,255,255,0.05)'
    },
    td: {
      color: 'var(--white)',
      border: 0
    },
    th: {
      color: 'var(--white)',
      border: 0
    }
  };
});

export const DropdownContainer = styled.div`
  position: relative;
`;

export const DropdownToggle = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  transition: ${({ theme }) => theme.transition};
  width: 24px;
  height: 14px;
  padding: 0;

  &:hover {
    opacity: 0.6;
  }
`;

export const DropdownMenu = styled.div<{
  active?: boolean;
  position: 'left' | 'right';
}>`
  border-radius: 10px;
  position: absolute;
  top: 25px;
  left: ${({ position }) => (position === 'left' ? '25px' : 'unset')};
  right: ${({ position }) => (position === 'right' ? '45px' : 'unset')};
  z-index: 2;
  padding: ${({ active }) => (active ? '30px' : '0')};
  background-color: rgba(9, 20, 29, 0.8);
  backdrop-filter: blur(5px);
  border: ${({ active, theme }) => (active ? `1px solid ${theme.colors.blue}` : '0')};
  display: flex;
  flex-direction: column;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(-20px)')};
  height: ${({ active }) => (active ? 'auto' : '0')};
  filter: ${({ active }) => (active ? 'none' : 'blur(6px)')};

  button {
    padding: 0;
    white-space: nowrap;
    margin-bottom: 10px;

    &:hover {
      opacity: 0.6;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
