import styled from 'styled-components';

export const UploadModalSx = {
  minWidth: '500px',
  maxWidth: 'auto',
  minHeight: '320px',
  maxHeight: '100vh'
};

export const UploadInformation = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-size: 13px;
  font-style: italic;
  margin-bottom: 20px;
`;

export const UploadItemsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const FooterButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;
