import { configureStore } from '@reduxjs/toolkit';
import { baseApi } from './api';
import DrawSlice from './features/draw/draw-slice';
import FolderSlice from './features/folder/folder-slice';
import JoyrideSlice from './features/joyride/joyride-slice';
import MapSlice from './features/map/map-slice';
import ModalSlice from './features/modal/modal-slice';
import PublicProjectsSlice from './features/public-projects/public-projects-slice';
import RegionSlice from './features/region/region-slice';
import SocketSlice from './features/socket/socket-slice';
import UiSlice from './features/ui/ui-slice';
import AuthSlice from './features/user/user-slice';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    publicProjectsState: PublicProjectsSlice,
    drawState: DrawSlice,
    mapState: MapSlice,
    modalState: ModalSlice,
    regionState: RegionSlice,
    socketState: SocketSlice,
    uiState: UiSlice,
    userState: AuthSlice,
    folderState: FolderSlice,
    joyrideState: JoyrideSlice
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([baseApi.middleware])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
