import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { ForestCoverData, ForestDataType } from '../../../../context/Polygon/types';
import { AreaUnitType } from '../../../../types/Geo';
import { convertUnitToFullName } from '../../../../utils/units';
import DeforestationYoYChart from '../../Components/Charts/DeforestationYoYChart';
import Footer from '../../Components/Footer';
import { globalStyles } from '../../style';

interface Props {
  areaYoY: ForestCoverData[];
  percentageYoY: ForestCoverData[];
  areaUnit: AreaUnitType;
  regionName: string;
}

const DeforestationYoYPage = ({ areaYoY, percentageYoY, areaUnit, regionName }: Props) => {
  const { t } = useTranslation();

  return (
    <Page style={globalStyles.pageWrapper}>
      <View style={globalStyles.chartsWrapper}>
        <Text style={{ ...globalStyles.headlineSmall, ...globalStyles.noMarginTop }}>{t('YoY Tree Cover Loss')}</Text>
        <Text style={globalStyles.headlineTiny}>{convertUnitToFullName(areaUnit)}</Text>
        <DeforestationYoYChart width={550} height={300} data={areaYoY} type={ForestDataType.AREA} />
        <Text style={globalStyles.headlineSmall}>{t('YoY Tree Cover Loss')}</Text>
        <Text style={globalStyles.headlineTiny}>{t('Percentage')}</Text>
        <DeforestationYoYChart width={550} height={300} data={percentageYoY} type={ForestDataType.PERCENTAGE} />
      </View>
      <Footer regionName={regionName} />
    </Page>
  );
};

export default DeforestationYoYPage;
