import styled from 'styled-components';

interface IActive {
  active?: boolean;
}

export const Wrapper = styled.div<IActive>`
  background: ${({ active, theme }) => (active ? theme.colors.green : theme.colors.white)};
  border: 1px solid ${({ active }) => (active ? 'rgba(93, 95, 239, 0.19)' : '#C7D1D1')};
  border-radius: 20px;
  width: 54px;
  height: 28px;
  cursor: pointer;
  position: relative;
`;

export const Switch = styled.div<IActive>`
  height: 26px;
  width: 26px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ active, theme }) => (active ? theme.colors.green : '#C7D1D1')};
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 21px;
  top: 0;
  left: -1px;
  position: absolute;
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) => (active ? 'translateX(27px)' : 'translateX(0)')};
`;
