import { ReactNode } from 'react';
import Icon from '../Icon';
import { Wrapper } from './style';

interface Props {
  children?: ReactNode;
  onClose?: () => void;
}

const Hint = ({ children, onClose }: Props) => (
  <Wrapper>
    <Icon variant="LIGHTBULB" color="black" size={24} dataTestId="hint-bulb" />
    {children}
    <div onClick={onClose} data-test-id="hint-close">
      <Icon variant="X" color="black" size={16} />
    </div>
  </Wrapper>
);

export default Hint;
