import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import CopyPasteInput from '../../Common/CopyPasteInput';
import Icon from '../../Common/Icon';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { IconCircleWrapper } from '../../Layout/AuthLayout/style';
import { Subtitle, Title } from '../ModalCommon.style';
import { ModalSx } from './style';

const ApiKeyModal = () => {
  const { apiKey } = useAppSelector((state) => state.userState);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Modal
      additionalSx={ModalSx}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="api-key-modal"
      modalType="apiKey"
    >
      <IconCircleWrapper data-test-id="api-key-modal-icon">
        <Icon color="gray" variant="KEY" size={24} />
      </IconCircleWrapper>
      <Title data-test-id="api-key-modal-title">{t('Your API key')}</Title>
      <Subtitle data-test-id="api-key-modal-subtitle">
        {t('Please copy your unique API key and save it in a safe place. You will not be able to retrieve it later.')}
      </Subtitle>
      <Spacer size="30px 0 0" />
      <CopyPasteInput
        variant="outline-darkGrey"
        dataTestId="api-key-modal-input"
        fullWidth
        value={apiKey || ''}
        visible={false}
        label={String(t('Click to copy'))}
      />
      <Button
        variant="purple"
        fullWidth
        onClick={() => dispatch(closeModal())}
        dataTestId="api-key-modal-button"
        additionalSx={{
          width: '190px',
          marginTop: '30px',
          alignSelf: 'center'
        }}
      >
        {t('Done')}
      </Button>
    </Modal>
  );
};

export default ApiKeyModal;
