import truncate from '@turf/truncate';
import { t } from 'i18next';

import { LngLat, MapLayerMouseEvent, MapMouseEvent } from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import { toast, ToastOptions } from 'react-toastify';
import {
  INITIAL_MAP_VIEW_STATE,
  MAPBOX_MAP_STYLES,
  PUBLIC_SOURCE_NAME,
  PUBLIC_TILE_LAYERS,
  USER_SOURCE_NAME,
  USER_TILE_LAYERS
} from '../../constants/map';
import { useMapContext } from '../../context/Map';
import { usePolygonContext } from '../../context/Polygon';
import { useGetSplitPolygonDataMutation } from '../../redux/api/biomassApi';
import {
  onDrawCreate,
  onDrawUpdate,
  resetDrawSliceState,
  setDrawLngLat,
  setIsDrawing,
  setNumberOfPoints
} from '../../redux/features/draw/draw-slice';
import { resetUIState } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Hint from '../Common/Hint';
import { drawRef } from './DrawControl';
import { prepareCleanedFeature } from './utils';

export const useMapLogic = () => {
  const dispatch = useAppDispatch();
  const [mapStyle, setMapStyle] = useState(MAPBOX_MAP_STYLES.satellite);
  const hoveredPublicFeatureId = useRef<string | null>(null);
  const hoveredUserFeatureId = useRef<string | null>(null);

  const { isDrawing, numberOfPoints } = useAppSelector((state) => state.drawState);
  const { userTiles } = useAppSelector((state) => state.regionState);
  const { mapRoot } = useMap();
  const { handleTileClick } = useMapContext();
  const { resetPolygonData } = usePolygonContext();
  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();

  const onDrawModeChange = useCallback(() => {
    if (isDrawing) {
      if (drawRef?.getAll().features.length === 0) {
        dispatch(setIsDrawing(false));
        toast.dismiss('hint-draw-second');
      }
    }
  }, [isDrawing, dispatch]);

  const handleDrawCreate = useCallback(
    (e: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) => {
      const features = [truncate(e.features[0], { precision: 6 })];
      dispatch(onDrawCreate(features));
    },
    [dispatch]
  );

  const handleDrawUpdate = useCallback(
    (e: { features: GeoJSON.Feature<GeoJSON.Polygon>[] }) => {
      const features = [truncate(e.features[0], { precision: 6 })];
      dispatch(onDrawUpdate(features));
    },
    [dispatch]
  );

  const onUserTileClick = async (e: MapMouseEvent) => {
    if (mapRoot) {
      const feature = mapRoot.queryRenderedFeatures(e.point)[0];

      // recover original geometry coordinates
      if (feature.properties && feature.properties.originalGeometry) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
        feature.geometry = JSON.parse(feature.properties.originalGeometry);
      }

      const cleanedFeature: GeoJSON.Feature<GeoJSON.Polygon> | undefined = userTiles.find(
        (el) => JSON.stringify(el.geometry) === feature.properties?.originalGeometry
      );

      if (cleanedFeature) {
        await verifySplitPolygon({
          feature: cleanedFeature,
          omitCache: false,
          withStateSet: true
        });
        handleTileClick(cleanedFeature, USER_SOURCE_NAME);
      }
    }
  };

  const onPublicTileClick = async (e: MapMouseEvent) => {
    if (mapRoot) {
      const feature = mapRoot.queryRenderedFeatures(e.point)[0];
      const cleanedFeature = prepareCleanedFeature(feature);

      if (cleanedFeature) {
        await verifySplitPolygon({
          feature: cleanedFeature,
          omitCache: false,
          withStateSet: true
        });
        handleTileClick(cleanedFeature, PUBLIC_SOURCE_NAME);
      }
    }
  };

  /**
   * Handle click event on the mapbox map
   * @param {MapLayerMouseEvent} e Map layer mouse event
   */
  const handleOnClick = async (e: MapLayerMouseEvent) => {
    // get clicked feature source name
    const feature = mapRoot?.queryRenderedFeatures(e.point)[0];

    if (feature) {
      const sourceName = feature.source;
      if (sourceName === USER_SOURCE_NAME) {
        await onUserTileClick(e);
      } else if (sourceName === PUBLIC_SOURCE_NAME) {
        await onPublicTileClick(e);
      }
    }
  };

  /**
   * Mapbox style loading mechanism
   * With the style change everything is reset to the initial state
   * @param {keyof typeof MAPBOX_MAP_STYLES} style Mapbox style key
   */
  const handleStyleChange = useCallback(
    (style: keyof typeof MAPBOX_MAP_STYLES) => {
      const newStyle = MAPBOX_MAP_STYLES[style];
      setMapStyle(newStyle);
      resetPolygonData();
      mapRoot?.flyTo(INITIAL_MAP_VIEW_STATE);
      dispatch(resetDrawSliceState());
      dispatch(resetUIState());
    },
    [dispatch, mapRoot, resetPolygonData]
  );

  const onUserTileHover = useCallback(
    (e: MapMouseEvent) => {
      const feature = mapRoot?.queryRenderedFeatures(e.point)[0];
      if (feature) {
        if (hoveredUserFeatureId.current) {
          mapRoot?.setFeatureState({ source: USER_SOURCE_NAME, id: hoveredUserFeatureId.current }, { hover: false });
          hoveredUserFeatureId.current = null;
        }
        mapRoot?.setFeatureState({ source: USER_SOURCE_NAME, id: feature.id }, { hover: true });
        if (feature.properties) {
          window.postMessage(
            {
              type: 'hover-saved-region',
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              originalGeometry: feature.properties.originalGeometry
            },
            '*'
          );
        }
        hoveredUserFeatureId.current = String(feature.id);
      }
    },
    [hoveredUserFeatureId, mapRoot]
  );

  const onUserTileBlur = useCallback(() => {
    if (hoveredUserFeatureId.current) {
      mapRoot?.setFeatureState({ source: USER_SOURCE_NAME, id: hoveredUserFeatureId.current }, { hover: false });
      window.postMessage({ type: 'hover-saved-region', originalGeometry: null }, '*');
      hoveredUserFeatureId.current = null;
    }
  }, [mapRoot]);

  const onPublicTileHover = useCallback(
    (e: MapMouseEvent) => {
      const feature = mapRoot?.queryRenderedFeatures(e.point)[0];
      if (feature) {
        if (hoveredPublicFeatureId.current) {
          mapRoot?.setFeatureState(
            {
              source: PUBLIC_SOURCE_NAME,
              id: hoveredPublicFeatureId.current
            },
            { hover: false }
          );
          hoveredPublicFeatureId.current = null;
        }
        mapRoot?.setFeatureState({ source: PUBLIC_SOURCE_NAME, id: feature.id }, { hover: true });
        hoveredPublicFeatureId.current = String(feature.id);
      }
    },
    [hoveredPublicFeatureId, mapRoot]
  );

  const onPublicTileBlur = useCallback(() => {
    if (hoveredPublicFeatureId.current) {
      mapRoot?.setFeatureState(
        {
          source: PUBLIC_SOURCE_NAME,
          id: hoveredPublicFeatureId.current
        },
        { hover: false }
      );
      hoveredPublicFeatureId.current = null;
    }
  }, [mapRoot]);

  /**
  Attach event listeners for mousemove event to update the drawn polygon hover effect on certain map layers
  When in drawing mode, the coordinates popovers are displayed with updated coordinates
  */
  const handleMouseMove = useCallback(
    (e: MapMouseEvent) => {
      if (isDrawing) {
        dispatch(setDrawLngLat({ ...e.lngLat } as LngLat));
      }

      if (!isDrawing && mapRoot && e.point) {
        const feature = mapRoot.queryRenderedFeatures(e.point)[0];
        if (feature) {
          const sourceName = feature.source;
          if (sourceName === USER_SOURCE_NAME) {
            onUserTileHover(e);
          } else if (sourceName === PUBLIC_SOURCE_NAME) {
            onPublicTileHover(e);
          }
        }
      }
    },
    [dispatch, isDrawing, mapRoot, onPublicTileHover, onUserTileHover]
  );

  /**
  Attach event listeners for mouselave event to remove hover effect on certain map layers
  */
  useEffect(() => {
    if (!isDrawing) {
      mapRoot?.on('mouseleave', [USER_TILE_LAYERS.FILLS, USER_TILE_LAYERS.OUTLINE], onUserTileBlur);
      mapRoot?.on('mouseleave', [PUBLIC_TILE_LAYERS.FILLS, PUBLIC_TILE_LAYERS.OUTLINE], onPublicTileBlur);
    } else {
      mapRoot?.off('mouseleave', [USER_TILE_LAYERS.FILLS, USER_TILE_LAYERS.OUTLINE], onUserTileBlur);
      mapRoot?.off('mouseleave', [PUBLIC_TILE_LAYERS.FILLS, PUBLIC_TILE_LAYERS.OUTLINE], onPublicTileBlur);
    }

    return () => {
      mapRoot?.off('mouseleave', [USER_TILE_LAYERS.FILLS, USER_TILE_LAYERS.OUTLINE], onUserTileBlur);
      mapRoot?.off('mouseleave', [PUBLIC_TILE_LAYERS.FILLS, PUBLIC_TILE_LAYERS.OUTLINE], onPublicTileBlur);
    };
  }, [isDrawing, mapRoot, onPublicTileBlur, onUserTileBlur]);

  /**
   * Listen for the number of points during the drawing process
   * Use listener of window.postMessage to get the number of points
   */
  useEffect(() => {
    window.addEventListener('message', (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (event.data.type === 'numberOfPoints') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        dispatch(setNumberOfPoints(+event.data.numberOfPoints + 1));
      }
    });
  }, [dispatch]);

  /**
   * Display hints for drawing for certain number of points
   */
  useEffect(() => {
    const toastOptions: ToastOptions = {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: 'hint-draw-first',
      autoClose: false
    };

    if (isDrawing) {
      if (!numberOfPoints) {
        toast(<Hint>{t('Click a point on the map to begin drawing.')}</Hint>, toastOptions);
      } else if (numberOfPoints === 1) {
        toast.dismiss('hint-draw-first');
      } else if (numberOfPoints === 2) {
        toast(<Hint>{t('Finish your region by double-clicking or returning to the starting point.')}</Hint>, {
          ...toastOptions,
          toastId: 'hint-draw-second'
        });
      }
    }
  }, [isDrawing, numberOfPoints]);

  return {
    onDrawModeChange,
    handleDrawCreate,
    handleDrawUpdate,
    handleOnClick,
    mapStyle,
    handleStyleChange,
    handleMouseMove
  };
};
