import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../../../redux/api/authApi';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import LinkButton from '../../Common/LinkButton';
import PasswordInputComponent from '../../Common/PasswordInput';
import Spacer from '../../Common/Spacer';

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loginUser, { isLoading, isSuccess, data, error, reset }] = useLoginMutation();

  const redirectToForgottenPasswordPage = useCallback(() => {
    navigate('/forgot-password');
  }, [navigate]);

  const handleSetEmail = useCallback((value: string) => {
    setEmail(value);
  }, []);

  const handleSetPassword = useCallback((value: string) => {
    setPassword(value);
  }, []);

  const handleOnSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      await loginUser({ email, password });
    },
    [email, loginUser, password]
  );

  useEffect(() => {
    if (isSuccess && data) {
      const apiKey = localStorage.getItem('apiKey');

      if (data.user?.role === 'admin' && location.pathname.includes('admin')) {
        navigate('/admin');
        return;
      }

      if (apiKey) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const parsedApiKey: {
          key: string;
          userId: string;
        } = JSON.parse(apiKey);

        if (data.user?.id === parsedApiKey.userId) {
          navigate(`/${parsedApiKey.key}`);
          localStorage.removeItem('apiKey');
        } else {
          localStorage.removeItem('apiKey');
          navigate('/');
        }
      } else {
        navigate('/');
      }
    }
  }, [data, isSuccess, location.pathname, navigate]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'login-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <form onSubmit={handleOnSubmit}>
      <Input
        value={email}
        onChange={handleSetEmail}
        type="email"
        label={String(t('Email address'))}
        required
        dataTestId="login-form-email"
        omitPlaceholder
        fullWidth
      />
      <Spacer size={'20px 0 0'} />
      <PasswordInputComponent
        value={password}
        onChange={handleSetPassword}
        label={String(t('Password'))}
        dataTestId="login-form-password"
        required
        omitPlaceholder
        fullWidth
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '30px'
        }}
      >
        <LinkButton
          text={t('Forgot Password?')}
          onClick={redirectToForgottenPasswordPage}
          dataTestId="auth-forgot-password-btn"
          additionalSx={{
            margin: 0
          }}
        />
        <Button
          dataTestId="login-form-submit-btn"
          disabled={isLoading}
          variant="purple"
          type="submit"
          additionalSx={{
            width: '190px',
            color: 'var(--blackI)'
          }}
        >
          {t('Log in')}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
