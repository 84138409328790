import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 30px;
`;

export const Container = styled.div`
  width: 1000px;
  margin: 0 auto;
`;

export const AdminHeader = styled.header`
  display: flex;
  justify-content: space-between;
  > button {
    padding-top: 0;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 60px;
`;
