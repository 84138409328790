import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.blackIV};
`;

export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.colors.silver};
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const CardSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.silver};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
`;
