import styled from 'styled-components';

export const DropZoneContainer = styled.div<{ active?: boolean }>`
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  position: fixed;
  background: rgba(22, 30, 37, 0.9);
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: ${({ theme }) => theme.transition};
`;

export const DropZoneBorder = styled.div`
  width: 80%;
  height: 36%;
  border: 3px dashed ${({ theme }) => theme.colors.purple};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
`;

export const DropZoneTitle = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const DropZoneFormats = styled.p`
  color: ${({ theme }) => theme.colors.purple};
  font-size: 24px;
  font-weight: 600;
`;
