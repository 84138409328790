import { LogoVariants } from '../../../constants/UI';

interface Props {
  dataTestId?: string;
}

const Logo = ({ dataTestId }: Props) => {
  return <img data-test-id={dataTestId} src={`/assets/${LogoVariants.white}`} alt="Cyclops" />;
};

export default Logo;
