export enum EnumProjectType {
  'REDD+' = 'REDD+',
  'ARR' = 'ARR',
  'IFM' = 'IFM'
}

export enum ContinentType {
  'AFRICA' = 'Africa',
  'ASIA' = 'Asia',
  'AUSTRALIA' = 'Australia',
  'OCEANIA' = 'Oceania',
  'ANTARCTICA' = 'Antarctica',
  'EUROPE' = 'Europe',
  'NORTH_AMERICA' = 'North America',
  'SOUTH_AMERICA' = 'South America'
}

export type IRegionPolygonProperties = GeoJSON.GeoJsonProperties & {
  pdfUrl: string;
  projectType: EnumProjectType;
  continent?: ContinentType;
  country?: string;
};

export interface PublicProject {
  id: string;
  type: string;
  properties: IRegionPolygonProperties;
  geometry: GeoJSON.Feature<GeoJSON.Polygon>;
  createdAt: Date;
  updatedAt: Date;
  hasBiomassData: boolean;
  name: string;
}

export interface UpdatePublicProjectDto {
  hasBiomassData?: boolean;
  properties?: IRegionPolygonProperties;
  name?: string;
}

export interface CreatePublicProjectDto {
  polygon: GeoJSON.Feature<GeoJSON.Polygon>;
  hasBiomassData?: boolean;
  properties: IRegionPolygonProperties;
  name: string;
}
