import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import AuthLayout from '../components/Layout/AuthLayout';
import { Subtitle, Title } from '../components/Layout/AuthLayout/style';
import { useActivateAccountMutation } from '../redux/api/authApi';

const ActivateAccount = () => {
  const { t } = useTranslation();
  const { activationToken } = useParams();
  const [activateAccount, { isSuccess, isLoading, isError }] = useActivateAccountMutation();
  const navigate = useNavigate();

  const handleActivate = useCallback(async () => {
    if (!activationToken || isLoading) return;
    await activateAccount({
      activationToken
    });
  }, [activateAccount, activationToken, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      void handleActivate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigate('/login?activationSuccess=true');
    }

    if (isError) {
      navigate('/login?activationSuccess=false');
    }
  }, [isError, isSuccess, navigate]);

  return (
    <AuthLayout>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '30px'
        }}
      >
        <CircularProgress
          data-test-id="activation-page-loader"
          color="success"
          size={50}
          sx={{
            color: 'var(--green)'
          }}
        />
      </div>
      <Title data-test-id="activation-page-title">{t('Your account is being activated')}</Title>
      <Subtitle data-test-id="activation-page-subtitle">
        {t('Please do not refresh this page. We will redirect you to the login page once your account is activated.')}
      </Subtitle>
    </AuthLayout>
  );
};

export default ActivateAccount;
