import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import Icon from '../components/Common/Icon';
import AuthLayout from '../components/Layout/AuthLayout';
import { IconCircleWrapper, TopBanner } from '../components/Layout/AuthLayout/style';

const LoginPage = () => {
  const route = useLocation();
  const { t } = useTranslation();
  const toastId = useRef<Id | null>(null);
  const successMessage = useRef('');

  useEffect(() => {
    const params = new URLSearchParams(route.search);
    const activationSuccess = params.get('activationSuccess');
    const resetSuccess = params.get('resetSuccess');

    if (resetSuccess) {
      successMessage.current = t('Password updated. Please log in to continue.');
    }

    if (activationSuccess) {
      if (activationSuccess === 'true') {
        successMessage.current = t('Account activated successfully. Please log in to continue.');
      } else if (!toastId.current && activationSuccess === 'false') {
        toastId.current = toast.error(t('There was an error activating your account, please contact support'));
      }
    }
  }, [route.search, t]);

  return (
    <AuthLayout withMessage={successMessage.current !== ''}>
      {successMessage.current !== '' ? (
        <TopBanner>
          <IconCircleWrapper>
            <Icon color={'gray'} variant="CHECK" size={24} />
          </IconCircleWrapper>
          <p>{successMessage.current}</p>
        </TopBanner>
      ) : null}
    </AuthLayout>
  );
};

export default LoginPage;
