import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_SHAPEFILE_EXTENSIONS } from '../../../../../../constants/region';
import { useDropZone } from '../../../../../../hooks/useDropZone/useDropZone';
import { useExtractPolygonsFromFileMutation } from '../../../../../../redux/api/regionApi';
import { IItem } from '../../../../UploadModal';
import { FileInfoSubtitle, FileInfoTitle, FileInfoWrapper, FileResetButton } from '../../style';

interface Props {
  setName: (name: string) => void;
}

const useRegionUploadStep = ({ setName }: Props) => {
  const [regionToSave, setRegionToSave] = useState<IItem | undefined>(undefined);
  const [extractPolygonsFromFile, { data, isSuccess: isExtracted, reset }] = useExtractPolygonsFromFileMutation();
  const { t } = useTranslation();

  const prepareRegionData = useCallback(
    (files: FileList) => {
      const formData = new FormData();
      formData.append('file', files[0]);
      setName(files[0].name.split('.')[0]);
      return formData;
    },
    [setName]
  );

  const { renderUploadSection, filename, setFilename, inputRef, formData, setFormData } = useDropZone({
    prepareData: prepareRegionData,
    accept: SUPPORTED_SHAPEFILE_EXTENSIONS
  });

  const createElementsToRender = useCallback(
    (polygons: GeoJSON.Feature<GeoJSON.Polygon>[], elementName: string): IItem[] =>
      polygons.map((polygon) => {
        return {
          data: polygon,
          name: '',
          filename: elementName
        };
      }),
    []
  );

  useEffect(() => {
    if (formData && !regionToSave) {
      void extractPolygonsFromFile(formData);
    }
  }, [extractPolygonsFromFile, formData, regionToSave]);

  useEffect(() => {
    if (isExtracted && data) {
      const polygonsToSave = createElementsToRender(data.polygons, filename);
      setRegionToSave(polygonsToSave[0]);
      if (inputRef.current) inputRef.current.value = '';
      reset();
    }
  }, [createElementsToRender, data, filename, inputRef, isExtracted, reset]);

  const renderContent = () =>
    filename ? (
      <FileInfoWrapper>
        <FileResetButton
          onClick={() => {
            if (inputRef.current) inputRef.current.value = '';
            setFilename('');
            setFormData(null);
            setRegionToSave(undefined);
          }}
        >
          {t('Reset')}
        </FileResetButton>
        <FileInfoTitle>{filename.split('.')[0]}</FileInfoTitle>
        <FileInfoSubtitle>
          {t('File name:')} {filename.toLocaleLowerCase()}
        </FileInfoSubtitle>
      </FileInfoWrapper>
    ) : (
      renderUploadSection()
    );

  return {
    renderRegionUploadStep: renderContent,
    regionFilename: filename,
    setRegionFilename: setFilename,
    regionToSave
  };
};

export default useRegionUploadStep;
