import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VALIDATION_ERRORS } from '../../../../constants/validation';
import { useGetSplitPolygonDataMutation } from '../../../../redux/api/biomassApi';
import { useCreatePublicProjectMutation, useUploadPDFFileMutation } from '../../../../redux/api/publicProjectsApi';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { setProjectToEditOrDelete } from '../../../../redux/features/public-projects/public-projects-slice';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  ContinentType,
  CreatePublicProjectDto,
  EnumProjectType,
  IRegionPolygonProperties
} from '../../../../types/API/PublicProjects';
import {
  validateLetterSpaceCertainCharacters,
  validateLettersSpace,
  validateLettersSpacePlus
} from '../../../../utils/validation';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';
import MetadataStep, { IErrors } from './MetadataStep';
import { StepCircle, StepWrapper } from './style';
import usePDFUploadStep from './UploadStep/PDF';
import useRegionUploadStep from './UploadStep/Region';

const CreatePublicProjectModal = () => {
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [errors, setErrors] = useState<IErrors>({});

  const { pdfFilename, setPdfFilename, pdfFormData, renderPDFUploadStep } = usePDFUploadStep({
    name
  });

  const { regionToSave, renderRegionUploadStep } = useRegionUploadStep({
    setName
  });

  const [uploadPDFFile, { error: PDFError, reset: resetPDF }] = useUploadPDFFileMutation();
  const [createPublicProject, { error, reset }] = useCreatePublicProjectMutation();

  const [metadata, setMetadata] = useState<IRegionPolygonProperties>({
    continent: ContinentType.AFRICA,
    country: '',
    pdfUrl: '',
    projectType: EnumProjectType.ARR
  });

  const checkBiomassForRegion = useCallback(async () => {
    if (regionToSave) {
      const splitData = await verifySplitPolygon({
        feature: regionToSave.data,
        omitCache: true,
        withStateSet: false
      }).unwrap();

      return splitData.drawnDataOnTile.features.length > 0;
    }
  }, [regionToSave, verifySplitPolygon]);

  // params are fields (keyof IErrors) and validation function for each one of them (same length as keyof IErrors)
  const validateFields = useCallback(() => {
    const isNameCorrect = validateLetterSpaceCertainCharacters(name.trim());
    const isCountryCorrect = metadata.country ? validateLettersSpace(metadata.country.trim()) : false;
    const isContinentCorrect = metadata.continent ? validateLettersSpace(metadata.continent.trim()) : false;
    const isProjectTypeCorrect = metadata.projectType ? validateLettersSpacePlus(metadata.projectType.trim()) : false;

    // go through all incorrect fields and set errors
    const incorrectFields: (keyof IErrors)[] = [];

    if (!isNameCorrect) incorrectFields.push('name');
    if (!isCountryCorrect) incorrectFields.push('country');
    if (!isContinentCorrect) incorrectFields.push('continent');
    if (!isProjectTypeCorrect) incorrectFields.push('projectType');

    const newErrors: IErrors = {
      name: '',
      country: '',
      continent: '',
      projectType: ''
    };

    incorrectFields.forEach((field) => {
      newErrors[field] =
        field === 'name' ? VALIDATION_ERRORS(t).LETTERS_SPACES_AND_SPECIAL : VALIDATION_ERRORS(t).LETTERS_AND_SPACES;
    });

    if (newErrors.name || newErrors.country || newErrors.continent || newErrors.projectType) {
      setErrors(newErrors);
      return false;
    }

    return true;
  }, [metadata.continent, metadata.country, metadata.projectType, name, t]);

  const onSubmit = useCallback(async () => {
    if (regionToSave === undefined || !pdfFormData) return;
    setIsSubmitting(true);

    // 1. Upload PDF file to S3
    const pdfData = await uploadPDFFile(pdfFormData);

    // 2. Get the URL of the PDF file and add it to the metadata
    let pdfUrl = '';
    if (!('error' in pdfData)) {
      pdfUrl = pdfData.data.url;
    } else {
      setIsSubmitting(false);
      return;
    }

    // 3. Check if region has biomass data and set proper flag in metadata
    const hasBiomass = await checkBiomassForRegion();

    // 4. Check if all the fields are correct
    const areCorrect = validateFields();

    if (!areCorrect) {
      toast.error(VALIDATION_ERRORS(t).GENERIC);
      setIsSubmitting(false);
      return;
    }

    // 5. Prepare the project object
    const submitData: CreatePublicProjectDto = {
      polygon: regionToSave.data,
      properties: {
        ...metadata,
        pdfUrl
      },
      hasBiomassData: hasBiomass,
      name
    };

    // 6. Save the project in the database
    const savedProject = await createPublicProject(submitData);

    // 7. Finalize the process
    if (!('error' in savedProject)) {
      toast.success(t('Project created successfully'));
      dispatch(closeModal());
    } else {
      setIsSubmitting(false);
      return;
    }
  }, [
    checkBiomassForRegion,
    createPublicProject,
    dispatch,
    metadata,
    name,
    pdfFormData,
    regionToSave,
    t,
    uploadPDFFile,
    validateFields
  ]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'create-public-project-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  useEffect(() => {
    if (PDFError && 'data' in PDFError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      if ((PDFError.data as any).message === 'File already exists') {
        toast.error(t('Public Project name already exists'));
        resetPDF();
        return;
      }
    }
  }, [PDFError, resetPDF, t]);

  const isSubmitButtonDisabled = useMemo(() => {
    if (step === 1) {
      return regionToSave === undefined;
    } else if (step === 2) {
      return !pdfFilename;
    }
    return (
      isSubmitting ||
      name?.trim().length === 0 ||
      metadata.country?.trim().length === 0 ||
      metadata.projectType?.trim().length === 0 ||
      metadata.continent?.trim().length === 0 ||
      !pdfFilename ||
      regionToSave === undefined
    );
  }, [isSubmitting, metadata.continent, metadata.country, name, metadata.projectType, pdfFilename, regionToSave, step]);

  const generateSubtitle = () => {
    let text = '';
    switch (step) {
      case 1:
        text = t('Let’s start with the shapefile for the project:');
        break;
      case 2:
        text = t('Now, add your PDF file there:');
        break;
      case 3:
        text = t('Final step - add metadata for the project:');
        break;
      default:
        break;
    }

    return text;
  };

  const handleNameChange = useCallback(
    (value: string) => {
      setName(value);
      // rename pdf file to be the same as value
      const pdfFile = pdfFormData?.get('file');
      if (pdfFile && value) {
        pdfFormData?.set('file', pdfFile, `${value}.pdf`);
        setPdfFilename(`${value}.pdf`);
      }
    },
    [pdfFormData, setPdfFilename]
  );

  const generateContent = () => {
    switch (step) {
      case 1:
        return renderRegionUploadStep();
      case 2:
        return renderPDFUploadStep();
      case 3:
        return (
          <MetadataStep
            metadata={metadata}
            setMetadata={setMetadata}
            name={name}
            setName={handleNameChange}
            errors={errors}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        dataTestId="create-public-project-modal"
        modalType="createPublicProject"
        onClose={() => dispatch(setProjectToEditOrDelete(undefined))}
      >
        <Title data-test-id="create-public-project-modal-title">{t('Create Public Project')}</Title>
        <Subtitle data-test-id="create-public-project-modal-subtitle">{generateSubtitle()}</Subtitle>
        <Spacer size={'30px 0 0'} />
        <StepWrapper>
          <StepCircle active={step >= 1}>{1}</StepCircle>
          <StepCircle active={step >= 2}>{2}</StepCircle>
          <StepCircle active={step > 2}>{3}</StepCircle>
        </StepWrapper>
        <Spacer size={'30px 0 0'} />
        {generateContent()}
        <Spacer size={'20px 0 0'} />
        <ButtonWrapper>
          <Button
            variant="text-only-purple"
            fullWidth
            dataTestId="create-public-project-modal-cancel-prev-button"
            additionalSx={{
              padding: 0,
              justifyContent: 'flex-start',
              fontSize: '14px',
              height: 'auto'
            }}
            disabled={isSubmitting}
            onClick={
              step === 1
                ? () => {
                    dispatch(closeModal());
                  }
                : () => {
                    setStep((prevStep) => prevStep - 1);
                  }
            }
          >
            {step === 1 ? t('Cancel') : t('Previous Step')}
          </Button>
          <Button
            variant="purple"
            additionalSx={{
              width: '190px',
              marginLeft: 'auto'
            }}
            dataTestId="create-public-project-modal-submit-next-button"
            disabled={isSubmitButtonDisabled}
            onClick={
              step === 3
                ? onSubmit
                : () => {
                    setStep((prevStep) => prevStep + 1);
                  }
            }
          >
            {step === 3 ? (isSubmitting ? t('Saving..') : t('Submit')) : t('Next Step')}
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default CreatePublicProjectModal;
