import styled from 'styled-components';

export const Wrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: ${({ theme }) => theme.transition};
    background-color: ${({ theme }) => theme.colors.navy};
    opacity: ${({ isLoading }) => (isLoading ? '0.9' : '0')};
    pointer-events: ${({ isLoading }) => (isLoading ? 'all' : 'none')};
    z-index: 1;
  }
`;

export const PoweredBy = styled.a`
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 11px 20px;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: 0.8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  width: 200px;
  height: 41px;
  border: 0;
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    background-image: url(/assets/powered.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    transition: ${({ theme }) => theme.transition};
    cursor: pointer;
  }

  &:before {
    transform: translateY(0) rotateX(0);
    background-size: 100%;
    width: 160px;
    height: 19px;
  }

  &:after {
    width: 100%;
    background-size: 160px 19px;
    border-radius: 8px;
    height: 100%;
    opacity: 0;
    transform: translateY(-50%) rotateX(90deg);
    background-color: ${({ theme }) => theme.colors.black};
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: translateY(0) rotateX(0);
      background-color: ${({ theme }) => theme.colors.blue};
    }
    &:before {
      opacity: 0;
      transform: translateY(50%) rotateX(90deg);
    }
  }
`;
