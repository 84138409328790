import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CarbonAccountingData, ForestCoverData } from '../../../../context/Polygon/types';
import { TableHeader } from '../../types';
import TableGrid from '../TableGrid/TableGrid';

type CustomTableProps = {
  carbonSequesteredData: CarbonAccountingData[];
  carbonSequesteredDataArea: CarbonAccountingData[];
  forestCoverData: ForestCoverData[];
  areaUnit: string;
};

const Table = ({ carbonSequesteredData, carbonSequesteredDataArea, forestCoverData, areaUnit }: CustomTableProps) => {
  const { t } = useTranslation();

  const round = useCallback((value: number) => {
    return Math.round(value * 100) / 100;
  }, []);

  const formatData = useCallback(() => {
    const headers: TableHeader = [];
    const data: Array<Array<string | number>> = [];

    headers.push({
      title: t('Timestamp')
    });
    headers.push({
      title: t('Mean Biomass'),
      subtitle: t(`(tonne/${areaUnit})`)
    });
    headers.push({ title: t('Total Biomass'), subtitle: t('(megatons)') });
    if (forestCoverData) {
      headers.push({
        title: t('Forested Area'),
        subtitle: `(${areaUnit})`
      });
      headers.push({
        title: t('Non-Forested Area'),
        subtitle: `(${areaUnit})`,
        smallerTitle: true
      });
    }

    carbonSequesteredData.map((item: CarbonAccountingData, index: number) => {
      const lineData = [];
      lineData.push(item.name);
      lineData.push(round(carbonSequesteredDataArea[index].carbon));
      lineData.push(round(item.carbon));
      if (forestCoverData) {
        const { forest, deforestation } = forestCoverData[index];

        lineData.push(Number(forest) || 0);
        lineData.push(Number(deforestation) || 0);
      }
      data.push(lineData);
    });

    return { headers, data };
  }, [t, areaUnit, carbonSequesteredData, round, carbonSequesteredDataArea, forestCoverData]);

  return <TableGrid {...formatData()} />;
};

export default Table;
