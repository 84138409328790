import { useAppSelector } from '../../../redux/hooks';
import BarChartComponent from '../../Chart/BarChart';

const CarbonAccounting = () => {
  const { splitDrawnPolygon } = useAppSelector((state) => state.drawState);
  const { carbonAccountingChartData } = useAppSelector((state) => state.regionState);

  return (
    <BarChartComponent
      data={carbonAccountingChartData}
      dataTestId="carbon-emissions-chart"
      isFromSplitData={!!splitDrawnPolygon?.drawnDataOutsideTile?.features.length}
    />
  );
};

export default CarbonAccounting;
