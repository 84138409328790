import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../../redux/api/authApi';
import { userApi } from '../../../redux/api/userApi';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import AdminLogo from '../../Admin/AdminLogo';
import Button from '../../Common/Button';
import LoginForm from '../../Forms/LoginForm';
import { Content, ContentWrapper, LoginBox, Title, Wrapper } from './style';

const Layout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAppSelector((state) => state.userState);
  const [logout] = useLogoutMutation();

  const handleGetUser = useCallback(async () => {
    await dispatch(userApi.endpoints?.getFromCookie.initiate());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && !user) {
      void handleGetUser();
      return;
    }

    if (isAuthenticated && user?.role === 'admin') {
      navigate('/admin/users');
    }
  }, [handleGetUser, isAuthenticated, navigate, user, user?.role]);

  const handleLogout = useCallback(async () => {
    await logout({ isAdmin: true });
  }, [logout]);

  return (
    <Wrapper>
      <LoginBox>
        <AdminLogo align="center" />
        {isAuthenticated && user?.role !== 'admin' ? (
          <ContentWrapper>
            <div>
              <Title data-test-id="admin-layout-forbidden-title">{t('Access forbidden')}</Title>
              <Content data-test-id="admin-layout-forbidden-content">
                {t('Looks like you have logged into the account with no access to the admin panel')}
                <br />
                <br />
                {t('Try logging into another one or contact us at:')}
                <br />
                <a href={`mailto:${process.env.REACT_APP_EMAIL_SUPPORT_PAGE as string}`}>
                  {process.env.REACT_APP_EMAIL_SUPPORT_PAGE as string}
                </a>
              </Content>
            </div>
            <Button dataTestId="admin-layout-forbidden-button" variant="red" onClick={handleLogout} fullWidth>
              {t('Logout')}
            </Button>
          </ContentWrapper>
        ) : (
          <LoginForm />
        )}
      </LoginBox>
    </Wrapper>
  );
};

export default Layout;
