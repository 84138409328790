import { tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

export const TooltipSX = {
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(5, 6, 8, 0.9)',
    borderRadius: '8px',
    color: 'white',
    padding: '16px',
    fontSize: '12px'
  }
};
export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TooltipText = styled.p`
  font-size: 12px;
  color: #d6ddea;
  letter-spacing: -0.24px;
  margin: 0;
  padding: 0;

  ul {
    padding: 16px 0 16px 16px;
  }
`;

export const TooltipTitle = styled(TooltipText)`
  line-height: 20px;
  font-weight: 700;
`;

export const TooltipIconContainer = styled.div`
  margin-left: 20px;
`;
