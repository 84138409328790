import styled, { css } from 'styled-components';

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.lightBlueTransparent};
  font-size: 14px;
  line-height: 22px;
`;

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
`;

const NotificationHoverCss = css`
  &:hover {
    padding-left: 16px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    border-right: 3px solid ${({ theme }) => theme.colors.green};
    transform: translateX(5px);
  }
`;

export const Notification = styled.div<{ wasOpened?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: ${({ theme }) => theme.transition};
  ${({ wasOpened }) => wasOpened && 'opacity: 0.3; filter: grayscale(1);'};
  padding: 12px 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 0;
  ${({ wasOpened }) => !wasOpened && NotificationHoverCss}
`;

const CommonTextCss = css`
  font-size: 14px;
  line-height: 17.5px;
`;

export const NotificationDate = styled.p`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.lightBlueTransparent};
`;

export const NotificationMessage = styled.p`
  ${CommonTextCss};
  line-height: 18px;
  color: ${({ theme }) => theme.colors.white};
`;
