import styled from 'styled-components';

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  border-radius: 8px;
  padding: 101px 250px;
`;

export const EmptyParagraph = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  font-size: 16px;
  line-height: 21px;
  text-align: center;
`;
