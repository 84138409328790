import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  fontFamily: `'Manrope', sans-serif`,
  letterSpacing: '-0.02em',
  transition: 'all 300ms ease',
  borderRadius: '8px',
  colors: {
    black: '#09141D',
    blue: '#1F2A34',
    error: '#EF3352',
    green: '#58DFAF',
    green2: '#438270',
    grey: '#6B7277',
    graphite: '#364046',
    lightGrey: '#929BA2',
    navy: '#161E25',
    purple: '#878BEB',
    white: '#fff',
    red: '#F1493F',
    errorRed: 'rgba(241, 73, 63, 0.3)',
    lightGraphBg: '#F8FAFE',
    darkGrey: '#2D353B',
    gold: '#FFD700',
    newGrey: '#111A1B',
    lightBlue: '#81A7CB',
    darkBlue: '#3C6B90',
    lightBlueTransparent: 'rgba(208, 247, 255, 0.75)',
    blackI: '#030818',
    blackII: '#121624',
    blackIII: '#1E222F',
    blackIV: '#303440',
    darkGray: '#565B69',
    cyclopsRed: '#EF3352',
    silver: '#ECECEC',
    gray: '#8E94A2',
    blueNavy: '#14A2F1'
  },
  breakpoints: {
    mobile: 'only screen and (max-width: 480px)',
    tablet: 'only screen and (min-width: 481px) and (max-width: 1024px)',
    laptop: 'only screen and (min-width: 1025px) and (max-width: 1280px)',
    desktop: 'only screen and (min-width: 1281px) and (max-width: 1920px)',
    extraLarge: 'only screen and (min-width: 1921px)'
  }
};

export const LightTheme: DefaultTheme = {
  ...theme
};

export const DarkTheme: DefaultTheme = {
  ...theme
};
