import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blackII};
  border-radius: 8px;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  position: relative;

  video,
  img {
    max-height: 400px;
    max-width: 400px;
    border-radius: 8px;
    margin-top: 20px;
  }
`;

export const IconWrapper = styled.button`
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 20px;
  right: 32px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Title = styled.h2`
  margin-top: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  font-size: 19px;
  line-height: 24px;
  margin-bottom: 12px;
`;

export const Content = styled.div`
  font-size: 15px;
  max-width: 410px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  align-self: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 28px;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blackI};
  font-size: 14px;
  line-height: 16px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;
