const TermsOfUse = [
  {
    title: 'CYCLOPS MRV Inc. – Terms of Use',
    content: [
      `The “Cyclops” web application and associated websites, mobile apps, and other services (collectively, “Services”) are provided and maintained by CYCLOPS MRV Inc. and its affiliates (collectively, “CYCLOPS”).  The use of the Services is governed by these Terms of Use.  You must agree to the Terms of Use to continue using the Services, so please read them carefully.`,
      `CYCLOPS reserves the right to amend these Terms of Use at any time.  If CYCLOPS does so, it will notify you at the email address provided to CYCLOPS.  The current version of the Terms of Use will govern the Services and will always be available on the CYCLOPS website.  By continuing to access or use the Services after any changes become effective, you agree to be bound by the revised Terms of Use.`
    ]
  },
  {
    title: 'Local Access Restrictions and Laws',
    content: [
      `The Services and all content and functionality incorporated in them are not directed to or intended for use by any person who is a resident of or located in any jurisdiction where the distribution of such content or functionality is contrary to that jurisdiction’s laws, rules, or regulations.  The same applies to any person who is a resident of or located in any jurisdiction where such distribution is prohibited without obtaining necessary licenses or authorizations and such licenses or authorizations have not been obtained.  If any such restriction applies to you, you are not authorized to access the relevant Services.  By using the Services, you agree to abide by and comply with all applicable laws and regulations.`
    ]
  },
  {
    title: 'Disclaimers',
    content: [
      `The content of the Services is prepared for informational purposes only, without regard to any particular user’s objectives, financial situation, particular growing conditions, or other factors.  The content does not constitute a recommendation to enter into a particular transaction or purchase any product.`,
      `You should not construe any material on the Services as business, financial, agricultural, hedging, investing, trading, legal, regulatory, tax, or accounting advice.  Likewise, you should not make the contents of the Services the primary basis for any business or investment decisions you make.  CYCLOPS encourages you to consult business advisors, attorneys, and tax and accounting advisors before entering into any transactions.`,
      `The Services contain assertions about, summaries, compilations, and copies of data relating to past historical events.  This contents, as well as the rest of the Services, are provided on an “as-is” basis.  CYCLOPS makes no warranty, express or implied, concerning the Services.  CYCLOPS expressly disclaims any implied warranty of merchantability or fitness for a particular purpose, including any warranty for the use or the results of the use of the Services with respect to their correctness, quality, accuracy, completeness, reliability, performance, or timeliness.  The content of the Services is believed to be accurate and is obtained from sources which CYCLOPS believes to be reliable.  However, CYCLOPS has not verified this information, and CYCLOPS does not guarantee its accuracy, completeness, fairness, or timeliness, and the Services should not be relied on as such.  By using the Services, you acknowledge and agree that CYCLOPS, its advisers, consultants, shareholders, and other affiliated persons are not responsible for and are not making any representations, guarantees, or warranties regarding the accuracy, completeness, fairness, or reliability of the information contained on the Services.`,
      `Moreover, the Services relate to carbon emission, biomass, and forestation data, which are unpredictable and speculative.  To the extent the Services contain any predictions or forecasts about carbon emission, biomass, and forestation data, CYCLOPS expressly disclaims the reliability of such predictions.  Any reliance on such predictions, or inferences you may draw about future events based on information on the Services, is at your sole risk.  You acknowledge and agree that CYCLOPS, its advisors, consultants, shareholders, and other affiliated persons are not responsible for and are not making any representations, guarantees, or warranties regarding the accuracy, completeness, fairness, or reliability of such predictions or inferences.`
    ]
  },
  {
    title: 'Forward-Looking Statements',
    content: [
      `Certain statements and illustrations contained on the Services are forward-looking.  These statements (including as to plans, objectives, targets, and trends) and illustrations provide current expectations of future events based on assumptions.  This includes any statement that does not directly relate to a historical fact or current fact.`,
      `You can generally identify forward-looking statements by words or phrases such as “anticipate,” “assume,” “believe,” “continue,” “estimate,” “expect,” “foresee,” “intend,” “may increase,” “may fluctuate,” and similar expressions, or by future or conditional verbs such as “will,” “should,” “would,” and “could.”  Forward-looking statements describe known and unknown risks, uncertainties, and other factors, which may materially differ from actual events or circumstances.`
    ]
  },
  {
    title: 'No Liability',
    content: [
      `Neither CYCLOPS nor any of its respective affiliates, directors, employees, advisors, or agents will be liable for any indirect, special, incidental, consequential, punitive, or exemplary loss, liability, or costs arising from use of the Services or of any product described or advertised on the Services, on any theory, including negligence, breach of contract, or otherwise, and regardless of whether the damage was foreseeable.  Neither CYCLOPS nor any third party providing content, nor any of their respective affiliates, directors, employees, advisors, or agents will be liable for any loss of profits, business, or data.  Neither CYCLOPS nor any third party providing content, nor any of their respective affiliates, directors, employees, advisors, or agents will be liable for any damage or loss to any equipment or software caused to any viruses, defects, or malfunctions in connection with the access or use of the Services.`
    ]
  },
  {
    title: 'Content Not to Be Construed as a Solicitation or Offering',
    content: [
      `The content of the Services should not be construed as a solicitation, recommendation, or offer to buy or sell any security, instrument, commodity, or financial or other product.  Any business dealings arising from content on the Services will be conducted in accordance with all applicable laws and regulations.`
    ]
  },
  {
    title: 'Intellectual Property Rights',
    content: [
      `Intellectual Property Rights in the Services and content available through them belong to CYCLOPS or to third parties who have entered into license agreements for such content with CYCLOPS.  You do not acquire, by virtue of your use of the Services, any rights, title, or interest in any of the Intellectual Property Rights to such content.`,
      `For the purpose of these Terms of Use, “Intellectual Property Rights” includes, but is not limited to: (i) copyright, patents, database rights, and rights in trademarks, designs, know-how, and registered or non-registered confidential information; (ii) applications for registration and the right to apply for registration for any of these rights; and (iii) all other intellectual property rights and equivalent or similar forms of protection existing anywhere in the world.`,
      `Unless otherwise stated, content available through the Services may be printed, downloaded, or saved for personal or internal use only.  Such printouts or downloads, however, must retain all applicable copyright or other proprietary notices.  Unless otherwise stated, you may not modify, copy, transmit, distribute, display, reproduce, publish, perform, license, frame, create derivative works from, transfer, or use in any other way for commercial or public purposes in whole or in part any content obtained from the Services without the prior written permission of dClimate.  You may not systematically retrieve content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory (whether through robots, spiders, automatic devices, or manual processes) without written permission from CYCLOPS.
    `
    ]
  },
  {
    title: 'Privacy Policy',
    content: [
      `CYCLOPS has published a Privacy Policy, which is available at the Privacy tab above. The terms of that privacy policy are incorporated by reference into these Terms of Use.`
    ]
  },
  {
    title: 'Governing Law',
    content: [
      `These Terms of Use shall be governed by and interpreted in accordance with the law of New York without regard to conflicts-of-law principles.  By using the Services, you submit irrevocably to the exclusive jurisdiction of the state and federal courts located in New York County, New York.`
    ]
  },
  {
    title: 'Severability',
    content: [
      `If a provision of these Terms of Use is or becomes illegal, invalid, or unenforceable in any country, that will not affect the legality, validity, or enforceability in that country of any other provision or in other jurisdictions of that or any other provisions of these Terms of Use.`
    ]
  },
  {
    title: 'Headings',
    content: [
      `Section headings are inserted for convenience of reference only and do not form a part of these Terms of Use.`
    ]
  }
];

export default TermsOfUse;
