import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeletePublicProjectMutation } from '../../../../redux/api/publicProjectsApi';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { setProjectToEditOrDelete } from '../../../../redux/features/public-projects/public-projects-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';

const DeletePublicProjectModal = () => {
  const dispatch = useAppDispatch();
  const { projectToEditOrDelete } = useAppSelector((state) => state.publicProjectsState);
  const [deleteProject, { isLoading, error, reset }] = useDeletePublicProjectMutation();
  const { t } = useTranslation();

  const handleDeleteProject = useCallback(async () => {
    if (projectToEditOrDelete) {
      const data = await deleteProject({ id: projectToEditOrDelete.id });

      if (!('error' in data)) {
        toast.success(t('Project deleted successfully'), {
          autoClose: 3000,
          toastId: 'delete-public-project-toast'
        });

        dispatch(setProjectToEditOrDelete(undefined));
        dispatch(closeModal());
      }
    }
  }, [deleteProject, dispatch, projectToEditOrDelete, t]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'delete-public-project-modal-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <Modal
      additionalSx={{
        width: '500px'
      }}
      withCloseIcon={false}
      disableBackdropClick
      disableEscapeKeyDown
      dataTestId="delete-public-project-modal"
      modalType="deletePublicProject"
      onClose={() => dispatch(setProjectToEditOrDelete(undefined))}
    >
      <Title data-test-id="delete-public-project-modal-title">{t('Delete Public Project')}</Title>
      <Subtitle data-test-id="delete-public-project-modal-subtitle">
        <>
          {t(`Are you sure you want to delete "${projectToEditOrDelete?.name || 'this project'}" ?`)} <br />
          {t(`This can't be undone`)}
        </>
      </Subtitle>
      <Spacer size={'30px 0 0'} />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          dataTestId="delete-public-project-modal-cancel-button"
          additionalSx={{
            padding: 0,
            justifyContent: 'flex-start',
            fontSize: '14px',
            height: 'auto'
          }}
          onClick={() => {
            dispatch(setProjectToEditOrDelete(undefined));
            dispatch(closeModal());
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          variant="red"
          additionalSx={{
            width: '190px'
          }}
          dataTestId="delete-public-project-modal-delete-button"
          disabled={isLoading}
          onClick={handleDeleteProject}
        >
          {t('Yes, Delete')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DeletePublicProjectModal;
