import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import ReportLogo from '../../Assets/ReportLogo';
import { styles } from './style';

interface Props {
  regionName?: string;
}

const CoverPage = ({ regionName }: Props) => {
  const { t } = useTranslation();
  return (
    <Page style={styles.coverWrapper}>
      <ReportLogo />
      <View>
        {regionName ? <Text style={styles.coverTitle}>{regionName}</Text> : null}
        <Text style={styles.coverTitle}>{t('Region Report')}</Text>
        <Text style={styles.coverDescription}>
          {t('Generated ')}
          {new Date().toLocaleDateString()}
          {t('  |  Powered by dClimate')}
        </Text>
      </View>
    </Page>
  );
};

export default CoverPage;
