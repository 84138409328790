import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../redux/hooks';
import Spacer from '../../../Common/Spacer';
import AccountContactForm from '../ContactForm';
import { AccountSectionTitle, ProText, Subtitle, UpdateProWrapper, UpgradeProText, UpgradeProTitle } from '../style';
import { AccountPlanWrapper, ContentWrapper } from './style';

const AccountPlan = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);

  return (
    <>
      <AccountSectionTitle>{t('Plan')}</AccountSectionTitle>
      <Subtitle data-test-id="account-plan-subtitle">
        {user?.role === 'free' ? t('Cyclops free plan') : t(`Cyclops ${user?.role || 'pro'} plan`)}
      </Subtitle>
      {user?.role !== 'free' ? (
        <>
          <Spacer size="10px 0 0" />
          <ProText data-test-id="account-plan-non-free-text">
            {t('You are already signed up for an annual subscription.')}
          </ProText>
        </>
      ) : (
        <>
          <Spacer size="30px 0 0" />
          <UpdateProWrapper>
            <AccountPlanWrapper>
              <ContentWrapper>
                <UpgradeProTitle data-test-id="account-plan-upgrade-title">{t('Upgrade to Pro')}</UpgradeProTitle>
                <UpgradeProText data-test-id="account-plan-upgrade-text">
                  {t(
                    'If you would like to view up-to-date and real time data, reach out to us for a Cyclops Pro Subscription.'
                  )}
                  <Spacer size="20px 0 0" />
                  <b>{t('Much more to come in future releases.')}</b>
                </UpgradeProText>
              </ContentWrapper>
              <AccountContactForm />
            </AccountPlanWrapper>
          </UpdateProWrapper>
        </>
      )}
    </>
  );
};

export default AccountPlan;
