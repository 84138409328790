import styled from 'styled-components';

export const ReportModalSx = {
  minWidth: '100vw',
  backgroundColor: 'var(--black)',
  maxWidth: '100vw',
  height: '100vh',
  maxHeight: '100vh',
  margin: 0,
  padding: '15px 0 0'
};

export const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
  margin-left: 20px;
`;
