import styled from 'styled-components';
import { IconWrapper } from '../../../../Common/Icon/style';

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;

  ${IconWrapper} {
    position: absolute;
    bottom: 12px;
    right: 14px;
    z-index: 1;
  }

  .rs-input-group.rs-input-group-inside {
    background-color: ${({ theme }) => theme.colors.blackIII};
    border-color: transparent;

    input {
      height: 46px;
      border: 0;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.white};
      font-size: 15px;
      font-weight: 500;
    }
    .rs-input-group-addon {
      display: none;
    }
  }
`;

export const TotalProjectedWrapper = styled.div`
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TotalProjectedLabel = styled.p`
  position: absolute;
  right: 14px;
  bottom: 9px;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.gray};

  small {
    font-size: 11px;
    margin-left: 1px;
  }
`;
