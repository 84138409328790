import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ForestDataType, ForestTemporalType } from '../../../../context/Polygon/types';
import { useAppSelector } from '../../../../redux/hooks';
import { Text, Title, Wrapper } from './style';

const AdditionalInfo = () => {
  const { t } = useTranslation();
  const [quarterContent, setQuarterContent] = useState('');
  const [dataContent, setDataContent] = useState('');
  const [extraDataFormatterContent, setExtraDataFormatterContent] = useState('');
  const [extraDataFormatterAbbreviation, setExtraDataFormatterAbbreviation] = useState('');

  const {
    selectedPolygon,
    forestDataType,
    forestTemporalType,
    carbonDisplayPer,
    forestCoverChartData,
    carbonAccountingChartData
  } = useAppSelector((state) => state.regionState);
  const { dataBoardTab } = useAppSelector((state) => state.uiState);
  const user = useAppSelector((state) => state.userState.user);

  const renderCarbonAccounting = useCallback(() => {
    const quarter =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      carbonAccountingChartData[carbonAccountingChartData.length - 1].name;
    const value =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      carbonAccountingChartData[carbonAccountingChartData.length - 1].carbon;
    const extraDataFormatter =
      carbonDisplayPer === 'data' ? t('megatons') : `ton / ${user?.settings.unit.replace('sq-', '') || 'hectare'}`;

    const data = `${new Intl.NumberFormat('en', {
      maximumFractionDigits: 2
    }).format(+value)}`;

    return {
      quarter,
      data,
      extraDataFormatter
    };
  }, [carbonDisplayPer, carbonAccountingChartData, t, user?.settings.unit]);

  const renderForestCover = useCallback(() => {
    const quarter = forestCoverChartData[forestCoverChartData.length - 1].name;

    const value =
      forestCoverChartData[forestCoverChartData.length - 1][
        forestTemporalType === ForestTemporalType.YoY ? 'deforestation' : 'forest'
      ];
    const data = value
      ? `${new Intl.NumberFormat('en-US', {
          notation: 'compact',
          maximumFractionDigits: 2
        }).format(+value)}`
      : '0';
    const extraDataFormatter =
      forestDataType === ForestDataType.PERCENTAGE ? '%' : user?.settings.unit?.replace('sq-', '') || 'hectare';

    return {
      quarter,
      data,
      extraDataFormatter
    };
  }, [forestCoverChartData, forestTemporalType, forestDataType, user?.settings.unit]);

  const returnData = useCallback(() => {
    // Why number string?
    // These are values from MUI tab component we are using.

    if (dataBoardTab === '0') {
      return renderCarbonAccounting();
    } else {
      return renderForestCover();
    }
  }, [dataBoardTab, renderCarbonAccounting, renderForestCover]);

  useEffect(() => {
    const { data, quarter, extraDataFormatter } = returnData();
    setQuarterContent(quarter);
    setDataContent(data);
    setExtraDataFormatterContent(extraDataFormatter);

    if (
      extraDataFormatter &&
      extraDataFormatter !== '%' &&
      extraDataFormatter !== 'megatons' &&
      (user?.settings.unit === 'sq-km' || user?.settings.unit === 'sq-mile')
    ) {
      setExtraDataFormatterAbbreviation('2');
    } else {
      setExtraDataFormatterAbbreviation('');
    }
  }, [returnData, user?.settings.unit]);

  return (
    <Wrapper isSavedRegion={!!selectedPolygon?.userId}>
      <Title>
        {t('Latest biomass ')}
        <strong>{quarterContent}</strong>
      </Title>
      <Text>
        {dataContent}
        <span>
          {extraDataFormatterContent}
          {extraDataFormatterAbbreviation !== '' ? <sup>{extraDataFormatterAbbreviation}</sup> : null}
        </span>
      </Text>
    </Wrapper>
  );
};

export default AdditionalInfo;
