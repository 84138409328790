import { ChangeEvent, DragEvent, useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { SUPPORTED_SHAPEFILE_EXTENSIONS } from '../../constants/region';
import DropZone from './DropZone/index';
import UploadSection from './UploadSection/index';

interface Props {
  accept?: string;
  prepareData: (files: FileList) => Promise<FormData> | FormData | undefined;
  desiredFilename?: string;
}

export const useDropZone = ({ accept = SUPPORTED_SHAPEFILE_EXTENSIONS, prepareData, desiredFilename }: Props) => {
  const [showDropzoneOverlay, setShowDropzoneOverlay] = useState(false);
  const [formData, setFormData] = useState<FormData | null>(null);
  const [filename, setFilename] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = useCallback((e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowDropzoneOverlay(true);
  }, []);

  const handleDragLeave = useCallback((e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowDropzoneOverlay(false);
  }, []);

  const prepareDataAndSetStates = useCallback(
    async (filesData: FileList) => {
      const data = await prepareData(filesData);
      if (data) {
        setFormData(data);
        setFilename(desiredFilename || filesData[0].name);
      }
    },
    [desiredFilename, prepareData]
  );

  const handleDrop = useCallback(
    async (e: DragEvent) => {
      handleDragLeave(e);
      await prepareDataAndSetStates(e.dataTransfer.files);
    },
    [handleDragLeave, prepareDataAndSetStates]
  );

  const handleInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      await prepareDataAndSetStates(event.target.files as FileList);
    },
    [prepareDataAndSetStates]
  );

  const renderUploadSection = useCallback(() => {
    return (
      <>
        <UploadSection
          inputRef={inputRef}
          onChange={handleInputChange}
          handleDragOver={handleDragOver}
          handleDragLeave={handleDragLeave}
          handleDrop={handleDrop}
          accept={accept}
        />
        {createPortal(<DropZone active={showDropzoneOverlay} formats={accept} />, document.body)}
      </>
    );
  }, [accept, handleDragLeave, handleDragOver, handleDrop, handleInputChange, showDropzoneOverlay]);

  return {
    inputRef,
    renderUploadSection,
    filename,
    setFilename,
    formData,
    setFormData,
    showDropZone: showDropzoneOverlay,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleInputChange,
    setShowDropzoneOverlay
  };
};
