import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import { Props } from '../Input';
import { ErrorMsg, InputSX, InputWrapper, LabelSx } from '../Input/style';
import { PasswordInputWrapper, VisibilityToggle } from './style';

const PasswordInputComponent = ({
  variant = 'navy',
  value,
  onChange,
  id,
  name,
  label,
  required = false,
  error,
  noMargin = false,
  dataTestId,
  fullWidth,
  omitPlaceholder,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const hasError = useMemo(() => !!(error && error.length > 0), [error]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(event.currentTarget.value);
    },
    [onChange]
  );

  const handleVisibilityChange = useCallback(() => {
    setShowPassword((wasVisible) => !wasVisible);
  }, []);

  return (
    <InputWrapper noMargin={noMargin}>
      <FormLabel htmlFor={id} sx={LabelSx} data-test-id={dataTestId && `${dataTestId}-label`}>
        {label}
      </FormLabel>
      <PasswordInputWrapper fullWidth={fullWidth}>
        <Input
          value={value}
          onChange={handleOnChange}
          id={id}
          name={name}
          required={required}
          placeholder={!omitPlaceholder ? (required ? String(t('Required')) : String(t('Optional'))) : undefined}
          type={showPassword ? 'text' : 'password'}
          sx={InputSX(variant, hasError, true, fullWidth ? '100%' : undefined)}
          inputProps={{ 'data-test-id': dataTestId }}
          fullWidth={fullWidth}
          {...rest}
        />
        <VisibilityToggle
          onClick={handleVisibilityChange}
          isVisible={showPassword}
          type="button"
          data-test-id={dataTestId && `${dataTestId}-toggle`}
          tabIndex={-1}
        >
          <Icon color="grey" variant={showPassword ? 'EYE_SHOW' : 'EYE_HIDE'} size={20} />
        </VisibilityToggle>
      </PasswordInputWrapper>
      {error ? <ErrorMsg data-test-id={dataTestId && `${dataTestId}-error`}>{error}</ErrorMsg> : null}
    </InputWrapper>
  );
};

export default PasswordInputComponent;
