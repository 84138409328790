import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRemoveFolderMutation } from '../../../../redux/api/folderApi';
import { setCurrentFolder } from '../../../../redux/features/folder/folder-slice';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';

const DeleteFolderModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { currentFolder } = useAppSelector((state) => state.folderState);
  const [deleteFolder, { isLoading, isError, reset }] = useRemoveFolderMutation();

  const handleOnDelete = useCallback(async () => {
    if (!currentFolder) return;
    const data = await deleteFolder({
      id: currentFolder.id
    });

    if (!('error' in data)) {
      toast.success(t('Folder deleted successfully'), {
        autoClose: 3000,
        toastId: 'delete-folder-toast'
      });

      dispatch(closeModal());

      if (location.pathname.includes(currentFolder.id)) {
        navigate('/portfolio/summary');
        dispatch(setCurrentFolder(null));
      }
    }
  }, [currentFolder, deleteFolder, t, location.pathname, dispatch, navigate]);

  useEffect(() => {
    if (isError) {
      toast.error(t('We were unable to delete the folder. Please try again later'), {
        autoClose: 3000,
        toastId: 'delete-folder-error-toast'
      });
      reset();
    }
  }, [isError, reset, t]);

  return (
    <Modal
      withCloseIcon={false}
      dataTestId={'delete-folder-modal'}
      modalType="deleteFolder"
      additionalSx={{
        width: '400px'
      }}
    >
      <Title data-test-id="delete-folder-modal-title">{t('Delete folder')}</Title>
      <Subtitle>
        {t('Are you sure you want to delete')} <strong>{currentFolder?.name}</strong>?
        {t('Your projects in this folder will continue to exist but they will be unassigned.')}
      </Subtitle>
      <Spacer size="30px 0 0" />

      <Spacer size="30px 0 0" />
      <ButtonWrapper>
        <Button
          variant="text-only-purple"
          fullWidth
          onClick={() => dispatch(closeModal())}
          disabled={isLoading}
          dataTestId="delete-folder-modal-cancel-button"
          additionalSx={{
            justifyContent: 'flex-start',
            paddingLeft: 0,
            fontSize: '14px'
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          dataTestId="delete-folder-modal-save-button"
          variant="red"
          fullWidth
          disabled={isLoading}
          onClick={handleOnDelete}
        >
          {t('Yes, Delete')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DeleteFolderModal;
