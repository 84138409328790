import styled from 'styled-components';

export const Wrapper = styled.div<{ active: boolean }>`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(31, 42, 52, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  width: 100vw;
  height: 100vh;
  transition: ${({ theme }) => theme.transition};
  opacity: 0;
  pointer-events: none;
  z-index: 99999999;
  padding: 30px;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    display: flex;
    opacity: ${({ active }) => (active ? 1 : 0)};
    pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 39px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 48px;
`;
