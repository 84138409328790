import styled from 'styled-components';
import { COMMON_ELEMENT_WIDTH } from '../../../constants/UI';

export const Wrapper = styled.div`
  width: ${COMMON_ELEMENT_WIDTH};
  padding: 40px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.h3`
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  margin-top: 9px;
`;

export const Details = styled.h3`
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 10px;
`;
