import { useTranslation } from 'react-i18next';
import Icon from '../../../components/Common/Icon';
import { SUPPORTED_SHAPEFILE_EXTENSIONS } from '../../../constants/region';
import { DropZoneBorder, DropZoneContainer, DropZoneFormats, DropZoneTitle } from './style';

interface Props {
  active: boolean;
  formats?: string;
}

const DropZone = ({ active, formats = `${SUPPORTED_SHAPEFILE_EXTENSIONS.replaceAll(',', ', ')}` }: Props) => {
  const { t } = useTranslation();
  return (
    <DropZoneContainer active={active}>
      <DropZoneBorder>
        <Icon variant="UPLOAD" size={70} color="green" />
        <DropZoneTitle data-test-id="upload-modal-dropzone-title">{t('Drag & Drop your file here')}</DropZoneTitle>
        <DropZoneFormats data-test-id="upload-modal-dropzone-formats">
          {t(`Accepted formats: ${formats}`)}
        </DropZoneFormats>
      </DropZoneBorder>
    </DropZoneContainer>
  );
};

export default DropZone;
