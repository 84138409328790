import { Path, Svg } from '@react-pdf/renderer';

const ReportLogo = () => {
  return (
    <Svg width={176} height={30} viewBox="0 0 176 30">
      <Path
        d="M63.3058 28.3029C68.3174 28.3029 71.2872 26.52 73.9601 23.66L70.322 19.9829C68.2803 21.84 66.4613 23.0286 63.4914 23.0286C59.0367 23.0286 55.9555 19.3143 55.9555 14.8572V14.7829C55.9555 10.3257 59.1109 6.68572 63.4914 6.68572C66.09 6.68572 68.1318 7.80001 70.1364 9.62001L73.7745 5.42287C71.3615 3.04572 68.4288 1.41144 63.5286 1.41144C55.5471 1.41144 49.9787 7.46573 49.9787 14.8572V14.9314C49.9787 22.3972 55.6585 28.3029 63.3058 28.3029Z"
        fill="#09141D"
      />
      <Path d="M78.5366 27.8572H97.2095V22.6572H84.2536V1.85715H78.5366V27.8572Z" fill="#09141D" />
      <Path
        d="M35.5086 27.8572H41.2256V17.4943L51.2116 1.85715H44.7151L38.4042 12.2943L32.2047 1.85715H25.5226L35.5086 17.6057V27.8572Z"
        fill="#09141D"
      />
      <Path
        d="M13.3271 28.3029C18.3387 28.3029 21.3085 26.52 23.9814 23.66L20.3433 19.9829C18.3016 21.84 16.4826 23.0286 13.5127 23.0286C9.05799 23.0286 5.97679 19.3143 5.97679 14.8572V14.7829C5.97679 10.3257 9.13223 6.68572 13.5127 6.68572C16.1113 6.68572 18.1531 7.80001 20.1577 9.62001L23.7958 5.42287C21.3828 3.04572 18.4501 1.41144 13.5499 1.41144C5.56843 1.41144 0 7.46573 0 14.8572V14.9314C0 22.3972 5.6798 28.3029 13.3271 28.3029Z"
        fill="#09141D"
      />
      <Path
        d="M133.002 27.8572H138.719V20.0572H143.063C148.891 20.0572 153.568 16.9372 153.568 10.92V10.8457C153.568 5.5343 149.819 1.85715 143.62 1.85715H133.002V27.8572ZM138.719 14.9686V7.02001H143.137C145.995 7.02001 147.777 8.3943 147.777 10.9572V11.0314C147.777 13.26 146.107 14.9686 143.248 14.9686H138.719Z"
        fill="#09141D"
      />
      <Path
        d="M166.329 28.2286C171.935 28.2286 175.87 25.3314 175.87 20.1686V20.0943C175.87 15.5629 172.9 13.6686 167.628 12.2943C163.136 11.1429 162.023 10.5857 162.023 8.87716V8.80287C162.023 7.54001 163.174 6.53716 165.364 6.53716C167.554 6.53716 169.819 7.50287 172.12 9.10002L175.09 4.79144C172.454 2.6743 169.225 1.48573 165.438 1.48573C160.129 1.48573 156.343 4.60573 156.343 9.32287V9.39716C156.343 14.56 159.721 16.0086 164.955 17.3457C169.299 18.46 170.19 19.2029 170.19 20.6514V20.7257C170.19 22.2486 168.779 23.1772 166.44 23.1772C163.471 23.1772 161.02 21.9514 158.682 20.02L155.303 24.0686C158.422 26.8543 162.394 28.2286 166.329 28.2286Z"
        fill="#09141D"
      />
      <Path
        fillRule="evenodd"
        d="M129.361 15C129.361 23.2843 122.649 30 114.369 30C106.089 30 99.377 23.2843 99.377 15C99.377 6.71573 106.089 0 114.369 0C122.649 0 129.361 6.71573 129.361 15ZM114.376 4.94016C108.82 4.94016 104.316 9.44676 104.316 15.0059C104.316 20.5651 108.82 25.0717 114.376 25.0717C119.933 25.0717 124.437 20.5651 124.437 15.0059H126.409C126.409 21.6552 121.022 27.0454 114.376 27.0454C107.731 27.0454 102.343 21.6552 102.343 15.0059C102.343 8.35673 107.731 2.96648 114.376 2.96648V4.94016ZM114.376 8.88748C110.999 8.88748 108.261 11.6268 108.261 15.0059C108.261 18.385 110.999 21.1243 114.376 21.1243C117.754 21.1243 120.492 18.385 120.492 15.0059H122.464C122.464 19.475 118.843 23.098 114.376 23.098C109.91 23.098 106.289 19.475 106.289 15.0059C106.289 10.5368 109.91 6.9138 114.376 6.9138V8.88748ZM114.376 18.5586C116.337 18.5586 117.927 16.968 117.927 15.0059C117.927 13.0439 116.337 11.4533 114.376 11.4533C112.415 11.4533 110.826 13.0439 110.826 15.0059C110.826 16.968 112.415 18.5586 114.376 18.5586Z"
        fill="#58DFAF"
      />
    </Svg>
  );
};

export default ReportLogo;
