import { MouseEvent, useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { MAPBOX_MAP_STYLES } from '../../../constants/map';
import { StyleChangeContainer, StyleName, StylesList, StylesListItem } from './style';

interface Props {
  setStyle: (style: keyof typeof MAPBOX_MAP_STYLES) => void;
  currentStyle: string;
}

const StyleChange = ({ setStyle, currentStyle }: Props) => {
  const [showStyles, setShowStyles] = useState(false);
  const styleChangeRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(styleChangeRef, () => setShowStyles(false));

  const handleStyleChange = (e: MouseEvent<HTMLDivElement>, style: keyof typeof MAPBOX_MAP_STYLES) => {
    e.stopPropagation();
    setStyle(style);
    setShowStyles(false);
  };

  const currentStyleName = useMemo(() => {
    const valueIndex = Object.values(MAPBOX_MAP_STYLES).findIndex((style) => {
      return style === currentStyle;
    });
    return Object.keys(MAPBOX_MAP_STYLES)[valueIndex];
  }, [currentStyle]);

  const renderItems = () =>
    Object.keys(MAPBOX_MAP_STYLES).map((style) => (
      <StylesListItem
        active={currentStyleName === style}
        key={style}
        data-test-id={`map-style-${style}`}
        name={style as keyof typeof MAPBOX_MAP_STYLES}
        onClick={(e) => handleStyleChange(e, style as keyof typeof MAPBOX_MAP_STYLES)}
      >
        <StyleName data-test-id={`map-style-${style}-name`}>{style.slice(0, 3)}</StyleName>
      </StylesListItem>
    ));

  return (
    <StyleChangeContainer
      active={showStyles}
      data-test-id="map-style-change"
      ref={styleChangeRef}
      onClick={() => setShowStyles((wasVisible) => !wasVisible)}
      name={currentStyleName as keyof typeof MAPBOX_MAP_STYLES}
    >
      <StyleName data-test-id="map-style-current">{currentStyleName.slice(0, 3)}</StyleName>
      <StylesList active={showStyles}>{renderItems()}</StylesList>
    </StyleChangeContainer>
  );
};

export default StyleChange;
