import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFolderResponse } from '../../../types/API/Folder';

interface FolderState {
  folders: IFolderResponse[];
  currentFolder: IFolderResponse | null;
}

const initialState: FolderState = {
  folders: [],
  currentFolder: null
};

export const folderSlice = createSlice({
  name: 'folder',
  initialState,
  reducers: {
    setFolders(state, action: PayloadAction<IFolderResponse[]>) {
      state.folders = action.payload;
    },
    setCurrentFolder(state, action: PayloadAction<IFolderResponse | null>) {
      state.currentFolder = action.payload;
    }
  }
});

export default folderSlice.reducer;
export const { setFolders, setCurrentFolder } = folderSlice.actions;
