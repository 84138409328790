import { useTranslation } from 'react-i18next';
import ReactPDFChart from 'react-pdf-charts';
import { Area, AreaChart, ReferenceArea, XAxis, YAxis } from 'recharts';
import { ForestCoverData, ForestDataType } from '../../../../context/Polygon/types';
import { theme } from '../../../../theme/Theme';

type GraphProps = {
  width?: number;
  height?: number;
  data: ForestCoverData[];
  type: ForestDataType;
  totalArea: number;
};

const ForestCoverChart = ({ width, height, data, type, totalArea }: GraphProps) => {
  const { t } = useTranslation();

  return (
    <ReactPDFChart>
      <AreaChart data={data} width={width} height={height}>
        <XAxis
          dataKey="name"
          tick={{
            fontSize: '10px'
          }}
          id="report-forest-cover-axis-x-tick"
        />
        <YAxis
          tickFormatter={(_value: number) =>
            type !== ForestDataType.PERCENTAGE
              ? new Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  maximumFractionDigits: 2
                }).format(_value)
              : t('{{value, number}}', { value: _value })
          }
          padding={{
            top: 20,
            bottom: 0
          }}
          tick={{
            fontSize: '10px'
          }}
        />
        <ReferenceArea
          stroke={theme.colors.purple}
          fill={theme.colors.purple}
          y1={0}
          ifOverflow="extendDomain"
          y2={type === ForestDataType.PERCENTAGE ? 100 : totalArea}
          fillOpacity={1}
        />
        <Area
          isAnimationActive={false} // Note: Essential to work
          dataKey="forest"
          name={String(t('Forested area'))}
          stroke={theme.colors.green}
          fill={theme.colors.green}
          fillOpacity={1}
        />
      </AreaChart>
    </ReactPDFChart>
  );
};

export default ForestCoverChart;
