import { useParams } from 'react-router-dom';
import Folder from '../../components/Portfolio/Folder';

const FolderPage = () => {
  const { id } = useParams();

  if (!id) {
    return <div>No id found</div>;
  }

  return <Folder id={id} />;
};

export default FolderPage;
