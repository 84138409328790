import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_SOURCE_NAME } from '../../constants/map';
import { useMapContext } from '../../context/Map';
import { usePolygonContext } from '../../context/Polygon';
import { IMessage } from '../../context/Socket';
import { useGetSplitPolygonDataMutation } from '../../redux/api/biomassApi';
import { updateMessage } from '../../redux/features/socket/socket-slice';
import { resetUIState } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { IRegionResponse } from '../../types/API/Region';
import { Notification, NotificationDate, NotificationList, NotificationMessage, Title } from './style';

const Notifications = () => {
  const dispatch = useAppDispatch();
  const { messages } = useAppSelector((state) => state.socketState);
  const { resetPolygonData } = usePolygonContext();
  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();
  const { handleTileClick } = useMapContext();
  const { t } = useTranslation();
  const unreadMessages = useMemo(() => messages.filter((message) => !message.wasOpened), [messages]);

  const handleDataReadyNotificationClick = useCallback(
    async (el: IRegionResponse) => {
      resetPolygonData();
      dispatch(resetUIState());

      await verifySplitPolygon({
        feature: el,
        withStateSet: true,
        omitCache: false
      });
      handleTileClick(el, USER_SOURCE_NAME);
    },
    [resetPolygonData, verifySplitPolygon, handleTileClick, dispatch]
  );

  const handleNotificationClick = async (index: number) => {
    if (!messages[index].wasOpened) {
      const message: IMessage = messages[index];
      if (message.payload.type === 'data-ready' && message.payload.region) {
        await handleDataReadyNotificationClick(message.payload.region);
      }

      const newMessage = {
        ...message,
        wasOpened: true
      };
      dispatch(updateMessage(newMessage));
    }
  };

  return (
    <>
      <Title data-test-id="notifications-title">
        {unreadMessages.length > 0
          ? t('See the latest notifications that have been sent to you')
          : t('No new notifications')}
      </Title>
      <NotificationList data-test-id="notifications-list">
        {messages.map((message, index) => (
          <Notification
            key={index}
            wasOpened={message.wasOpened}
            onClick={() => handleNotificationClick(index)}
            data-test-id="notifications-notification"
          >
            <NotificationDate data-test-id="notifications-notification-date">
              {new Date(message.createdAt).toLocaleDateString()}
            </NotificationDate>
            <NotificationMessage data-test-id="notifications-notification-message">
              {message.payload.message}
            </NotificationMessage>
          </Notification>
        ))}
      </NotificationList>
    </>
  );
};

export default Notifications;
