import styled from 'styled-components';

export const UpgradeModalSx = {
  minWidth: '880px',
  height: '690px'
};

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 440px;
  column-gap: 50px;
  margin-top: 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
