import FormLabel from '@mui/material/FormLabel';
import { InputHTMLAttributes, useCallback, useMemo } from 'react';
import PhoneInput from 'react-phone-number-input';
import { InputVariant } from '../../../types/Theme';
import Icon from '../Icon';
import { ErrorMsg, InputRelativeContainer, LabelSx, ValueResetter } from '../Input/style';
import { InputWrapper } from './style';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  variant?: InputVariant;
  value: string;
  onChange?: (val: any) => void;
  id?: string;
  name?: string;
  label: string;
  required?: boolean;
  color?: never;
  size?: never;
  error?: string;
  fullWidth?: boolean;
  noMargin?: boolean;
  dataTestId?: string;
  omitPlaceholder?: boolean;
  width?: string;
}

const PhoneInputComponent = ({
  variant = 'navy',
  value,
  onChange,
  id,
  name,
  label,
  required = false,
  error,
  fullWidth = false,
  noMargin = false,
  dataTestId,
  omitPlaceholder,
  width,
  ...rest
}: Props) => {
  const handleOnChange = useCallback(
    (val: any) => {
      if (onChange) onChange(val);
    },
    [onChange]
  );

  const handleOnReset = useCallback(() => {
    if (onChange) onChange('');
  }, [onChange]);

  const hasValue = useMemo(() => !!(value && value.length > 0), [value]);
  const hasError = useMemo(() => !!(error && hasValue), [error, hasValue]);

  return (
    <InputWrapper
      fullWidth={fullWidth}
      hasError={hasError}
      hasValue={hasValue}
      variant={variant}
      noMargin={noMargin}
      width={width}
    >
      <FormLabel htmlFor={id} sx={LabelSx} data-test-id={dataTestId && `${dataTestId}-label`}>
        {label}
      </FormLabel>
      <InputRelativeContainer width={width ? width : fullWidth ? '100%' : undefined}>
        <PhoneInput
          value={value}
          onChange={handleOnChange}
          id={id}
          name={name}
          type="tel"
          required={required}
          data-test-id={dataTestId}
          placeholder={!omitPlaceholder ? (required ? 'Required' : 'Optional') : undefined}
          {...rest}
        />
        <ValueResetter
          onClick={handleOnReset}
          visible={hasValue}
          type="button"
          data-test-id={dataTestId && `${dataTestId}-resetter`}
          tabIndex={-1}
        >
          <Icon size={20} color="graphite" variant="X_CIRCLE" />
        </ValueResetter>
      </InputRelativeContainer>
      {error ? <ErrorMsg data-test-id={dataTestId && `${dataTestId}-error`}>{error}</ErrorMsg> : null}
    </InputWrapper>
  );
};

export default PhoneInputComponent;
