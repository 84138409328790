import ReactPDFChart from 'react-pdf-charts';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { CarbonAccountingData } from '../../../../context/Polygon/types';
import { theme } from '../../../../theme/Theme';

type GraphProps = {
  width?: number;
  height?: number;
  data: CarbonAccountingData[];
};

const CarbonChart = ({ width, height, data }: GraphProps) => {
  return (
    <ReactPDFChart>
      <BarChart data={data} width={width} height={height}>
        <CartesianGrid strokeDasharray="3 3" />
        {data && data.length > 0 ? (
          <XAxis
            id="report-carbon-axis-x-tick"
            dataKey="name"
            tick={{
              fontSize: '10px'
            }}
          />
        ) : null}
        <YAxis
          tick={{
            fontSize: '10px'
          }}
          tickFormatter={(_value: number) =>
            new Intl.NumberFormat('en-US', {
              notation: 'compact',
              maximumFractionDigits: 2
            }).format(_value)
          }
          id="report-carbon-axis-y-tick"
        />
        <Bar
          isAnimationActive={false} // Note: Essential to work
          dataKey="carbon"
          fill={theme.colors.green}
          radius={[4, 4, 0, 0]}
          id="report-carbon-bar"
        />
      </BarChart>
    </ReactPDFChart>
  );
};

export default CarbonChart;
