import styled from 'styled-components';

export const Wrapper = styled.div<{ isSavedRegion?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-end;
  position: absolute;
  right: 32px;
  bottom: 10px;
  text-align: right;
`;

export const Title = styled.p`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  line-height: 14px;
  transition: ${({ theme }) => theme.transition};
  position: relative;
  z-index: 1;
  width: 100%;

  strong {
    margin-left: 4px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
  }
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.green};
  font-size: 17px;
  font-weight: bold;
  line-height: 17px;
  transition: ${({ theme }) => theme.transition};
  width: 100%;
  z-index: 1;
  position: relative;

  span {
    margin-left: 4px;
    font-weight: normal;

    sup {
      font-size: 10px;
      position: absolute;
      top: -6px;
      right: -6px;
    }
  }
`;
