import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.navy};
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const Section = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.blue};
  margin-bottom: 15px;
  padding-bottom: 15px;

  &:last-of-type {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

export const SectionTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 12px;
`;

export const SectionSubtitle = styled.h4`
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 30px;
`;

export const ButtonWrapper = styled.div`
  button {
    font-size: 14px;
    padding: 12px 20px;

    &:first-of-type {
      margin-right: 20px;
    }
  }
`;
