import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useGetAllUsersQuery } from '../../../redux/api/userApi';
import { openModal } from '../../../redux/features/modal/modal-slice';
import { setAdminUserPickedToEdit } from '../../../redux/features/user/user-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IUser } from '../../../types/User';
import Pagination from '../../Common/Pagination';
import { EditUser, StyledTableCell, StyledTableRow, Title, TopWrapper } from './style';

const UsersList = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.userState);
  const { data } = useGetAllUsersQuery({
    page,
    itemsPerPage: 10
  });

  const handleUserClick = useCallback(
    (clickedUser: IUser) => {
      if (clickedUser.role) {
        dispatch(setAdminUserPickedToEdit(clickedUser));
        dispatch(openModal('adminEditUser'));
      }
    },
    [dispatch]
  );

  const handleChangePage = useCallback((_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);

  return (
    <>
      <TopWrapper>
        <Title data-test-id="admin-users-title">{t('Users list')}</Title>
        <EditUser data-test-id="admin-users-find-user" onClick={() => dispatch(openModal('adminFindUser'))}>
          {t('Find user')}
        </EditUser>
      </TopWrapper>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: 'var(--black)',
          borderRadius: '8px',
          border: '1px solid var(--blue)',
          overflow: 'visible'
        }}
      >
        <Table sx={{ minWidth: 1000 }} aria-label="Users table">
          <TableHead>
            <TableRow>
              <StyledTableCell data-test-id="admin-users-table-head-cell-fullname" itemType="th">
                {t('Full Name')}
              </StyledTableCell>
              <StyledTableCell data-test-id="admin-users-table-head-cell-email" align="left" itemType="th">
                {t('Email')}
              </StyledTableCell>
              <StyledTableCell data-test-id="admin-users-table-head-cell-status" itemType="th">
                {t('Status')}
              </StyledTableCell>
              <StyledTableCell data-test-id="admin-users-table-head-cell-company" align="left" itemType="th">
                {t('Company')}
              </StyledTableCell>
              <StyledTableCell data-test-id="admin-users-table-head-cell-role" align="left" itemType="th">
                {t('Role')}
              </StyledTableCell>
              <StyledTableCell data-test-id="admin-users-table-head-cell-telephone" align="left" itemType="th">
                {t('Telephone')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((row: IUser) => (
              <StyledTableRow
                key={`${row.id || ''}`}
                isMe={row.id === user?.id}
                isActive={row.active}
                onClick={row.id === user?.id ? undefined : () => handleUserClick(row)}
              >
                <StyledTableCell
                  data-test-id="admin-users-table-body-cell-fullname"
                  style={{
                    maxWidth: '200px',
                    wordWrap: 'break-word'
                  }}
                >
                  {`${row.firstName || String(t('N/A'))} ${row.lastName || String(t('N/A'))}`}
                </StyledTableCell>
                <StyledTableCell
                  data-test-id="admin-users-table-body-cell-email"
                  align="left"
                  style={{
                    maxWidth: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {row.email}
                </StyledTableCell>
                <StyledTableCell
                  data-test-id="admin-users-table-body-cell-status"
                  style={{
                    color: row.active ? 'var(--green)' : 'var(--red)',
                    fontWeight: 600
                  }}
                >
                  {row.active ? t('Active') : t('Inactive')}
                </StyledTableCell>
                <StyledTableCell data-test-id="admin-users-table-body-cell-company" align="left">
                  {row.company || t('N/A')}
                </StyledTableCell>
                <StyledTableCell data-test-id="admin-users-table-body-cell-role" align="left">
                  {row.role || t('N/A')}
                </StyledTableCell>
                <StyledTableCell align="left" data-test-id="admin-users-table-body-cell-telephone">
                  {row.telephone ? formatPhoneNumberIntl(row.telephone) : t('N/A')}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.total && Math.ceil(data.total / 10) > 1 ? (
        <Pagination
          page={page}
          count={Math.ceil(data.total / 10)}
          boundaryCount={1}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      ) : null}
    </>
  );
};

export default UsersList;
