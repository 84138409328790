import { Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { ForestCoverData, ForestDataType } from '../../../../context/Polygon/types';
import { AreaUnitType } from '../../../../types/Geo';
import { convertUnitToFullName } from '../../../../utils/units';
import ForestCoverChart from '../../Components/Charts/ForestCoverChart';
import Footer from '../../Components/Footer';
import { globalStyles } from '../../style';

interface Props {
  forestCoverArea: ForestCoverData[];
  forestCoverPercentage: ForestCoverData[];
  areaUnit: AreaUnitType;
  calculatedArea: number;
  regionName: string;
}

const ForestCoverPage = ({ forestCoverArea, forestCoverPercentage, areaUnit, calculatedArea, regionName }: Props) => {
  const { t } = useTranslation();

  return (
    <Page style={globalStyles.pageWrapper}>
      <View style={globalStyles.chartsWrapper}>
        <Text style={{ ...globalStyles.headlineSmall, ...globalStyles.noMarginTop }}>{t('Forest Cover')}</Text>
        <Text style={globalStyles.headlineTiny}>{convertUnitToFullName(areaUnit)}</Text>
        <ForestCoverChart
          width={550}
          height={300}
          data={forestCoverArea}
          type={ForestDataType.AREA}
          totalArea={calculatedArea}
        />
        <Text style={globalStyles.headlineSmall}>{t('Forest Cover')}</Text>
        <Text style={globalStyles.headlineTiny}>{t('Percentage')}</Text>
        <ForestCoverChart
          width={550}
          height={300}
          data={forestCoverPercentage}
          type={ForestDataType.PERCENTAGE}
          totalArea={calculatedArea}
        />
      </View>
      <Footer regionName={regionName} />
    </Page>
  );
};

export default ForestCoverPage;
