import { useTranslation } from 'react-i18next';
import { openModal } from '../../../../redux/features/modal/modal-slice';
import { useAppDispatch } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import Spacer from '../../../Common/Spacer';
import Droppable from '../../../DragAndDrop/Droppable';
import FolderHeader from '../FolderHeader';
import { EmptyParagraph, EmptyWrapper } from './style';

interface Props {
  withHeader?: boolean;
  isFolder?: boolean;
  isUnassigned?: boolean;
  folderId?: string;
}

const EmptyTemplate = ({ withHeader = true, isFolder = true, isUnassigned = false, folderId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      {withHeader ? <FolderHeader /> : null}
      <Droppable id={`empty-${folderId || ''}`} folderId={folderId}>
        <EmptyWrapper>
          <EmptyParagraph>
            {isFolder
              ? t('This folder is empty. Create a new project or move a project to this folder.')
              : t('It seems that there are no projects here.')}
          </EmptyParagraph>
          {!isFolder ? (
            <EmptyParagraph>
              {t(
                isUnassigned
                  ? 'Create new project with no folder selected to see them here'
                  : 'Create new project with  folder selected to see them here'
              )}
            </EmptyParagraph>
          ) : null}
          <Spacer size="30px 0 0" />
          <Button
            onClick={() => dispatch(openModal('uploadShapefile'))}
            additionalSx={{
              padding: '10px 30px'
            }}
            variant={'purple'}
          >
            {t('Create project')}
          </Button>
        </EmptyWrapper>
      </Droppable>
    </>
  );
};

export default EmptyTemplate;
