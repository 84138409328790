import { useTranslation } from 'react-i18next';
import { Legend, Line, LineChart, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { ForestDataType } from '../../../context/Polygon/types';
import { useAppSelector } from '../../../redux/hooks';
import { theme } from '../../../theme/Theme';
import { NameType, Props, ValueType } from '../../../types/Chart';
import { convertUnitToFullName } from '../../../utils/units';
import {
  CHART_AXIS_COMMON_PROPS,
  CHART_COMMON_LABEL_PROPS,
  CHART_COMMON_PROPS,
  CHART_LINE_COMMON_PROPS,
  CHART_X_AXIS_COMMON_PROPS,
  renderLegend,
  renderTooltip
} from '../common';

const LineChartComponent = ({ data, dataTestId }: Props) => {
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.userState);
  const { forestDataType } = useAppSelector((state) => state.regionState);

  // custom tooltip component
  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    return renderTooltip({ active, payload, user, forestDataType });
  };

  const renderLines = () => (
    <Line
      animationEasing="ease-out"
      dataKey="deforestation"
      name={String(t('Change in deforestation'))}
      stroke={theme.colors.green}
      type="monotone"
      opacity={0.5}
      id={dataTestId && `${dataTestId}-line`} // for E2E testing
      {...CHART_LINE_COMMON_PROPS}
    />
  );

  return (
    <LineChart data={data} {...CHART_COMMON_PROPS}>
      <XAxis
        {...CHART_X_AXIS_COMMON_PROPS}
        tick={false}
        id={dataTestId && `${dataTestId}-axis-x-tick`} // for E2E testing
      />
      <YAxis
        id={dataTestId && `${dataTestId}-axis-y-tick`} // for E2E testing
        tickFormatter={(_value: number) =>
          forestDataType !== ForestDataType.PERCENTAGE
            ? new Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 2
              }).format(_value)
            : t('{{value, number}}', {
                value: _value,
                maximumFractionDigits: 2
              })
        }
        padding={{
          top: 20,
          bottom: 20
        }}
        label={{
          ...CHART_COMMON_LABEL_PROPS,
          value:
            forestDataType !== ForestDataType.PERCENTAGE && user?.settings.unit
              ? convertUnitToFullName(user?.settings.unit)
              : t('% change vs prior year quarter')
        }}
        {...CHART_AXIS_COMMON_PROPS}
      />
      <Tooltip content={<CustomTooltip />} />
      {renderLines()}
      <Legend
        iconType="circle"
        id={dataTestId && `${dataTestId}-legend`} // for E2E testing
        content={(props) => renderLegend(props)}
      />
    </LineChart>
  );
};

export default LineChartComponent;
