import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDropZone } from '../../../../hooks/useDropZone/useDropZone';
import { useUpdatePDFFileForProjectMutation } from '../../../../redux/api/publicProjectsApi';
import { closeModal } from '../../../../redux/features/modal/modal-slice';
import { setProjectToEditOrDelete } from '../../../../redux/features/public-projects/public-projects-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import Spacer from '../../../Common/Spacer';
import { ButtonWrapper, Subtitle, Title } from '../../ModalCommon.style';
import { FileInfoSubtitle, FileInfoTitle, FileInfoWrapper, FileResetButton } from '../CreatePublicProjectModal/style';

const EditPublicProjectPDFModal = () => {
  const dispatch = useAppDispatch();
  const { projectToEditOrDelete } = useAppSelector((state) => state.publicProjectsState);
  const [updateProjectPDF, { isLoading, reset, error }] = useUpdatePDFFileForProjectMutation();
  const { t } = useTranslation();

  const preparePDFFormData = useCallback(
    (files: FileList) => {
      if (projectToEditOrDelete) {
        const form = new FormData();
        // rename file to current project name
        form.append('file', files[0], `${projectToEditOrDelete.name}.pdf`);
        return form;
      }
    },
    [projectToEditOrDelete]
  );

  const { renderUploadSection, formData, setFormData, filename, setFilename, inputRef } = useDropZone({
    prepareData: preparePDFFormData,
    desiredFilename: projectToEditOrDelete?.name || '',
    accept: 'application/pdf'
  });

  const isButtonDisabled = useMemo(() => {
    return !formData || !formData.get('file');
  }, [formData]);

  const handleOnSave = useCallback(async () => {
    if (projectToEditOrDelete && formData) {
      const data = await updateProjectPDF({
        id: projectToEditOrDelete.id,
        body: formData
      });

      if (!('error' in data)) {
        toast.success(
          t(
            projectToEditOrDelete?.properties.pdfUrl !== ''
              ? 'Project PDF updated successfully'
              : 'Project PDF added successfully',
            {
              toastId: 'project-pdf-updated-successfully',
              autoClose: 3000
            }
          )
        );
        dispatch(setProjectToEditOrDelete(undefined));
        dispatch(closeModal());
      }
    }
  }, [dispatch, formData, projectToEditOrDelete, t, updateProjectPDF]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'edit-region-pdf-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <>
      <Modal
        dataTestId="edit-public-project-pdf-modal"
        modalType="editPublicProjectPDF"
        onClose={() => dispatch(setProjectToEditOrDelete(undefined))}
      >
        <Title data-test-id="edit-public-project-pdf-modal-title">
          {projectToEditOrDelete?.properties.pdfUrl !== '' ? t('Edit Public Project PDF') : t('Add Public Project PDF')}
        </Title>
        {projectToEditOrDelete?.properties.name ? (
          <Subtitle data-test-id="edit-public-project-pdf-modal-subtitle">
            {projectToEditOrDelete.properties.name}
          </Subtitle>
        ) : null}
        <Spacer size={'30px 0 0'} />

        {filename ? (
          <FileInfoWrapper>
            <FileResetButton
              onClick={() => {
                if (inputRef.current) inputRef.current.value = '';
                setFilename('');
                setFormData(null);
              }}
            >
              {t('Reset')}
            </FileResetButton>
            <FileInfoTitle>{filename.split('.')[0]}</FileInfoTitle>
            <FileInfoSubtitle>
              {t('File name:')} {filename.toLocaleLowerCase()}
            </FileInfoSubtitle>
          </FileInfoWrapper>
        ) : (
          renderUploadSection()
        )}
        <Spacer size={'30px 0 0'} />
        <ButtonWrapper>
          <Button
            variant="outline-purple"
            fullWidth
            onClick={() => dispatch(closeModal())}
            dataTestId="edit-public-project-pdf-modal-delete-region-button"
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="purple"
            fullWidth
            dataTestId="edit-public-project-pdf-modal-update-button"
            disabled={isButtonDisabled || isLoading}
            onClick={handleOnSave}
          >
            {projectToEditOrDelete?.properties.pdfUrl !== '' ? t('Update') : t('Add')}
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default EditPublicProjectPDFModal;
