import { TFunction } from 'i18next';

export const VALIDATION_ERRORS = (t: TFunction<'translation', undefined>) => ({
  PASSWORD: t('Min 8 characters'),
  ALPHANUMERIC: t('Only alphanumeric characters'),
  LETTERS_AND_SPACES: t('Only letters and spaces'),
  LETTERS_SPACES_AND_SPECIAL: t('Only letters, spaces and special characters (-_+)'),
  SAME_PASSWORD: t('Password and Confirmed Password should be the same'),
  EMAIL: t('Type proper email format'),
  EMAIL_PASSWORD_REQUIRED: t('Email and password are required'),
  REQUIRED: t('This field is required'),
  GENERIC: t('Please enter correct values to highlighted fields'),
  INVALID_CURRENT_PASSWORD: t('Password incorrect')
});

export const regexes = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^.{8,}$/,
  alphaOnly: /^[a-zA-Z]+$/,
  lettersSpacesOnly: /^[a-zA-Z ]+$/,
  lettersSpacesPlusOnly: /^[a-zA-Z +]+$/,
  letterSpaceCertainChars: /^[a-zA-Z0-9 \-_+]+$/,
  alphaNumericSpace: /^[a-zA-Z0-9 ]+$/
};
