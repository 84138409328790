import { MapboxGeoJSONFeature } from 'react-map-gl';
import { PublicProject } from '../../types/API/PublicProjects';
import { IRegionResponse } from '../../types/API/Region';

/**
 * Helper function for formatting distributed data to map a library-required format
 */
export const prepareRegionsData = (
  regions: GeoJSON.Feature<GeoJSON.Polygon>[] | IRegionResponse[] | PublicProject[]
) => {
  const newArr: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
    type: 'FeatureCollection',
    features: []
  };

  regions.forEach((region) => {
    newArr.features.push({
      type: 'Feature',
      geometry: region.geometry as GeoJSON.Polygon,
      properties: {
        ...region.properties,
        // record original geometry (for some reason, mapbox-gl changes it a little)
        originalGeometry: region.geometry
      }
    });
  });

  return newArr;
};

/**
 * Helper function for preparing cleaned feature for the map
 */
export const prepareCleanedFeature = (feature: MapboxGeoJSONFeature) => {
  const newFeature = { ...feature };
  // recover original geometry coordinates
  if (feature.properties && feature.properties.originalGeometry) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument
    newFeature.geometry = JSON.parse(feature.properties.originalGeometry);
  }

  return {
    type: feature.type,
    geometry: newFeature.geometry as GeoJSON.Polygon,
    properties: feature.properties,
    id: feature.properties?.name as string // Since we don't have an ID, we are using the tile's name
  };
};
