import { StyleSheet } from '@react-pdf/renderer';
import styled from 'styled-components';

export const globalStyles = StyleSheet.create({
  pdfViewer: {
    position: 'absolute',
    top: '100px',
    bottom: '50px',
    border: 0,
    width: '100%'
  },
  pageWrapper: {
    padding: '50px',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },
  chartsWrapper: {
    flexGrow: 1,
    marginLeft: '-50px'
  },
  headlineBig: {
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: '20px',
    color: '#030818'
  },
  headlineMedium: {
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '15px',
    color: '#565B69'
  },
  headlineSmall: {
    marginTop: '20px',
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '12px',
    marginLeft: '50px',
    color: '#030818'
  },
  headlineTiny: {
    fontFamily: 'Manrope',
    fontWeight: 400,
    fontSize: '9px',
    marginLeft: '50px',
    marginBottom: '10px',
    color: '#565B69'
  },
  // utils
  noMarginTop: {
    marginTop: 0
  },
  noMarginLeft: {
    marginLeft: 0
  }
});

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const LoadingTitle = styled.div`
  color: white;
  font-family: Manrope, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 51px;
  text-align: center;
  margin-top: 10px;
`;

export const LoadingSubTitle = styled.div`
  color: white;
  font-family: Manrope, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
`;
