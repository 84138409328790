import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VALIDATION_ERRORS } from '../../../constants/validation';
import { useChangePasswordMutation, useLogoutMutation } from '../../../redux/api/authApi';
import { IChangePasswordBody } from '../../../types/API/Auth';
import { validatePassword } from '../../../utils/validation';
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';
import Message from '../../Common/Message';
import PasswordInput from '../../Common/PasswordInput';
import Spacer from '../../Common/Spacer';

export interface Props {
  onClose: () => void;
}

const PasswordChangeForm = ({ onClose }: Props) => {
  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [changePassword, { isLoading, isSuccess, error, reset }] = useChangePasswordMutation();

  const handleSetCurrentPassword = (value: string) => {
    setPasswordError('');
    setCurrentPassword(value);
  };

  const handleSetNewPassword = (value: string) => {
    if (confirmPassword !== value) {
      setNewPasswordError(VALIDATION_ERRORS(t).SAME_PASSWORD);
    } else if (!validatePassword(value)) {
      setNewPasswordError(VALIDATION_ERRORS(t).PASSWORD);
    } else {
      setNewPasswordError('');
    }

    setNewPassword(value);
  };

  const handleSetConfirmPassword = (value: string) => {
    if (newPassword !== value) {
      setNewPasswordError(VALIDATION_ERRORS(t).SAME_PASSWORD);
    } else if (!validatePassword(value)) {
      setNewPasswordError(VALIDATION_ERRORS(t).PASSWORD);
    } else {
      setNewPasswordError('');
    }

    setConfirmPassword(value);
  };

  const updatePassword = useCallback(async () => {
    const updatePasswordBody: IChangePasswordBody = {
      currentPassword,
      newPassword
    };

    await changePassword(updatePasswordBody);
  }, [changePassword, currentPassword, newPassword]);

  const handleOnSubmitPassword = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      if (newPasswordError.length === 0) {
        await updatePassword();
      }
    },
    [updatePassword, newPasswordError]
  );

  const handleLogout = useCallback(async () => {
    await logout({ isAdmin: false });
  }, [logout]);

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'change-password-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <>
      {isSuccess ? (
        <>
          <Spacer size="20px 0 0" />
          <Message
            text={String(t('Password changed'))}
            dataTestId="password-change-form-message-success"
            details={String(t('You will be logged out and need to log in with your new password.'))}
          >
            <Icon variant="TICK_CIRCLE" color="green" size={28} />
          </Message>
          <Spacer size="20px 0 0" />
          <Button
            fullWidth
            onClick={handleLogout}
            disabled={isLogoutLoading}
            variant="outline-purple"
            dataTestId="password-change-form-button-done"
          >
            {t('Done')}
          </Button>
        </>
      ) : (
        <>
          <Spacer size="20px 0 0" />
          <form onSubmit={handleOnSubmitPassword}>
            <PasswordInput
              variant="navy"
              value={currentPassword}
              required
              error={passwordError}
              onChange={handleSetCurrentPassword}
              label={String(t('Current Password'))}
              dataTestId="password-change-form-input-current-password"
            />
            <Spacer size={'20px 0 0'} />
            <PasswordInput
              variant="navy"
              value={newPassword}
              required
              onChange={handleSetNewPassword}
              label={String(t('Create New Password'))}
              dataTestId="password-change-form-input-new-password"
            />
            <Spacer size={'20px 0 0'} />
            <PasswordInput
              variant="navy"
              value={confirmPassword}
              required
              error={newPasswordError}
              onChange={handleSetConfirmPassword}
              label={String(t('Confirm New Password'))}
              dataTestId="password-change-form-input-confirm-password"
            />
            <Spacer size={'30px 0 0'} />
            <div
              style={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between'
              }}
            >
              <Button
                fullWidth
                onClick={handleOnClose}
                variant="text-only-purple"
                dataTestId="password-change-form-button-cancel"
                additionalSx={{
                  padding: 0,
                  justifyContent: 'flex-start',
                  fontSize: '14px',
                  height: 'auto'
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                fullWidth
                disabled={isLoading}
                type="submit"
                variant="purple"
                dataTestId="password-change-form-button-continue"
              >
                {t('Continue')}
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default PasswordChangeForm;
