/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MUITab from '@mui/material/Tab';
import { Children, Dispatch, ReactElement, SetStateAction, SyntheticEvent } from 'react';
import { ColorVariant } from '../../../types/Theme';
import { TabsSx, TabSx } from './style';

export interface Props {
  children: ReactElement[];
  color?: ColorVariant;
  additionalTabsSx?: any;
  additionalTabSx?: any;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onChange?: (newValue: string) => void;
  dataTestId?: string;
  orientation?: 'horizontal' | 'vertical';
  centered?: boolean;
}

const Tabs = ({
  children,
  color,
  additionalTabsSx,
  additionalTabSx,
  value,
  setValue,
  onChange,
  dataTestId,
  orientation = 'horizontal',
  centered
}: Props) => {
  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    if (onChange) onChange(newValue);
    setValue(newValue);
  };

  const tabsColor = color
    ? {
        '.MuiTabs-indicator': {
          backgroundColor: `var(--${color})`
        }
      }
    : {};

  const tabColor = color
    ? {
        '&.Mui-selected': {
          color: `var(--${color})`
        }
      }
    : {};

  return (
    <>
      <TabContext value={value}>
        <TabList
          data-testid={dataTestId}
          onChange={handleChange}
          sx={{ ...TabsSx, ...tabsColor, ...additionalTabsSx }}
          orientation={orientation}
          centered={centered}
        >
          {Children.map(children, (child, index) => {
            return (
              <MUITab
                data-test-id={dataTestId && `${dataTestId}-tab-${index}`}
                sx={{ ...TabSx, ...tabColor, ...additionalTabSx }}
                label={child.props.title}
                disabled={child.props.disabled}
                value={index.toString()}
              />
            );
          })}
        </TabList>
        {Children.map(children, (child, index) => {
          return (
            <TabPanel
              data-test-id={dataTestId && `${dataTestId}-content-tab-${index}`}
              value={index.toString()}
              sx={{ padding: '0' }}
            >
              {child}
            </TabPanel>
          );
        })}
      </TabContext>
    </>
  );
};

export default Tabs;
