const PrivacyPolicy = [
  {
    title: 'Types of Data Collected',
    description: [
      'We may collect the following information from communications generated when you sign up for an account, request a transaction, enroll in a promotion or program, or otherwise use our Services:'
    ],
    content: [
      `1. <strong>Identity Data</strong> includes your full name, date of birth, gender, social security number, and other data on government-issued identification documents.`,
      `2. <strong>Contact Data</strong> includes your email address, mailing address, and telephone number.`,
      `3. <strong>Financial Data</strong> includes your bank account, wallet, payment card details, and other information necessary for “know your customer” and anti-money-laundering laws and regulations including, potentially, account balances, financial transaction history, credit history, and tax information.`,
      `4. <strong>Profile Data</strong> includes your username and password, purchases or orders made by you, your knowledge assessment results, and your interests, preferences, feedback, and survey responses.`,
      `5. <strong>Usage Data</strong> includes information about how you access and use our Services, such as your interactions with others on the Services, photos or media you upload to the Services, your username, and other content you provide.  We only access your device camera roll and camera with your permission.  If you need to update your permissions, you can do so in your device’s settings.  Please remember that CYCLOPS may, but has no obligation to, monitor, record, and store Usage Information in order to protect your safety or the safety of other users, to assist with regulatory or law enforcement efforts, to protect and defend our rights and property, or for other reasons relating to our provision of the Services.  By using the Services, you consent to the recording, storage, and disclosure of such information.`,
      `6. <strong>Additional Data</strong> includes information that you submit via focus groups, contests/sweepstakes, job applications, customer support, and other similar means.  We may also collect any communications between CYCLOPS and you (including recording calls made to or by CYCLOPS) and any other information you provide to CYCLOPS.`
    ]
  },
  {
    title: 'How We Use the Data We Collect',
    description: [
      'We use and process the data we collect for purposes described in this Privacy Policy or as otherwise described to you through our Service or in connection with our Services.  For example, we use the data to:'
    ],
    content: [
      `1. Create and process your account and deliver the Services to you, including to allow you to register for the Services and participate in interactive features and for CYCLOPS to authenticate your identity, handle billing and account management, fulfill our legal and regulatory obligations, and complete other administrative matters.`,
      `2. Send you transactional information, including confirmations, invoices, technical notices, product and services information and announcements, software updates, security alerts, support and administrative messages, and information about your transaction with us.`,
      `3. Communicate with you to respond to your comments and questions, deliver newsletter or other content we believe you will be interested in, provide customer service or feedback, contact you about amounts owed, conduct surveys, respond to employment opportunities for which you’ve applied, notify you about upcoming events, or for any other purposes in connection with the Services.`,
      `4. Conduct research and analytics to better understand our visitors and customers and tailor our product offerings.`,
      `5. Provide you with updates about products and services offered by us and our selected partners, as well as to facilitate new contests, sweepstakes, promotions, and rewards.`,
      `6. Make suggestions and recommendations to you about things that are similar to those about which you have inquired or that may otherwise be of interest to you, such as providing you with advertisements based on your interests and activity on the Services.`,
      `7. Monitor, administer, and enhance our Services, including troubleshooting, data analysis, testing, research, statistical, and survey purposes.`,
      `8. Ensure and enhance the safety and security of our Services, business, and users, and investigate or provide notice of fraud or unlawful or criminal activity.`,
      `9. Perform audits and protect or exercise our legal rights or defend against legal claims, including to enforce and carry out contracts and agreements.`,
      `10. Comply with applicable laws and legal obligations.`
    ]
  },
  {
    title: 'When We Share Data',
    description: [
      'CYCLOPS is committed to maintaining your trust, and we want you to understand when and with whom we may share the data we collect.'
    ],
    content: [
      `1. <strong>Authorized third-party vendors and service providers.</strong>  We may share personal data with certain authorized and vetted contractors, subcontractors, third-party vendors, and service providers who help us run and protect our business.  The services these third parties provide includes sending emails, conducting business analytics, marketing, and data processing.  For example, we may share personal data with service providers that identify and serve targeted advertisements or provide mailing services, contest fulfillment, web hosting, or analytic services.`,
      `2. <strong>Corporate transactions.</strong>  We may share personal data in connection with a substantial corporate transaction, such as the sale of a website, a merger, consolidation, reorganization, financing, change or control or acquisition of all or a portion of our business by another company or third party, asset sale, initial public offering, or in the unlikely event of bankruptcy or similar proceeding.`,
      `3. <strong>Legal purposes.</strong>  We may disclose personal data to respond to subpoenas, court orders, legal process, law-enforcement requests, legal claims, or government inquiries and to protect and defend the rights, interests, safety, and security of CYCLOPS, our affiliates, users, or the public.`,
      `4. <strong>With your consent.</strong>  We may share personal data for any other purposes disclosed to you with your consent. We may also share information with others in an aggregated or otherwise anonymized form that does not reasonably identify you directly as an individual.`
    ]
  },
  {
    title: 'Third-Party Content',
    description: [
      'Some of the Services may contain links to content maintained by third parties whom we do not control.  We are not responsible for the privacy practices of these third parties, nor are we responsible for the content on the third-party services.  Please note that these third-party services have their own privacy policies, and we strongly recommend that you read their privacy policies and terms and conditions of use to understand how they collect, use, and share your information.'
    ]
  },
  {
    title: 'Cookies, Third-Party Tracking, and Online Advertising',
    description: [
      'Like most modern websites, our Services uses cookies.  Cookies are small files saved on your computer, which allow our Services to recognize you as you navigate our website.',
      'CYCLOPS uses cookies to a minimal, necessary extent. The cookies we use are used to keep track of your login information and your activity on our website and are not intended for collecting your data for marketing or third-party purposes.'
    ]
  },
  {
    title: 'Control Over Your Information',
    description: [
      'Most of the information that we collect from you is automatically collected from cookies.',
      'The information you provide to us through your account profile can also be changed or deleted by logging into your account.',
      `You may also email us at ${String(
        process.env.REACT_APP_EMAIL_UPGRADE_TO
      )} to request that we delete certain personal information about you.  We may verify the request by asking you to provide information that matches information we have on file about you.  You can also designate an authorized agent to exercise these rights on your behalf, but we will require proof that the person is authorized to act on your behalf, and we may also still ask you to verify your identity with us directly.`,
      `If you wish to stop receiving promotional emails from us, you can disable future communications by clicking on the provided “unsubscribe” link.  You may not opt out of service-related or other non-promotional communications (e.g., account verification, transactional communications, changes/updates to features of the Services, and technical and security notices).`
    ]
  },
  {
    title: 'Notices to Certain Users',
    content: [
      `<strong>A: California Users</strong> Under California Civil Code Section 1798.100 (California Consumer Privacy Act), California residents may have certain data protection rights regarding their personal information.  These rights include the right to disclosure, deletion, access, and nondiscrimination, as well as the right to opt out of having your personal information shared or sold.  To exercise your rights under the California Consumer Privacy Act, please email us at ${String(
        process.env.REACT_APP_EMAIL_UPGRADE_TO
      )} as described in section VI.
The following are categories of personal information defined under the California Consumer Privacy Act that we may collect, receive, or maintain in the course of administering the Services: Identifiers, Personal information described in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)), Protected classification characteristics under California or federal law, Commercial information, Biometric information, Internet or other electronic network activity, Geolocation data, Sensory data, Professional or employment-related information, Non-public education information, and inferences drawn from other personal information.`,
      `<strong>B: European Union and United Kingdom Users:</strong> Under EU and UK data protection laws (UK GDPR/2018 Data Protection Act and EU GDPR), individuals may have certain data protection rights regarding their personal information.  These rights include the right to request access to and, rectification or erasure of your personal information, as well as the right to ask us to restrict the processing of your personal information.  You may also have a right to object to processing of your personal information where it is carried out for our legitimate interest or direct marketing.  To exercise your rights under the GDPR, please email us at ${String(
        process.env.REACT_APP_EMAIL_UPGRADE_TO
      )} as described in section VI.
Individuals may also have a right to lodge a complaint about the processing of their Personal Information with their local data protection authority.`
    ]
  },
  {
    title: 'Changes to this Policy',
    description: [
      'This Privacy Policy may evolve with time.  If we update it, we will revise the date listed in the heading and post the new version.  To stay informed of our privacy practices, we recommend you review the Privacy Policy on a regular basis as you continue to use our Services'
    ]
  }
];

export default PrivacyPolicy;
