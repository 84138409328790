import Skeleton from '@mui/material/Skeleton';

export interface Props {
  width: number;
  height: number;
  sx?: any;
  dataTestId?: string;
}

const SkeletonComponent = ({ width, height, sx, dataTestId }: Props) => (
  <Skeleton
    width={width}
    height={height}
    data-test-id={dataTestId}
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    sx={{ ...sx, backgroundColor: 'grey.900' }}
    animation="wave"
    variant="rounded"
  />
);

export default SkeletonComponent;
