import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateUserMutation } from '../../../../redux/api/userApi';
import { useAppSelector } from '../../../../redux/hooks';
import { AreaUnitType } from '../../../../types/Geo';
import { ISettings } from '../../../../types/User';
import Button from '../../../Common/Button';
import Select, { Props as SelectProps } from '../../../Common/Select';
import { AccountFooterWrapper, AccountSectionTitle } from '../style';
import { SelectLabel, SelectWrapper, Wrapper } from './style';

const AccountSettings = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);
  const [updateUser, { isLoading, isSuccess, error, reset }] = useUpdateUserMutation();
  const [unit, setUnit] = useState<AreaUnitType>(user?.settings.unit || AreaUnitType.squareKilometre);
  const [language, setLanguage] = useState(user?.settings.language || 'en-US');
  const unitOptions: SelectProps['options'] = useMemo(
    () => [
      {
        value: AreaUnitType.squareKilometre,
        label: t('Square Kilometers')
      },
      {
        value: AreaUnitType.hectare,
        label: t('Hectares')
      },
      {
        value: AreaUnitType.acre,
        label: t('Acre')
      },
      {
        value: AreaUnitType.squareMile,
        label: t('Square Miles')
      }
    ],
    [t]
  );

  const languageOptions: SelectProps['options'] = useMemo(
    () => [
      {
        value: 'en-US',
        label: t('English')
      }
    ],
    [t]
  );
  const handleChangeLang = useCallback((val: string) => {
    setLanguage(val);
  }, []);

  const handleChangeUnit = useCallback((val: AreaUnitType) => {
    setUnit(val);
  }, []);

  const handleSubmit = useCallback(async () => {
    const settings: ISettings = {
      ...user?.settings,
      language,
      unit
    };
    await updateUser({
      settings
    });
  }, [language, unit, updateUser, user?.settings]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Settings saved successfully'), {
        autoClose: 3000,
        toastId: 'settings-toast'
      });
    }
  }, [isSuccess, t]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'account-setting-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  return (
    <>
      <Wrapper>
        <AccountSectionTitle>{t('Settings')}</AccountSectionTitle>
        <SelectWrapper>
          <SelectLabel>{t('Data units')}</SelectLabel>
          <Select
            value={unit}
            onChange={handleChangeUnit}
            options={unitOptions}
            dataTestId="account-settings-unit-select"
            additionalSx={{
              width: '400px'
            }}
            additionalPaperSx={{
              ul: {
                padding: 0
              }
            }}
            autoWidth={false}
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectLabel>{t('Application language')}</SelectLabel>
          <Select
            value={language}
            onChange={handleChangeLang}
            options={languageOptions}
            dataTestId="account-settings-language-select"
            additionalSx={{
              width: '400px'
            }}
            additionalPaperSx={{
              ul: {
                padding: 0
              }
            }}
            autoWidth={false}
          />
        </SelectWrapper>
      </Wrapper>
      <AccountFooterWrapper
        style={{
          bottom: 0
        }}
      >
        <Button
          disabled={isLoading}
          fullWidth
          variant="purple"
          onClick={handleSubmit}
          dataTestId="account-settings-save-button"
          additionalSx={{
            width: '190px',
            marginLeft: 'auto'
          }}
        >
          {t('Save Changes')}
        </Button>
      </AccountFooterWrapper>
    </>
  );
};

export default AccountSettings;
