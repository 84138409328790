import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import Icon from '../../Common/Icon';
import Tabs from '../../Common/Tabs';
import Tab from '../../Common/Tabs/Tab';
import LoginForm from '../../Forms/LoginForm';
import RegistrationForm from '../../Forms/RegistrationForm';
import Header from '../Header';
import { AuthWrapper, FormWrapper, IconCircleWrapper, Subtitle, TabsSx, Title } from './style';

interface Props {
  children?: ReactNode;
  withMessage?: boolean;
}

const AuthLayout = ({ children, withMessage }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [tab, setTab] = useState(location.pathname.replace('/', '') === 'login' ? '0' : '1');

  const { isAuthenticated } = useAppSelector((state) => state.userState);

  /**
   * If a user is logged in - redirect to homepage
   */
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  /**
   * Tab item change
   * Results in change of page from login/register and likewise
   */
  const handleHistoryChange = useCallback((newValue: string) => {
    setIsSubmitted(false);
    window.history.pushState({}, '', newValue === '0' ? '/login' : '/register');
  }, []);

  const isLoginOrRegister = useMemo(
    () => location.pathname.includes('login') || location.pathname.includes('register'),
    [location.pathname]
  );

  const handleOnSubmitSuccess = useCallback(() => {
    setIsSubmitted(true);
  }, []);

  return (
    <AuthWrapper>
      <Header version="auth" />
      <FormWrapper
        withHorizontalPadding={!location.pathname.includes('login') && !location.pathname.includes('register')}
        withMessage={withMessage}
      >
        {isSubmitted ? (
          <>
            <IconCircleWrapper>
              <Icon color="gray" variant="ENVELOPE" size={20} />
            </IconCircleWrapper>

            <Title>{t('Account created')}</Title>
            <Subtitle>
              {t(
                'Thank you for signing up with Cyclops! Your account has been successfully created. Please check your email to activate your account.'
              )}
            </Subtitle>
          </>
        ) : null}
        {isLoginOrRegister && !isSubmitted ? (
          <Tabs
            dataTestId="auth-tabs"
            value={tab}
            setValue={setTab}
            onChange={handleHistoryChange}
            additionalTabsSx={TabsSx}
          >
            <Tab title="Log In">
              <LoginForm />
            </Tab>
            <Tab title="Sign Up">
              <RegistrationForm onSuccess={handleOnSubmitSuccess} />
            </Tab>
          </Tabs>
        ) : null}
        {children}
      </FormWrapper>
    </AuthWrapper>
  );
};

export default AuthLayout;
