import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { MESSAGES_LS } from '../../../constants/user';
import { IMessage } from '../../../context/Socket';
import { setMessages } from '../../../redux/features/socket/socket-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import { ButtonWrapper } from './style';

const NotificationsFooter = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { messages } = useAppSelector((state) => state.socketState);
  const [, setStoredMessages] = useLocalStorage<IMessage[]>(String(MESSAGES_LS), []);

  const handleClearAll = useCallback(() => {
    setStoredMessages([]);
    dispatch(setMessages([]));
  }, [dispatch, setStoredMessages]);

  return messages.length > 0 ? (
    <ButtonWrapper>
      <Button fullWidth variant="green" dataTestId="notifications-remove-all-button" onClick={handleClearAll}>
        {t('Clear all')}
      </Button>
    </ButtonWrapper>
  ) : null;
};

export default NotificationsFooter;
