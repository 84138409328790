import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  footerWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100vw',
    padding: '0 50px',
    backgroundColor: 'rgba(142, 148, 162, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px'
  },
  footerText: {
    color: '#030818',
    fontSize: '10px',
    fontFamily: 'Manrope'
  }
});
