import styled from 'styled-components';
import { COMMON_ELEMENT_WIDTH } from '../../../constants/UI';

export const PasswordInputWrapper = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : COMMON_ELEMENT_WIDTH)};
`;

export const VisibilityToggle = styled.button<{ isVisible: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  transition: ${({ theme }) => theme.transition};
  position: absolute;
  padding: 0;
  margin: 0;
  right: 16px;
  background-color: transparent;
  top: 13px;
  border: 0;
  cursor: pointer;
`;
