import { createContext, useCallback, useContext } from 'react';
import { useSendEmailMutation } from '../../redux/api/mailApi';
import { IMailBody } from '../../types/API/Mail';
import { Props } from '../types';
import { defaultState, IRegionContext } from './types';

const RegionContext = createContext<IRegionContext>(defaultState);

export const RegionProvider = ({ children }: Props) => {
  const [sendMail] = useSendEmailMutation();
  const generateMailBody = async (polygonCollection: GeoJSON.FeatureCollection, from: string): Promise<IMailBody> => {
    // Convert GeoJSON to string
    const geoJsonString = JSON.stringify(polygonCollection);

    // Create a Blob object
    const blob = new Blob([geoJsonString], { type: 'application/json' });

    // Read blob data as base64
    const reader = new FileReader();
    const base64Promise = new Promise<string>((resolve) => {
      reader.onload = () => {
        const base64Data = reader.result as string;
        resolve(base64Data.split(',')[1]); // Extract base64 data (remove data:image/jpeg;base64,)
      };
    });
    reader.readAsDataURL(blob);
    const base64Data = await base64Promise;

    // Prepare attachment object
    const attachment = {
      filename: 'geojson.json',
      content: base64Data,
      type: 'application/json',
      disposition: 'attachment',
      content_id: 'geojson'
    };

    return {
      subject: `Report region from user ${from}`,
      to: String(process.env.REACT_APP_REPORT_EMAIL),
      from: String(process.env.REACT_APP_REPORT_EMAIL),
      text: `User ${from} reported a region. Please check the attachment.`,
      attachments: [attachment]
    };
  };

  const reportRegion = useCallback(
    async (polygonCollection: GeoJSON.FeatureCollection, from: string) => {
      try {
        const mailBody = await generateMailBody(polygonCollection, from);
        await sendMail(mailBody);
        return {
          success: true
        };
      } catch (err: any) {
        return {
          success: false
        };
      }
    },
    [sendMail]
  );

  return (
    <RegionContext.Provider
      value={{
        reportRegion
      }}
    >
      {children}
    </RegionContext.Provider>
  );
};

export const useRegionContext = () => useContext(RegionContext);
