import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUploadDocumentMutation } from '../../../redux/api/regionApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import { ButtonWrapper, Title } from '../ModalCommon.style';
import useDocumentUpload from './useDocumentUpload';

const UploadDocumentModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { selectedPolygon } = useAppSelector((state) => state.regionState);

  const { filename, formData, renderUploadStep } = useDocumentUpload();

  const [uploadDocument, { error, reset }] = useUploadDocumentMutation();

  const onSubmit = useCallback(async () => {
    if (!formData) return;
    setIsSubmitting(true);

    const updatedRegion = await uploadDocument({
      regionId: selectedPolygon?.id as string,
      file: formData
    });

    if (!('error' in updatedRegion)) {
      toast.success(t('Document uploaded successfully'));
      dispatch(closeModal());
    } else {
      setIsSubmitting(false);
      return;
    }
  }, [dispatch, formData, t, selectedPolygon?.id, uploadDocument]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'upload-document-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset, t]);

  const isSubmitButtonDisabled = useMemo(() => {
    return isSubmitting || !filename;
  }, [isSubmitting, filename]);

  return (
    <>
      <Modal dataTestId="upload-document-modal" modalType="uploadDocument">
        <Title data-test-id="upload-document-modal-title">{t('Upload document')}</Title>
        <Spacer size={'30px 0 0'} />
        {renderUploadStep()}
        <Spacer size={'20px 0 0'} />
        <ButtonWrapper>
          <Button
            variant="text-only-purple"
            fullWidth
            dataTestId="upload-document-modal-cancel"
            additionalSx={{
              padding: 0,
              justifyContent: 'flex-start',
              fontSize: '14px',
              height: 'auto'
            }}
            disabled={isSubmitting}
            onClick={() => dispatch(closeModal())}
          >
            {t('Cancel')}
          </Button>
          <Button
            variant="purple"
            additionalSx={{
              width: '190px',
              marginLeft: 'auto'
            }}
            dataTestId="upload-document-modal-upload"
            disabled={isSubmitButtonDisabled}
            onClick={onSubmit}
          >
            {t('Upload')}
          </Button>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default UploadDocumentModal;
