import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { logout } from '../features/user/user-slice';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  credentials: 'include'
});
// We are using a mutex to prevent multiple refresh requests
const mutex = new Mutex();

const customFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  // If the mutex is locked, we wait for it to unlock, then try again
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  // If the result is a 401, we try to refresh the token and try request again
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: '/auth/refresh',
            credentials: 'include',
            method: 'POST'
          },
          api,
          extraOptions
        );
        // If the refresh request is a 403 (Wrong or expired token), we log the user out
        if (refreshResult.error?.status === 403) {
          api.dispatch(logout());
          document.cookie = 'authorized=false';
          window.location.href = '/login';
        } else {
          result = await baseQuery(args, api, extraOptions);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export default customFetchBase;
