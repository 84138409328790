import styled from 'styled-components';

export const SectionWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  height: 400px;
`;

export const DetailRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const RowLabel = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const RowValue = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
`;

export const MapContainer = styled.div`
  transition: ${({ theme }) => theme.transition};
  position: relative;
`;

export const MapButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 8px;
  padding: 10px 30px;
  background: rgba(3, 8, 24, 0.8);
  backdrop-filter: blur(5px);
  color: ${({ theme }) => theme.colors.silver};
  font-size: 16px;
  font-weight: 700;
  border: none;
  z-index: 99;
  cursor: pointer;
`;

export const PolygonContainer = styled.div`
  position: absolute;
  top: calc(50% - 65px);
  left: calc(50% - 65px);
`;
