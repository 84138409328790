import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { setDataBoardTab } from '../../../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Tabs from '../../../Common/Tabs';
import Tab from '../../../Common/Tabs/Tab';
import CarbonAccounting from '../../../DataBoard/CarbonAccounting';
import Controls from '../../../DataBoard/Controls';
import Deforestation from '../../../DataBoard/Deforestation';
import ForestCover from '../../../DataBoard/ForestCover';
import { Navigation } from '../../../DataBoard/Header/style';
import Loader from '../../../DataBoard/Loader';
import ProjectDocuments from '../ProjectDocuments';
import { DeforestationWrapper, LoaderWrapper, ProjectControlsWrapper, TabWrapper } from './style';

const ProjectTabs = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { dataBoardTab } = useAppSelector((state) => state.uiState);
  const [projectTab, setProjectTab] = useState('0');
  const { carbonAccounting, deforestation, forestCover, selectedPolygon } = useAppSelector(
    (state) => state.regionState
  );

  const handleTabChange = useCallback(
    (tab: string) => {
      dispatch(setDataBoardTab(tab));
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectedPolygon && projectId !== selectedPolygon.id) {
      dispatch(setDataBoardTab('0'));
    }
  }, [selectedPolygon, dispatch, projectId]);

  useEffect(() => {
    dispatch(setDataBoardTab(projectTab));
  }, [dispatch, projectTab]);

  const loadingContainer = (
    <LoaderWrapper>
      <Loader
        title={t('Keep Exploring...')}
        description={t("Your data is loading. We'll notify you when it's ready.")}
      />
    </LoaderWrapper>
  );

  if (!selectedPolygon) {
    return <div>No project found.</div>;
  }

  return (
    <Navigation>
      <Tabs value={dataBoardTab} setValue={setProjectTab} onChange={handleTabChange}>
        <Tab title={t('Carbon')}>
          <TabWrapper>
            {carbonAccounting ? (
              <ProjectControlsWrapper>
                <Controls />
              </ProjectControlsWrapper>
            ) : null}
            {carbonAccounting ? <CarbonAccounting /> : loadingContainer}
          </TabWrapper>
        </Tab>
        <Tab title={t('Forest Cover')}>
          <TabWrapper>
            {forestCover ? (
              <ProjectControlsWrapper>
                <Controls />
              </ProjectControlsWrapper>
            ) : null}
            {forestCover ? <ForestCover /> : loadingContainer}
          </TabWrapper>
        </Tab>
        <Tab title={t('Heatmap')}>
          <TabWrapper>
            {deforestation ? (
              <DeforestationWrapper>
                <Deforestation visible={true} deforestationData={deforestation} hideCloseButton />
              </DeforestationWrapper>
            ) : (
              loadingContainer
            )}
          </TabWrapper>
        </Tab>
        <Tab title={t('Project Documentation')}>
          <ProjectDocuments />
        </Tab>
      </Tabs>
    </Navigation>
  );
};

export default ProjectTabs;
