import { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  title: string;
  disabled?: boolean;
}

const Tab = ({ children }: Props) => <>{children}</>;

export default Tab;
