/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import area from '@turf/area';
import center from '@turf/center';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { toast } from 'react-toastify';
import { useOnClickOutside } from 'usehooks-ts';
import { INITIAL_MAP_VIEW_STATE, USER_SOURCE_NAME } from '../../constants/map';
import { useMapContext } from '../../context/Map';
import { usePolygonContext } from '../../context/Polygon';
import { useGetSplitPolygonDataMutation } from '../../redux/api/biomassApi';
import { useUpdateRegionMutation } from '../../redux/api/regionApi';
import { openModal } from '../../redux/features/modal/modal-slice';
import { setRegionToEditOrDelete } from '../../redux/features/region/region-slice';
import { resetUIState } from '../../redux/features/ui/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { IRegionResponse } from '../../types/API/Region';
import { AreaUnitType, SQ_METER_TO_KILOMETER_RATIO } from '../../types/Geo';
import { convertSqKmTo } from '../../utils/units';
import Icon from '../Common/Icon';
import PolygonCanvas from '../Common/PolygonCanvas';
import MultiPolygonCanvas from '../Common/PolygonCanvas/MultipolygonCanvas';
import {
  Item,
  ItemContentWrapper,
  ItemMap,
  ItemSection,
  ItemsList,
  ItemText,
  ItemTitle,
  MenuItem,
  MenuOptions,
  MenuToggle,
  NewBadge,
  Title
} from './style';

const SavedRegions = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { userTiles } = useAppSelector((state) => state.regionState);
  const { resetPolygonData } = usePolygonContext();
  const { mapRoot } = useMap();
  const { handleTileClick } = useMapContext();
  const [verifySplitPolygon] = useGetSplitPolygonDataMutation();
  const [openMenu, setOpenMenu] = useState(-1);
  useOnClickOutside(menuRef, () => setOpenMenu(-1), 'mouseup');
  const user = useAppSelector((state) => state.userState.user);

  const [updateRegion, { data: regionData, isSuccess, error, reset }] = useUpdateRegionMutation();
  const [isHovered, setIsHovered] = useState<string | null>(null);

  // hovering over region in list from mapbox hover event
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.type === 'hover-saved-region') {
        if (e.data.originalGeometry) {
          const desiredElement = document.querySelector(`[data-original-geometry='${e.data.originalGeometry}']`);
          // if a desired element exists, a set isHovered with this element id
          if (desiredElement) {
            setIsHovered(String(desiredElement.getAttribute('data-id')));
          }
        } else {
          setIsHovered(null);
        }
      }
    });
  }, [userTiles]);

  useEffect(() => {
    if (error && 'data' in error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      toast.error((error.data as any).message, {
        toastId: 'saved-regions-error',
        autoClose: 3000
      });
      reset();
    }
  }, [error, reset]);

  const handleRegionDelete = useCallback(
    (e: MouseEvent<HTMLButtonElement>, data: IRegionResponse) => {
      // Prevents the click event from bubbling up to the parent element
      e.stopPropagation();
      dispatch(setRegionToEditOrDelete(data));
      dispatch(openModal('deleteRegion'));
    },
    [dispatch]
  );

  const handleShowOnMap = useCallback(
    (e: MouseEvent<HTMLButtonElement>, data: IRegionResponse) => {
      // Prevents the click event from bubbling up to the parent element
      e.stopPropagation();
      const centerPoint = center(data.geometry);
      mapRoot?.flyTo({
        center: [centerPoint.geometry.coordinates[0], centerPoint.geometry.coordinates[1]],
        zoom: INITIAL_MAP_VIEW_STATE.zoom + 2
      });
    },
    [mapRoot]
  );

  const handleSetRegionUpdatedFlag = useCallback(
    async (region: IRegionResponse) => {
      await updateRegion({
        id: String(region.id),
        body: {
          hasCheckedUpdate: true
        }
      });
      await verifySplitPolygon({
        feature: region,
        omitCache: false,
        withStateSet: true
      });
    },
    [updateRegion, verifySplitPolygon]
  );

  useEffect(() => {
    if (isSuccess && regionData) {
      handleTileClick(regionData, USER_SOURCE_NAME);
    }
  }, [handleTileClick, isSuccess, regionData]);

  const handleRegionClick = useCallback(
    async (el: IRegionResponse) => {
      resetPolygonData();

      if (
        el.dataUpdatedAt &&
        el.createdAt &&
        new Date(String(el.dataUpdatedAt)) > new Date(String(el.createdAt)) &&
        !el.hasCheckedUpdate &&
        el.isSubscribed &&
        user?.role !== 'free'
      ) {
        await handleSetRegionUpdatedFlag(el);
        return;
      }

      await verifySplitPolygon({
        feature: el,
        omitCache: false,
        withStateSet: true
      });
      handleTileClick(el, USER_SOURCE_NAME);
      dispatch(resetUIState());
    },
    [resetPolygonData, user?.role, verifySplitPolygon, handleTileClick, dispatch, handleSetRegionUpdatedFlag]
  );

  const handleRegionHover = useCallback(
    (el: IRegionResponse) => {
      const feature = mapRoot?.querySourceFeatures(USER_SOURCE_NAME, {
        filter: ['==', ['get', 'originalGeometry'], JSON.stringify(el.geometry)]
      });
      // if feature - go through each of them and set hover state in mapbox by setFeatureState
      if (feature) {
        feature.forEach((f) => {
          mapRoot?.setFeatureState(
            {
              source: USER_SOURCE_NAME,
              id: f.id
            },
            { hover: true }
          );
        });
      }
    },
    [mapRoot]
  );

  const handleResetRegionHover = useCallback(() => {
    const features = mapRoot?.querySourceFeatures(USER_SOURCE_NAME);
    if (features) {
      features.forEach((f) => {
        mapRoot?.setFeatureState(
          {
            source: USER_SOURCE_NAME,
            id: f.id
          },
          { hover: false }
        );
      });
    }
  }, [mapRoot]);

  const handleEditRegion = useCallback(
    (e: MouseEvent<HTMLButtonElement>, feature: IRegionResponse) => {
      // Prevents the click event from bubbling up to the parent element
      e.stopPropagation();
      dispatch(setRegionToEditOrDelete(feature));
      dispatch(openModal('editRegion'));
    },
    [dispatch]
  );

  const toggleOptionsMenu = useCallback((e: MouseEvent<HTMLDivElement>, index: number) => {
    // Prevents the click event from bubbling up to the parent element
    e.stopPropagation();
    setOpenMenu((prevIndex) => (prevIndex === index ? -1 : index));
  }, []);

  return (
    <>
      <Title>{t('Select a saved region to view the data or make updates:')}</Title>
      <ItemsList data-test-id="saved-regions-list">
        {userTiles.map((tile, index) => {
          const calculatedArea = Math.floor(
            convertSqKmTo(area(tile.geometry) / SQ_METER_TO_KILOMETER_RATIO, user?.settings.unit)
          );

          const isNew =
            tile.dataUpdatedAt &&
            tile.createdAt &&
            new Date(String(tile.dataUpdatedAt)) > new Date(String(tile.createdAt)) &&
            !tile.hasCheckedUpdate &&
            tile.isSubscribed &&
            user?.role !== 'free';

          return (
            <Item
              key={tile.id}
              data-id={tile.id}
              onClick={() => handleRegionClick(tile)}
              onMouseOver={() => handleRegionHover(tile)}
              onMouseLeave={() => handleResetRegionHover()}
              data-original-geometry={JSON.stringify(tile.geometry)}
              data-index={index}
              isHovered={isHovered === String(tile.id)}
              data-test-id={`saved-regions-region-${index}`}
            >
              <ItemMap>
                {tile.geometry.type === 'Polygon' ? (
                  <PolygonCanvas
                    data-test-id={`saved-regions-region-polygon-canvas-${index}`}
                    width={71}
                    fillColor="rgba(20, 162, 241, 0.56)"
                    strokeColor="rgba(20, 162, 241, 1)"
                    height={71}
                    polygon={tile as GeoJSON.Feature<GeoJSON.Polygon>}
                  />
                ) : (
                  <MultiPolygonCanvas
                    data-test-id={`saved-regions-region-multipolygon-canvas-${index}`}
                    fillColor="rgba(20, 162, 241, 0.56)"
                    strokeColor="rgba(20, 162, 241, 1)"
                    width={71}
                    height={71}
                    polygon={tile as unknown as GeoJSON.Feature<GeoJSON.MultiPolygon>}
                  />
                )}
              </ItemMap>
              <ItemContentWrapper>
                <ItemSection>
                  <NewBadge data-test-id="saved-regions-new-badge" isNew={isNew}>
                    {t('New data')}
                  </NewBadge>
                  <ItemTitle data-test-id="saved-regions-region-name">{tile.name}</ItemTitle>
                  <MenuToggle onClick={(e) => toggleOptionsMenu(e, index)}>
                    <Icon variant="MORE" size={24} color="white" />
                  </MenuToggle>
                  <MenuOptions isOpen={index === openMenu} ref={menuRef}>
                    <MenuItem onClick={(e) => handleShowOnMap(e, tile)}>{t('See on Map')}</MenuItem>
                    <MenuItem onClick={(e) => handleEditRegion(e, tile)}>{t('Edit Region')}</MenuItem>
                    <MenuItem onClick={(e) => handleRegionDelete(e, tile)}>{t('Delete Region')}</MenuItem>
                  </MenuOptions>
                </ItemSection>
                <ItemSection>
                  {user?.settings.unit ? (
                    <ItemText data-test-id="saved-regions-region-area">
                      {`${new Intl.NumberFormat('en', {
                        maximumFractionDigits: 2
                      }).format(calculatedArea)} ${user.settings.unit.replace('sq-', '')}${
                        calculatedArea > 1 && user.settings.unit !== AreaUnitType.squareKilometre ? 's' : ''
                      }`}
                      {user?.settings.unit === AreaUnitType.squareKilometre ||
                      user?.settings.unit === AreaUnitType.squareMile ? (
                        <sup>2</sup>
                      ) : null}
                    </ItemText>
                  ) : null}
                  {tile.dataUpdatedAt ? (
                    <ItemText data-test-id="saved-regions-region-updated-date" isNew={isNew}>
                      {t('Data updated:')} {new Date(tile.dataUpdatedAt).toLocaleString()}
                    </ItemText>
                  ) : null}
                </ItemSection>
              </ItemContentWrapper>
            </Item>
          );
        })}
      </ItemsList>
    </>
  );
};

export default SavedRegions;
