import styled from 'styled-components';

export const Wrapper = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.blackII};
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 400px;
  border-radius: 10px;
  transition: ${({ theme }) => theme.transition};
  transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(-20px)')};
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  filter: ${({ active }) => (active ? 'none' : 'blur(6px)')};
  box-shadow: 0 2.3245px 5.70055px 0 rgba(0, 0, 0, 0.03), 0 5.58609px 13.69921px 0 rgba(0, 0, 0, 0.04),
    0 10.5181px 25.7944px 0 rgba(0, 0, 0, 0.05), 0 18.7625px 46.0128px 0 rgba(0, 0, 0, 0.07),
    0 35.09319px 86.06187px 0 rgba(0, 0, 0, 0.08), 0 84px 206px 0 rgba(0, 0, 0, 0.11);

  @media ${({ theme }) => theme.breakpoints.extraLarge} {
    bottom: 100px;
    right: 100px;
  }
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.white};
`;

export const Link = styled.a`
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.purple};
  margin-bottom: 20px;
`;

export const Button = styled.button<{ isTransparent?: boolean }>`
  width: 190px;
  height: 46px;
  border-radius: 8px;
  border: 0;
  font-weight: 700;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.blackI};
  font-size: 16px;
  line-height: 21px;
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
