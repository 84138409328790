import { useTranslation } from 'react-i18next';
import { DetailRow, RowLabel, RowValue } from './style';

interface IProps {
  label: string;
  value?: string | number | string[];
}
const ProjectDetailRow = ({ label, value }: IProps) => {
  const { t } = useTranslation();
  return (
    <DetailRow>
      <RowLabel>{t(label)}</RowLabel>
      {Array.isArray(value) ? (
        value.map((item) => <RowValue key={item}>{item || '--'}</RowValue>)
      ) : (
        <RowValue>{value || '--'}</RowValue>
      )}
    </DetailRow>
  );
};

export default ProjectDetailRow;
