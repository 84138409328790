import styled from 'styled-components';

export const ContentSkeletonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
`;

export const ContentSkeletonNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  z-index: 5;
`;

export const ControlsAreaWrapper = styled.div`
  display: flex;
  padding: 0 0 24px;
  gap: 20px;
  width: 100%;
`;

export const LastUpdated = styled.p`
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.lightBlueTransparent};
`;

export const ContentWrapper = styled.div`
  position: relative;
`;
