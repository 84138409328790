import styled from 'styled-components';

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
`;

export const Subtitle = styled.p`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-weight: 500;
`;

export const ButtonWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin-bottom: 20px;
  gap: 20px;

  button {
    border-radius: 10px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;
