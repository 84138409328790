import { useCallback } from 'react';
import { drawRef } from '../components/Map/DrawControl';
import { usePolygonContext } from '../context/Polygon';
import { resetDrawSliceState, setIsDrawing } from '../redux/features/draw/draw-slice';
import { closeModal } from '../redux/features/modal/modal-slice';
import { resetUIState } from '../redux/features/ui/ui-slice';
import { useAppDispatch } from '../redux/hooks';

export const useDrawHook = () => {
  const dispatch = useAppDispatch();
  const { resetPolygonData } = usePolygonContext();

  const startOverFromModal = useCallback(() => {
    resetPolygonData();
    dispatch(resetUIState());
    dispatch(resetDrawSliceState());

    dispatch(setIsDrawing(true));
    drawRef?.deleteAll();
    drawRef?.changeMode('draw_polygon');

    dispatch(closeModal());
  }, [dispatch, resetPolygonData]);

  return {
    startOverFromModal
  };
};
