import { MouseEvent, useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

export const useMenu = () => {
  const [menuToShow, setMenuToShow] = useState(-1);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => setMenuToShow(-1), 'mouseup');

  const handleOpenMenu = useCallback((e: MouseEvent<HTMLButtonElement>, index: number) => {
    e.stopPropagation();
    setMenuToShow((prevIndex) => (prevIndex === index ? -1 : index));
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuToShow(-1);
  }, []);

  return { menuToShow, menuRef, handleOpenMenu, handleCloseMenu };
};
