import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSendContactUsMailMutation } from '../../../redux/api/mailApi';
import { closeModal } from '../../../redux/features/modal/modal-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { IDynamicTemplateBody } from '../../../types/API/Mail';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import Modal from '../../Common/Modal';
import Spacer from '../../Common/Spacer';
import Textarea from '../../Common/Textarea';
import { InputSx } from '../AccountModal/style';
import { Subtitle, Title } from '../ModalCommon.style';
import { ModalSx } from './style';

const ContactUsModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.userState);
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [sendContactUsMail, { isLoading, isSuccess, isError }] = useSendContactUsMailMutation();

  const handleSetFullName = (value: string) => {
    setFullName(value);
  };
  const handleSetCompany = (value: string) => {
    setCompany(value);
  };

  const handleSetMessage = (value: string) => {
    setMessage(value);
  };

  const handleOnSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      if (!fullName || !company || !message) {
        toast.error(t('Please fill all required fields before submitting'), {
          autoClose: 3000,
          toastId: 'contact-us-error'
        });
        return;
      }

      const body: IDynamicTemplateBody = {
        to: process.env.REACT_APP_EMAIL_CONTACT_US_TO as string,
        from: process.env.REACT_APP_EMAIL_CONTACT_US_FROM as string,
        dynamicTemplateData: {
          full_name: fullName,
          cyclops_url: process.env.REACT_APP_APP_URL as string,
          company,
          message,
          email: user?.email
        }
      };
      await sendContactUsMail(body);
    },
    [user?.email, fullName, company, message, t, sendContactUsMail]
  );

  useEffect(() => {
    setFullName(user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : '');
    setCompany(user?.company ? user.company : '');
  }, [user]);

  useEffect(() => {
    if (isSuccess) {
      setMessage('');

      toast.success(t('Your message has been sent successfully'), {
        autoClose: 3000,
        toastId: 'contact-us-success'
      });

      dispatch(closeModal());
    }
    if (isError) {
      toast.error(t('Something went wrong, please try again later'), {
        autoClose: 3000,
        toastId: 'contact-us-error'
      });
    }
  }, [isError, isSuccess, dispatch, t]);

  return (
    <Modal modalType="contactUs" additionalSx={ModalSx} dataTestId="contact-us-modal">
      <Title data-test-id="contact-us-modal-title">{t('Contact us')}</Title>
      <Subtitle data-test-id="contact-us-modal-subtitle">
        {t(
          'Kindly utilize the designated form for direct contact, and we will respond promptly. Your communication is important to us. Thank you.'
        )}
      </Subtitle>
      <form onSubmit={handleOnSubmit}>
        <Input
          required
          value={fullName}
          variant="black"
          onChange={handleSetFullName}
          label={String('Full name')}
          fullWidth
          dataTestId="contact-form-first-name"
        />
        <Spacer size={'20px 0 0'} />
        <Input
          required
          value={company}
          variant="black"
          onChange={handleSetCompany}
          label={String(t('Your company'))}
          dataTestId="contact-form-company"
          fullWidth
        />
        <Spacer size={'20px 0 0'} />
        <Textarea
          required
          value={message}
          variant="black"
          onChange={handleSetMessage}
          dataTestId="contact-form-message"
          label={String(t('Your message'))}
          minRows={5}
          width={InputSx.width}
          fullWidth
        />
        <Button variant="purple" fullWidth disabled={isLoading} type="submit" dataTestId="contact-us-modal-button">
          {t('Send')}
        </Button>
      </form>
    </Modal>
  );
};

export default ContactUsModal;
